@charset "utf-8";
.c-title {
	h3{
		font-family: 'UTM Bebas';
		font-weight: 400;
		font-size: 64px;
		line-height: 80px;
		color: #fff;
		position: relative;
		padding-bottom: 22px;
		@include brTablet{
			font-size: 44px;
			line-height: 60px;
		}
		@include brSpLandscape{
			font-size: 24px;
			line-height: 32px;
		}
	}
	p{
		font-weight: 400;
		font-size: 20px;
		line-height: 32px;
		letter-spacing: -0.02em;
		color: #FDFDFD;
	}
	&--border{
		h3{
			&::after{
				content: "";
				width: 100%;
				height: 1px;
				background-color: rgba(255, 255, 255, 0.8);
				position: absolute;
				bottom: 23px;
				left: 0;
				@include brSpLandscape{
					opacity: 0;
				}
			}
		}
	}
	&--center{
		text-align: center;
		h3{
			&::after{
				left: 50%;
				transform: translateX(-50%);
			}
		}
	}
}