.p-solution {
    &__main {
        margin-top: -250px;
        position: relative;
    }
    &__box {
        margin-bottom: 55px;
        &__row {
            align-items: center;
        }
        &__content {
            margin-top: 97px;
            // display: flex;
            // flex-direction: column;
            // justify-content: space-between;
            &-icon {
                margin-bottom: 24px;
            }
            &-title {
                margin-bottom: 8px;
                h3 {
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 32px;
                }
            }
            &-desc {
                margin-bottom: 24px;
                p {
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 24px;
                }
            }
            &-handle {
                a {
                    display: flex;
                    align-items: center;
                    gap: 13px;
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 24px;
                    z-index: 2;
                    position: relative;
                    &::after {
                        content: '';
                        position: absolute;
                        width: 121px;
                        height: 48px;
                        background: $cBase;
                        border-radius: 4px;
                        z-index: -1;
                        left: -9px;
                        opacity: 0;
                    }
                    &:hover {
                        opacity: 1;
                        &::after {
                            opacity: 1;
                        }
                    }
                }
            }
            &--right {
                margin-left: 44px;
            }
        }
    }
}