.c-btn {
    font-family: 'Overpass', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #FDFDFD;
    background: $cBase;
    border-radius: 2px;
    padding: 12px 42px;
    white-space: nowrap;
    @include brTablet {
        font-size: 20px;
        padding: 12px 20px;
    }
    @include brSpLandscape {
        font-size: 16px;
        line-height: 24px;
        padding: 10px 20px;
    }
    &--white {
        font-weight: 600;
        font-size: 16px;
        line-height: 32px;
        color: $cAccent;
        background-color: #fff;
        padding: 4px 43.5px;
        &:hover {
            opacity: 1;
            background-color: $cBase;
            color: #fff;
        }
    }
    &--border {
        border: 1px solid $cBase;
        &:hover {
            opacity: 1;
            background-color: $cBase;
            color: #fff;
        }
    }
}