.l-container{
    width: 1158px;
    padding-left: 24px;
    padding-right: 24px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.l-container-fluid {
    padding-left: 24px;
    padding-right: 24px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
}