/*----------------------------------------
	clearfix
----------------------------------------*/
/*@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;500;700&display=swap");*/
/*@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700");*/
/*@import url("https://fonts.googleapis.com/css2?family=Overpass:wght@500");*/
/*@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&family=Overpass:wght@300;400;500;600;700&display=swap");*/
@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');

@font-face {
  font-family: 'UTM Bebas';
  src: url('/assets/font/Bebas-Regular.ttf');
}

html {
  overflow-x: hidden;
  scroll-behavior: smooth;
}

body {
  color: #fff;
  font-family: 'Roboto Condensed', sans-serif;
  -webkit-font-feature-settings: 'halt' 1;
  font-feature-settings: 'halt' 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  background: #001530;
}

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

a {
  color: #fff;
  text-decoration: none;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  display: inline-block;
}

a:hover {
  opacity: 1;
}

img {
  vertical-align: bottom;
}

img {
  height: auto;
}

table {
  width: 100%;
}

input,
button {
  font-family: inherit;
}

input:focus-visible,
button:focus-visible {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

button,
select {
  cursor: pointer;
}

input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

a:focus,
input:focus {
  outline: none;
}

.w-100 {
  width: 100%;
}

/*----------------------------------------
	reset
----------------------------------------*/
html,
body,
div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
dialog,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  color: #fff;
  vertical-align: baseline;
}

header,
footer,
article,
section,
aside,
hgroup,
nav,
menu,
figure,
figcaption,
time {
  display: block;
}

li {
  list-style: none;
}

ol li {
  list-style: decimal;
}

img {
  border: 0;
  vertical-align: top;
  font-size: 0;
  line-height: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

caption,
th {
  text-align: left;
}

hr {
  margin: 1em 0;
  padding: 0;
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
}

input,
select {
  vertical-align: middle;
}

input,
textarea {
  margin: 0;
  padding: 0;
}

.clearfix {
  min-height: 1px;
  _height: 1%;
}

.clearfix:after {
  content: '';
  clear: both;
  height: 0;
  display: block;
  visibility: hidden;
}

.l-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

.l-col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  -webkit-box-flex: 1;
  flex-grow: 1;
  max-width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
}

.l-col-auto {
  -ms-flex: 0 0 auto;
  -webkit-box-flex: 0;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
  padding-left: 15px;
  padding-right: 15px;
}

.l-col-8 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.l-col-4 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.l-header {
  position: relative;
}

@media screen and (min-width: 1200px) {
  .p-news__featured .c-title {
    margin-top: 50px;
  }
}

.l-header-desktop {
  padding: 25px 0 15px;
  position: relative;
  top: 0;
  left: 0;
  z-index: 3;
  width: 100%;
  /* background: #000E33; */
  /* -webkit-box-shadow: 0px 1px 3px rgba(255, 255, 255, 0.2);
  box-shadow: 0px 1px 3px rgba(255, 255, 255, 0.2); */
}

@media (max-width: 1199px) {
  .l-header-desktop {
    display: none;
  }
}

.l-header-desktop .l-container-fluid {
  padding-left: 66px;
  padding-right: 66px;
  width: calc(1920px - 113px);
  max-width: 100%;
}

@media (max-width: 1599px) {
  .l-header-desktop .l-container-fluid {
    padding-left: 24px;
    padding-right: 24px;
  }
}

.l-header-desktop .l-row {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.l-header-desktop .l-row .l-col-auto {
  cursor: pointer;
}

.l-header-desktop__right__inner {
  padding-top: 13px;
  padding-left: 25px;
}

@media (max-width: 1599px) {
  .l-header-desktop__right__inner {
    padding-left: 0;
  }
}

.l-header-desktop__intro {
  padding-top: 19px;
}

.l-header-desktop__intro p {
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 8px;
}

.l-header-desktop__intro h3 {
  color: #fff;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  text-transform: uppercase;
}

.l-header-desktop__nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  justify-content: end;
}

.l-header-desktop__menu {
  width: 77%;
}

.l-header-desktop__menu>ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.l-header-desktop__menu>ul>li {
  position: relative;
}

.l-header-desktop__menu>ul>.active>a {
  color: #fff;
}

.l-header-desktop__menu>ul>li.active::before {
  content: '';
  width: 112px;
  height: 110px;
  background: url('/assets/img/common/icn_light.svg') no-repeat center;
  background-size: cover;
  position: absolute;
  bottom: -23px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.l-header-desktop__menu>ul>li.active::after {
  content: '';
  width: 72px;
  height: 5px;
  background: #ffffff;
  border-radius: 18px;
  position: absolute;
  bottom: -23px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.l-header-desktop__menu>ul>li.active>a::before {
  content: url('/assets/img/common/icn_eyefire_active.svg');
  position: absolute;
  top: -48px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.l-header-desktop__menu>ul>li+li {
  margin-left: 0px;
}

@media (max-width: 1800px) {
  .l-header-desktop__menu>ul>li+li {
    margin-left: 1.8917498686vw;
  }
}

.l-header-desktop__menu>ul>li.has-sub:hover .l-header-desktop__menu__dropdown-menu {
  opacity: 1;
  visibility: visible;
  top: 100%;
  pointer-events: all;
}

.l-header-desktop__menu>ul>li.has-sub>a {
  padding-right: 26px;
}

@media (max-width: 1599px) {
  .l-header-desktop__menu>ul>li.has-sub>a {
    padding-right: 0px;
  }
}

/* .l-header-desktop__menu>ul>li.has-sub>a::after {
  content: url("/assets/img/common/icn_down.svg") !important;
  position: absolute;
  right: 2px;
  top: -4px;
  z-index: 0;
} */

.l-header-desktop__menu>ul>li>a {
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  color: #c9e1ff;
  letter-spacing: 0.09em;
  position: relative;
}

@media (max-width: 1599px) {
  .l-header-desktop__menu>ul>li>a {
    font-size: 18px;
  }
}

.l-header-desktop__menu__dropdown-menu {
  padding: 16px 0 14px;
  min-width: 200px;
  background: -webkit-gradient(linear,
      left bottom,
      left top,
      from(rgba(0, 0, 0, 0.15)),
      to(rgba(0, 0, 0, 0.15))),
    rgba(255, 255, 255, 0.45);
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)),
    rgba(255, 255, 255, 0.45);
  border: 0.75px solid #ffffff;
  -webkit-box-shadow: 0px 6px 4px 2px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 6px 4px 2px rgba(0, 0, 0, 0.25);
  -webkit-backdrop-filter: blur(4.5px);
  backdrop-filter: blur(4.5px);
  border-radius: 4px;
  position: absolute;
  top: calc(100% + 15px);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  pointer-events: none;
  z-index: 999;
}

.l-header-desktop__menu__dropdown-menu ul li {
  padding: 5px 20px;
}

.l-header-desktop__menu__dropdown-menu ul li a {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  position: relative;
  padding-left: 15px;
  display: block;
}

.l-header-desktop__menu__dropdown-menu ul li a:hover {
  opacity: 1;
}

.l-header-desktop__menu__dropdown-menu ul li a:hover::after {
  opacity: 1;
}

.l-header-desktop__menu__dropdown-menu ul li a::after {
  content: '';
  position: absolute;
  left: -11px;
  top: 0px;
  width: calc(100% + 20px);
  height: 100%;
  background: rgba(29, 161, 242, 0.45);
  border: 0.5px solid #93d1ff;
  border-radius: 4px;
  opacity: 0;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
  z-index: -1;
}

.l-header-desktop__menu__dropdown-menu ul li a::before {
  content: '';
  width: 5px;
  height: 5px;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.l-header-desktop__group-right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 63px;
  cursor: pointer;
}

@media (max-width: 1599px) {
  .l-header-desktop__group-right {
    margin-left: 30px;
  }
}

.l-header-desktop__languages {
  position: relative;
}

.l-header-desktop__languages:hover .l-header-desktop__languages__dropdown {
  opacity: 1;
  visibility: visible;
  top: 100%;
  pointer-events: all;
  left: 0%;
  background: #fff;
}

/* .l-header-desktop__languages .l-header-desktop__languages__dropdown a::before {
  content: "";
  width: 5px;
  height: 5px;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
} */

.l-header-desktop__languages>a {
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.09em;
  border: none;
  background: transparent;
  color: #c9e1ff;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media (max-width: 1599px) {
  .l-header-desktop__languages>a {
    font-size: 20px;
  }
}

.l-header-desktop__languages>a span {
  margin-right: 13px;
  margin-left: 8px;
}

.l-header-desktop__languages__dropdown {
  padding: 14px 0 14px;
  min-width: 97px;
  background: -webkit-gradient(linear,
      left bottom,
      left top,
      from(rgba(0, 0, 0, 0.15)),
      to(rgba(0, 0, 0, 0.15))),
    rgba(255, 255, 255, 0.45);
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)),
    rgba(255, 255, 255, 0.45);
  border: 0.75px solid #ffffff;
  -webkit-box-shadow: 0px 6px 4px 2px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 6px 4px 2px rgba(0, 0, 0, 0.25);
  -webkit-backdrop-filter: blur(4.5px);
  backdrop-filter: blur(4.5px);
  border-radius: 4px;
  position: absolute;
  right: 0;
  top: calc(100% + 15px);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  pointer-events: none;
  z-index: 1;
}

.l-header-desktop__languages__dropdown ul li {
  padding: 4px 20px;
}

.l-header-desktop__languages__dropdown ul li a {
  cursor: pointer;
  /* padding-left: 15px; */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #ffffff;
}

.l-header-desktop__languages__dropdown ul li a:hover {
  opacity: 1;
}

.l-header-desktop__languages__dropdown ul li a:hover span {
  opacity: 0.6;
}

.l-header-desktop__languages__dropdown ul li a span {
  -webkit-transition: 0.2s;
  transition: 0.2s;
  font-size: 20px;
  color: #000;
  font-weight: 500;
}

.l-header-desktop__languages__dropdown ul li a img {
  margin-right: 8px;
}

.l-header-desktop__search {
  margin-left: 2.6274303731vw;
}

.l-header-desktop__search>a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.l-header-mobile {
  display: none;
  position: relative;
  margin-top: -3px;
}

.l-header-mobile.show-canvas .l-header-mobile__canvas {
  opacity: 1;
  visibility: visible;
}

.l-header-mobile .l-container {
  padding: 0;
}

.l-header-mobile .l-row {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0;
}

.l-header-mobile__left {
  padding-left: 24px;
  padding-right: 0;
  padding-top: 7px;
}

.l-header-mobile__right {
  padding: 38px 35px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.l-header-mobile__logo {
  position: relative;
  bottom: 3px;
}

.l-header-mobile__canvas {
  position: fixed;
  opacity: 0;
  visibility: hidden;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  top: 0;
  left: 0;
  right: 0;
  background-color: #001530;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
  z-index: 10;
}

@media (max-width: 1124px) and (min-width: 767px) {
  .l-header-mobile__canvas {}
}

.l-header-mobile__canvas .l-row {
  background: #000e33;
  /* -webkit-box-shadow: 0px 1px 3px rgba(255, 255, 255, 0.2);
  box-shadow: 0px 1px 3px rgba(255, 255, 255, 0.2); */
}

.l-header-mobile__canvas__inner {
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 36px;
  padding-bottom: 100px;
}

.l-header-mobile__canvas__menu {
  text-align: center;
}

.l-header-mobile__canvas__menu ul li {
  padding: 15px 0;
}

.l-header-mobile__canvas__menu ul li a {
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: 0.09em;
  color: #c9e1ff;
  position: relative;
}

@media (max-width: 767px) {
  .l-header-mobile__canvas__menu ul li.has-sub a::after {
    content: url(/assets/img/common/icn_down.svg) !important;
    position: absolute;
    right: -22px;
    top: -2px;
    z-index: 0;
  }
}

@media (max-width: 555px) {
  .l-header-mobile__canvas .l-header-mobile__left {
    padding-right: 6px;
  }

  /* .p-sdk__face {
    margin-top: 92px !important;
  } */
}

.l-header-mobile__canvas .l-header-mobile__right {
  padding: 31.5px 35px;
}

.l-header-mobile__canvas__languages {
  text-align: center;
  position: relative;
  margin-top: 15px;
}

.l-header-mobile__canvas__languages.show .l-header-mobile__canvas__languages__dropdown {
  opacity: 1;
  visibility: visible;
  top: calc(100% + 12px);
  pointer-events: all;
}

.l-header-mobile__canvas__languages>a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: 0.09em;
  color: #c9e1ff;
  align-items: center;
}

.l-header-mobile__canvas__languages>a span {
  margin-right: 16px;
  margin-left: 8px;
}

.l-header-mobile__canvas__languages__dropdown {
  padding: 11px 0;
  min-width: 84px;
  background: #ffffff;
  -webkit-box-shadow: 0px 12px 14px rgba(189, 189, 189, 0.25);
  box-shadow: 0px 12px 14px rgba(189, 189, 189, 0.25);
  border-radius: 5px;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  top: calc(100% + 27px);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  pointer-events: none;
  z-index: 1;
}

.l-header-mobile__canvas__languages__dropdown ul li {
  padding: 4px 18px;
}

.l-header-mobile__canvas__languages__dropdown ul li a {
  font-family: 'Roboto', sans-serif;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #121212;
}

.l-header-mobile__canvas__languages__dropdown ul li a:hover {
  opacity: 1;
}

.l-header-mobile__canvas__languages__dropdown ul li a:hover span {
  opacity: 0.6;
}

.l-header-mobile__canvas__languages__dropdown ul li a span {
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

.l-header-mobile__canvas__languages__dropdown ul li a img {
  margin-right: 8px;
}

.l-footer {
  background-color: #001530;
  margin-top: -1px;
  padding-top: 1px;
}

.l-footer .l-container-fluid {
  width: 1420px;
}

.l-footer__contact {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

@media (max-width: 767px) {
  .l-footer__contact {
    padding: 32px 0 40px;
  }
}

.l-footer__contact__inner {
  width: 606px;
  padding: 24px 0 37px 0;
  width: 1110px;
  max-width: 100%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  background: url('/assets/img/common/bg_contact.webp') no-repeat center;
  background-size: cover;
  border: 2px solid #4291f6;
  border-radius: 10px;
}

@media (max-width: 767px) {
  .l-footer__contact__inner {
    padding: 21px 0 35px 0;
    background-image: url('/assets/img/common/bg_contact_sp.png');
  }
}

.l-footer__contact__inner h3 {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 56px;
  text-transform: uppercase;
  color: #ffffff;
}

@media (max-width: 767px) {
  .l-footer__contact__inner h3 {
    font-size: 24px;
    line-height: 28px;
  }
}

.l-footer__contact__inner p {
  margin-top: 8px;
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  text-align: center;
  color: #ffffff;
}

@media (max-width: 767px) {
  .l-footer__contact__inner p {
    font-size: 16px;
    line-height: 24px;
    margin-top: 16px;
    margin-bottom: 32px !important;
    padding: 0 9px !important;
  }
}

.l-footer__contact__group-btn {
  text-align: center;
  margin-top: 56px;
}

@media (max-width: 767px) {
  .l-footer__contact__group-btn {
    margin-top: 32px;
  }
}

.l-footer__contact__group-btn .c-btn {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #fff;
  border-radius: 2px;
  width: 238px;
  height: 48px;
  gap: 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}

.l-footer__content {
  background: url('/assets/img/common/bg_footer.webp') no-repeat center;
  background-size: cover;
}

@media (max-width: 767px) {
  .l-footer__content {
    background: url('/assets/img/common/bg_footer-sp.webp') no-repeat center;
    background-size: cover;
  }
}

.l-footer__main {
  margin-top: -108px;
  padding: 216px 0 56px;
}

@media (max-width: 767px) {
  .l-footer__main {
    margin-top: 0;
    padding: 42px 0 0;
  }
}

.l-footer__main__header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-bottom: 25px;
}

@media (max-width: 1199px) {
  .l-footer__main__header {
    display: block;
    padding-bottom: 56px;
  }
}

@media (max-width: 767px) {
  .l-footer__main__header {
    text-align: center;
  }
}

.l-footer__main__header__logo {
  -webkit-box-flex: 463.88px;
  -ms-flex: 463.88px;
  flex: 463.88px;
}

@media (max-width: 767px) {
  .l-footer__main__header__logo {
    -webkit-box-flex: 100%;
    -ms-flex: 100%;
    flex: 100%;
  }
}

.l-footer__main__header__slogan {
  -webkit-box-flex: calc(100% - 463.88px);
  -ms-flex: calc(100% - 463.88px);
  flex: calc(100% - 463.88px);
}

@media (max-width: 767px) {
  .l-footer__main__header__slogan {
    -webkit-box-flex: 100%;
    -ms-flex: 100%;
    flex: 100%;
  }
}

.l-footer__main__header__slogan p {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.02em;
  color: #ffffff;
  margin-top: 16px;
  width: 805px;
  max-width: 100%;
}

@media (max-width: 767px) {
  .l-footer__main__header__slogan p {
    margin-top: -7px;
    font-size: 18px;
    line-height: 32px;
  }
}

.l-footer__main__logo {
  margin-bottom: 41px;
}

@media (max-width: 767px) {
  .l-footer__main__logo {
    margin-bottom: 22px;
  }
}

@media (max-width: 767px) {
  .l-footer__main__logo img {
    width: 167.6px;
  }
}

@media (max-width: 1199px) {
  .l-footer__main .l-row>div {
    -webkit-box-flex: 50%;
    -ms-flex: 50%;
    flex: 50%;
    max-width: 50%;
  }
}

@media (max-width: 767px) {
  .l-footer__main .l-row>div {
    -webkit-box-flex: 100%;
    -ms-flex: 100%;
    flex: 100%;
    max-width: 100%;
    margin-bottom: 13px;
  }

  .footer-pc {
    display: none;
  }
}

.l-footer__main .l-row>div.l-footer__main__office {
  margin-bottom: 13px;
}

@media (max-width: 767px) {
  .l-footer__main .l-row>div.l-footer__main__office {
    padding-right: 5px;
  }
}

.l-footer__main .l-row>div.l-footer__main__service {
  margin-bottom: 20px;
}

.l-footer__main__office .l-footer__main__content>ul>li {
  margin-bottom: 16px;
}

@media (max-width: 767px) {
  .l-footer__main__office .l-footer__main__content>ul>li {
    margin-bottom: 17px;
  }
}

@media (max-width: 1199px) {
  .l-footer__main__office {
    -webkit-box-flex: 50%;
    -ms-flex: 50%;
    flex: 50%;
    max-width: 50%;
  }
}

@media (max-width: 1279px) {
  .l-footer__main__menu {
    padding-left: 15px;
  }
}

@media (max-width: 767px) {
  .l-footer__main__menu {
    margin-top: 17px;
  }
}

.l-footer__main__solution {
  margin-left: 5.0520833333vw;
}

@media (max-width: 1199px) {
  .l-footer__main__solution {
    margin-left: 0;
  }
}

@media (max-width: 1199px) and (max-width: 767px) {
  .l-footer__main__solution {
    margin-top: 30px;
  }
}

.l-footer__main__technology {
  margin-left: 5.0520833333vw;
}

@media (max-width: 1199px) {
  .l-footer__main__technology {
    margin-left: 0;
  }
}

@media (max-width: 767px) {
  .l-footer__main__technology {
    margin-top: 15px;
  }
}

.l-footer__main__title {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: -0.02em;
  color: #ffffff;
  position: relative;
  padding-bottom: 11px;
  margin-bottom: 40px;
  text-transform: uppercase;
}

@media (max-width: 1199px) {
  .l-footer__main__title {
    font-size: 20px;
    padding-bottom: 18px;
    line-height: 23px;
    margin-bottom: 30px;
  }
}

.l-footer__main__title::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 24px;
  height: 5px;
  border-radius: 5px;
  background-color: #4291f6;
}

@media (max-width: 1199px) {
  .l-footer__main__title::after {
    width: 30px;
    height: 5px;
  }
}

.l-footer__main__content-rules {
  margin-top: 0px;
}

@media (max-width: 767px) {
  .l-footer__main__content-rules.item-sp {
    margin-top: 30px;
    padding-left: 15px;
    padding-bottom: 21px;
  }
}

.l-footer__main__content__item {
  margin-top: 21px;
}

@media (max-width: 1199px) {
  .l-footer__main__content__item {
    margin-top: 23px;
  }
}

.l-footer__main__content__item h5 {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 25px;
  color: #f9f9f9;
  margin-bottom: 7px;
}

@media (max-width: 1199px) {
  .l-footer__main__content__item h5 {
    margin-bottom: 4px;
  }
}

.l-footer__main__content__item p {
  /* font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #F9F9F9; */
  opacity: 1;
}

.l-footer__main__content__item p .l-footer__main__content__desAdr {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #f9f9f9;
  opacity: 0.68;
}

.l-footer__main__content__item p.email {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 10px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 13px;
}

.l-footer__main__content__keep {
  margin-top: 0px;
}

@media (max-width: 1199px) {
  .l-footer__main__content__keep {
    margin-bottom: 50px;
  }
}

@media (max-width: 767px) {
  .l-footer__main__content__keep.item-sp {
    margin-top: 15px;
    padding-left: 15px;
  }
}

.l-footer__main__content__keep-item {
  margin-top: -18px;
}

.l-footer__main__content__keep-item ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 13px;
}

.l-footer__main__content>ul>li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 17px;
  padding-left: 15px;
  position: relative;
}

.l-footer__main__content>ul>li::after {
  content: '';
  position: absolute;
  background: rgba(255, 255, 255, 0.68);
  width: 5px;
  height: 5px;
  border-radius: 50%;
  left: 0;
  top: 9px;
}

@media (max-width: 767px) {
  .l-footer__main__content>ul>li {
    margin-bottom: 15px;
  }
}

.l-footer__main__content>ul>li>a {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  opacity: 0.68;
}

.l-footer__main__content>ul>.li_tech>a {
  line-height: 16px;
}

.l-footer__main__content>ul>li>a:hover {
  opacity: 1;
}

.l-footer__main__content>ul>li .icon {
  min-width: 37px;
}

.l-footer__main__content>ul>li .icon-envelop {
  position: relative;
  top: 3px;
}

.l-footer__main__content>ul>li h5 {
  font-size: 16px;
  line-height: 25px;
  font-weight: 400;
  letter-spacing: 0.01em;
  color: #ffffff;
}

@media (max-width: 767px) {
  .l-footer__main__content>ul>li h5 {
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 12px;
  }
}

.l-footer__main__content>ul>li p {
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 0.01em;
  color: #ffffff;
  opacity: 0.68;
}

@media (max-width: 767px) {
  .l-footer__main__content>ul>li p {
    font-size: 14px;
    line-height: 20px;
  }
}

.l-footer__main__social-link {
  margin-bottom: 44px;
}

@media (max-width: 767px) {
  .l-footer__main__social-link {
    margin-bottom: 17px;
  }
}

.l-footer__main__social-link ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

@media (max-width: 767px) {
  .l-footer__main__social-link ul {
    margin-top: -7px;
  }
}

.l-footer__main__social-link ul li+li {
  margin-left: 14px;
}

@media (max-width: 767px) {
  .l-footer__main__social-link ul li+li {
    margin-left: 16px;
  }
}

.l-footer__main__link {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  letter-spacing: 0.01em;
  text-decoration-line: underline;
  color: #ffffff;
  font-size: 14px;
  display: block;
  text-decoration: underline;
  margin-bottom: 12px;
}

@media (max-width: 767px) {
  .l-footer__main__link {
    margin-bottom: 10px;
  }
}

.l-footer__copyright {
  padding: 34px 0 30px;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  position: relative;
}

.l-footer__copyright .l-container-fluid {
  position: relative;
}

@media (max-width: 767px) {
  .l-footer__copyright {
    padding: 24px 0 30px;
  }
}

.l-footer__copyright__logo {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 19px;
}

@media (max-width: 767px) {
  .l-footer__copyright__logo {
    top: calc(50% - 66px);
  }

  .l-footer__copyright__logo img {
    width: 153px;
  }
}

.l-footer__copyright__logo--right {
  left: unset;
  right: 91px;
}

@media (max-width: 767px) {
  .l-footer__copyright__logo--right {
    right: 53px;
  }

  .l-footer__copyright__logo--right img {
    width: 93px;
  }
}

.l-footer__copyright p {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  text-align: center;
}

.l-footer__copyright p br {
  display: none;
}

@media (max-width: 767px) {
  .l-footer__copyright p br {
    display: inline-block;
  }
}

@media (max-width: 1199px) {
  .l-footer__copyright p {
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  .l-footer__copyright p {
    font-size: 16px;
    text-align: center;
    width: 250px;
    margin: 60px auto 0;
  }
}

.l-container {
  width: 1158px;
  padding-left: 24px;
  padding-right: 24px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.l-container-fluid {
  padding-left: 24px;
  padding-right: 24px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 767px) {
  .p-home__slider {
    margin-top: -100px;
  }
}

.p-home__slider__follow {
  position: absolute;
  right: calc(100% + (39 / 1920 * 100vw));
  top: 32%;
  padding-top: 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 1;
}

.p-home__slider__follow-label {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.02em;
  color: #ffffff;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  white-space: nowrap;
}

.p-home__slider__follow ul {
  margin-top: 38px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  flex-wrap: nowrap;
}

.p-home__slider__follow ul::before {
  content: '';
  width: 1px;
  height: 30px;
  background-color: #fff;
  display: block;
  margin-left: 0px;
  margin-bottom: 11px;
}

.p-home__slider__follow ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.p-home__slider__follow ul li+li {
  margin-top: 16px;
}

.p-home__slider__follow ul li a img {
  width: 32px;
  height: 32px;
}

.p-home__slider__item {
  position: relative;
}

.p-home__slider__item>img.sp {
  display: none;
}

@media (max-width: 767px) {
  .p-home__slider__item>img.sp {
    display: block;
  }
}

@media (max-width: 767px) {
  .p-home__slider__item>img.pc {
    display: none;
  }
}

.p-home__slider__content {
  position: absolute;
  top: 25.3%;
  left: 50%;
  transform: translateX(-50%);
  width: 1188px;
  max-width: 100%;
  z-index: 1;
  text-align: center;
}

/* @media (max-width: 1366px) {
  .p-home__slider__content {
    top: 35px;
  }
} */

@media (max-width: 1279px) {
  .p-home__slider__content {
    /* left: 0;
    width: 100%;
    max-width: 100%; */
    padding: 0 25px;
  }
}

@media (max-width: 767px) {
  .p-home__slider__content {
    top: 55%;
  }
}

@media (max-width: 575px) {
  .p-home__slider__content {
    top: auto;
    bottom: 91px;
  }
}

.p-home__slider__content h2 {
  font-weight: 700;
  font-size: 68.7037px;
  line-height: 86px;
  text-transform: uppercase;
  color: #1f7aff;
  margin-bottom: 50px;
}

@media (max-width: 1279px) {
  .p-home__slider__content h2 {
    font-size: 40px;
    line-height: 60px;
  }
}

@media (max-width: 767px) {
  .p-home__slider__content h2 {
    font-weight: 700;
    font-size: 40px;
    line-height: 32px;
    margin-bottom: 16px;
  }
}

.p-home__slider__content p {
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;
  padding-left: 6px;
  margin-bottom: 54px;
}

@media (max-width: 1279px) {
  .p-home__slider__content p {
    font-size: 22px;
    line-height: 32px;
  }
}

@media (max-width: 767px) {
  .p-home__slider__content p {
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 32px;
    padding: 0 5px;
  }
}

.p-home__slider__content .c-btn {
  margin-left: 2px;
}

@media (max-width: 767px) {
  .p-home__slider__content .c-btn {
    font-size: 16px;
    line-height: 24px;
    padding: 12px 42px;
  }
}

.p-home__slider__btn-play {
  margin-left: 28px;
}

.p-home__slider .slick-slide {
  outline: none;
  overflow: hidden;
}

.p-home__slider .slick-arrow {
  width: 52px;
  height: 52px;
  cursor: pointer;
  padding: 0;
  border: none;
  position: absolute;
  background-color: transparent;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  z-index: 1;
}

.p-home__slider .slick-arrow::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.65);
  opacity: 0.54;
  z-index: 1;
}

.p-home__slider .slick-arrow img {
  position: relative;
  z-index: 2;
}

.p-home__slider .slick-arrow:hover {
  opacity: 0.6;
}

.p-home__slider .slick-arrow.slick-prev {
  left: 0;
}

.p-home__slider .slick-arrow.slick-next {
  right: 0;
}

.p-home__slider .slick-dots {
  position: absolute;
  bottom: 153px;
  right: calc((100% - 1188px) / 2);
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media (max-width: 1279px) {
  .p-home__slider .slick-dots {
    left: 25px;
    right: auto;
    bottom: 30px;
  }
}

@media (max-width: 1199px) {
  .p-home__slider .slick-dots {
    display: none !important;
  }
}

.p-home__slider .slick-dots li {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin: 0 12px;
}

.p-home__slider .slick-dots li.slick-active button {
  background-color: #fff;
}

.p-home__slider .slick-dots li button {
  font-size: 0;
  width: 10px;
  height: 10px;
  border: 1px solid #ffffff;
  border-radius: 50%;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
  -webkit-transition: 0.4s all;
  transition: 0.4s all;
}

.p-home__slider .slick-dots .slick-active button {
  width: 20px;
  height: 20px;
  /* background-color: #ffffff; */
}

@media (max-width: 767px) {
  .p-home__slider .slick-dots .slick-active button {
    width: 10px;
    height: 10px;
  }

  .p-home__slider .slick-dots li button {
    width: 7px;
    height: 7px;
  }
}

.p-home__slider .slick-dots li button:hover {
  background-color: #fff;
}

.p-home__technology {
  padding: 40px 0 93px;
}

@media (max-width: 767px) {
  .p-home__technology {
    padding: 43px 0 43px;
  }
}

@media (max-width: 767px) {
  .p-home__technology--pc {
    display: none;
  }
}

.p-home__technology--sp {
  display: none;
}

@media (max-width: 767px) {
  .p-home__technology--sp {
    display: block;
    padding: 0;
    margin-top: -105px;
  }
}

/* @media (max-width: 414px) and (min-width: 391px) {
  .p-home__technology--sp {
    padding-top: 185px;
  }
} */

.p-home__technology-slider {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 447px;
  padding-right: 447px;
  padding-top: 171px;
}

@media (max-width: 1800px) {
  .p-home__technology-slider {
    padding-left: 23.4892275355vw;
    padding-right: 23.4892275355vw;
  }
}

@media (max-width: 1199px) {
  .p-home__technology-slider {
    padding-left: 50px;
    padding-right: 50px;
  }
}

@media (max-width: 767px) {
  .p-home__technology-slider {
    padding: 0;
    margin-top: 17px;
  }
}

.p-home__technology-item {
  position: relative;
  padding: 0 23px;
  max-width: 1000px !important;
}

@media (max-width: 767px) {
  .p-home__technology-item {
    padding: 0 24px;
    margin-bottom: 40px;
  }
}

.p-home__technology-item.slick-active::before {
  content: '';
  width: 50%;
  height: 100%;
  position: absolute;
  right: calc(100% + 0px);
  top: 0;
  background: -webkit-gradient(linear,
      right top,
      left top,
      color-stop(9.83%, #001530),
      color-stop(133.67%, rgba(0, 21, 48, 0)));
  background: linear-gradient(270deg,
      #001530 9.83%,
      rgba(0, 21, 48, 0) 133.67%);
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
  z-index: 2;
}

.p-home__technology-item.slick-active::after {
  content: '';
  width: 50%;
  height: 100%;
  position: absolute;
  left: calc(100% + 28px);
  top: 0;
  background: -webkit-gradient(linear,
      right top,
      left top,
      color-stop(9.83%, #001530),
      color-stop(133.67%, rgba(0, 21, 48, 0)));
  background: linear-gradient(270deg,
      #001530 9.83%,
      rgba(0, 21, 48, 0) 133.67%);
  z-index: 2;
}

.p-home__technology-item.slick-active .p-home__solution-content {
  left: 82px;
}

.p-home__technology-item.slick-active .c-btn {
  padding: 11px 11px 13px;
  background-color: #34a3fd;
}

@media (min-width: 991.5px) {
  .p-home__technology-content div a:hover {
    background-color: #34a3fd;
    padding: 12px 16px;
    width: 125px;
  }
}

/* .p-home__technology-slider .slick-list .slick-track .slick-active .c-btn {
  padding: 14.5px 9.5px 14.5px;
  background-color: #34A3FD;
} */

.p-home__technology-content {
  width: 47%;
  position: absolute;
  bottom: 38px;
  left: 70px;
  z-index: 1;
}

@media (max-width: 767px) {
  .p-home__technology-content {
    width: 100%;
    left: 0;
    padding: 0 50px;
    bottom: 40px;
  }
}

.p-home__technology-content>img {
  margin-left: -24px;
  width: 142px;
}

@media (max-width: 767px) {
  .p-home__technology-content>img {
    width: 120px;
    margin-left: -30px;
  }
}

.p-home__technology-content h5 {
  font-weight: 500;
  font-size: 40px;
  line-height: 48px;
  margin-bottom: 8px;
  margin-top: -29px;
  margin-left: 13px;
  font-family: 'Roboto Condensed';
}

@media (max-width: 767px) {
  .p-home__technology-content h5 {
    font-size: 24px;
    margin-left: 0;
    margin-bottom: 0;
    line-height: 32px;
  }
}

.p-home__technology-content p {
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  margin-bottom: 24px;
  margin-left: 11px;
  font-family: 'Roboto Condensed';
}

@media (max-width: 767px) {
  .p-home__technology-content p {
    font-size: 20px;
    margin-left: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    margin-bottom: 6px;
  }
}

.p-home__technology-content div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.p-home__technology-content .btn-technology {
  height: 48px;
}

.p-home__technology-content div a {
  padding: 11px;
  background-color: transparent;
  font-family: 'Roboto Condensed';
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  font-style: normal;
}

@media (max-width: 767px) {
  .p-home__technology-content div {
    margin-left: -19px;
  }

  .p-home__technology-content div a {
    font-family: 'Roboto Condensed';
    padding: 12px 13.5px;
    margin-left: 7px;
  }

  .p-home__technology-content div a:hover {
    font-family: 'Roboto Condensed';
    opacity: 1;
  }
}

.p-home__technology-content div img {
  margin-left: 7px;
}

@media (min-width: 768px) and (max-width: 1080px) {
  .p-home__technology-content {
    width: 47%;
    position: absolute;
    bottom: 10px;
    left: 60px;
    z-index: 1;
  }

  .p-home__technology-content p {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 24px;
  }
}

@media (min-width: 1200px) and (max-width: 1440px) {
  .p-home__technology-content {
    width: 60%;
    bottom: 20px;
    left: 60px;
  }

  .p-home__technology-content h5 {
    margin-bottom: 5px;
  }

  .p-home__technology-content p {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 8px;
  }
}

.p-home__technology .slick-list {
  overflow: visible;
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
}

.p-home__technology .slick-dots {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex !important;
  position: absolute;
  top: 8px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: 1;
  display: block;
  width: 1144px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-left: -12px;
  margin-right: -12px;
}

@media (max-width: 1199px) {
  .p-home__technology .slick-dots {
    margin-left: 0;
    margin-right: 0;
  }
}

.p-home__technology .slick-dots li {
  padding-left: 12px;
  padding-right: 12px;
  -webkit-box-flex: 25%;
  -ms-flex: 25%;
  flex: 25%;
  max-width: 25%;
  margin-bottom: 16px;
}

.p-home__technology .slick-dots li.slick-active button,
.p-home__technology .slick-dots li:hover button {
  color: #001530;
  background-color: #fff;
  font-weight: 700;
}

.p-home__technology .slick-dots li button {
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  text-align: center;
  color: rgba(255, 255, 255, 0.56);
  padding: 9px 10px;
  border: 1px solid #ffffff;
  border-radius: 32px;
  width: 100%;
  background-color: transparent;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

.p-home__technology .slick-dots li button:hover {
  font-weight: 700;
}

@media (max-width: 1199px) {
  .p-home__technology .slick-dots li button {
    font-size: 13px;
  }
}

.p-home__technology .slick-arrow {
  border: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 90px;
  height: 90px;
  background: rgba(255, 255, 255, 0.4);
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 1;
}

.p-home__technology .slick-arrow.slick-prev {
  left: 0;
  top: 63.5%;
}

.p-home__technology .slick-arrow.slick-next {
  right: 0;
  top: 63.5%;
}

.p-home__solution {
  padding-top: 66px;
  padding-bottom: 17px;
  background-size: cover;
}

@media (max-width: 767px) {
  .p-home__solution {
    padding-top: 27px;
  }
}

.p-home__solution .c-title {
  margin-bottom: 21.25px;
}

@media (max-width: 767px) {
  .p-home__solution .c-title {
    text-align: center;
    margin-bottom: 94px;
  }
}

@media (max-width: 767px) {
  .p-home__solution .c-title p {
    width: 314px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: -6px;
  }
}

.p-home__solution .c-title .c-btn {
  display: none;
}

@media (max-width: 767px) {
  .p-home__solution .c-title .c-btn {
    display: inline-block;
    margin-top: 24px;
    padding: 12px 34px;
  }
}

@media (max-width: 767px) {
  .p-home__solution .l-row {
    display: none;
  }
}

.p-home__solution .l-col {
  -webkit-box-flex: 33.33333%;
  -ms-flex: 33.33333%;
  flex: 33.33333%;
  max-width: 33.33333%;
  margin-bottom: 45px;
}

@media (max-width: 1199px) {
  .p-home__solution .l-col {
    -webkit-box-flex: 50%;
    -ms-flex: 50%;
    flex: 50%;
    max-width: 50%;
  }
}

.p-home__solution-slider {
  display: none;
}

.p-home__solution-slider-count {
  display: none;
  text-align: center;
}

@media (max-width: 767px) {
  .p-home__solution-slider-count {
    display: block;
    position: relative;
    top: -40px;
    /* left: 18px; */
  }
}

.p-home__solution-slider-count .current-slide {
  font-family: 'UTM Bebas';
  font-style: normal;
  font-weight: 400;
  font-size: 60px;
  line-height: 80px;
  /* position: absolute; */
  top: -30px;
  left: 30px;
}

.p-home__solution-slider-count .separate::before {
  content: url('/assets/img/home/icn_separate.svg');
  position: relative;
  top: 4px;
  left: 13px;
}

.p-home__solution-slider-count .total-slide {
  font-family: 'UTM Bebas';
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 100px;
  color: #9a9999;
  position: relative;
  top: 16px;
  left: -8px;
}

@media (max-width: 767px) {
  .p-home__solution-slider {
    display: block;
    margin-right: 25px;
    margin-left: 12px;
  }

  .p-home__solution-slider .slick-list {
    overflow: visible;
  }

  .p-home__solution-slider .slick-arrow {
    width: 40px;
    height: 40px;
    border: none;
    background: rgba(255, 255, 255, 0.4);
    position: absolute;
    top: -118px;
  }

  .p-home__solution-slider .slick-prev.slick-arrow {
    left: -10px;
  }

  .p-home__solution-slider .slick-next.slick-arrow {
    right: -25px;
  }
}

.p-home__solution-item {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  position: relative;
}

@media (max-width: 767px) {
  .p-home__solution-item::before {
    content: '';
    width: 3px;
    height: 178px;
    position: absolute;
    background-color: #34a3fd;
    border-radius: 6px;
    left: -19px;
    top: 120px;
  }

  .p-home__solution-item {
    left: 8px;
    padding: 10px;
  }
}

.p-home__solution-item h3 {
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  margin-bottom: 16px;
  min-height: unset;
  font-family: 'Roboto Condensed';
}

.l-col:first-child .p-home__solution-item h3 {
  margin-bottom: 0;
}

.l-col:first-child .p-home__solution-item .img_prize {
  width: 216.9px;
  margin-bottom: 16px;
}

.p-home__solution-item .home-news {
  /*display: -webkit-box;*/
  /*word-break: break-word;*/
  /*white-space: break-spaces;*/
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 10px;
  padding-right: 35px;
  font-family: 'Roboto Condensed';
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@media (max-width: 767px) {
  .p-home__solution-item h3 {
    padding-right: 10px;
  }

  .p-home__solution-item p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    min-height: 96px;
  }
}

.p-home__solution-icon {
  width: 96px;
  height: 96px;
  background-color: #fff;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 24px;
}

.p-home__solution-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  -webkit-box-align: center;
  -ms-flex-align: center;
  display: flex;
  align-items: center;
}

.btn-solution {
  height: 48px;
}

.p-home__solution-btn .c-btn {
  background-color: transparent;
  padding-left: 0;
  padding-right: 0;
  font-family: 'Roboto Condensed' !important;
  color: #fff;
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 24px;
}

@media (max-width: 767px) {
  .p-home__solution-btn .c-btn {
    font-size: 16px;
  }
}

@media (min-width: 992px) {
  .p-home__solution-btn .c-btn:hover {
    padding: 12px 16px;
    background-color: #34a3fd;
    opacity: 1;
    font-size: 16px;
    width: 124px;
    display: flex;
    justify-content: center;
    /* height: 48px; */
  }
}

.p-home__solution-btn img {
  margin-left: 10px;
  margin-top: 4px;
}

/* .p-home__solution-btn:first-child img {
  margin-top: 0;
} */

.p-home__products {
  padding: 174px 0 50px;
}

@media (max-width: 767px) {
  .p-home__solution-btn .c-btn:hover {
    font-size: 16px;
    font-weight: 500;
  }

  .p-home__products {
    padding: 80px 0 50px;
  }
}

.p-home__products .l-container {
  width: 100%;
  padding: 0;
}

@media (max-width: 767px) {
  .p-home__products .l-container {
    padding: 0 24px;
  }
}

.p-home__products .c-title {
  position: relative;
  z-index: 2;
  bottom: 32px;
}

@media (max-width: 767px) {
  .p-home__products .c-title {
    margin-bottom: 81px;
  }
}

.p-home__products .c-title h3 {
  padding-bottom: 18px;
}

.p-home__products .c-title p {
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  text-align: center;
  letter-spacing: -0.02em;
  margin-bottom: 24px;
}

@media (max-width: 767px) {
  .p-home__products .c-title p {
    font-size: 20px;
    line-height: 32px;
    width: 307px;
    margin: 0 auto 30px;
  }
}

.p-home__products .c-title .c-btn {
  padding: 14px 30.75px;
}

.p-home__products-left {
  -webkit-box-flex: 50%;
  -ms-flex: 50%;
  flex: 50%;
  max-width: 50%;
  position: relative;
}

@media (max-width: 1199px) {
  .p-home__products-left {
    margin-top: 50px;
  }
}

.p-home__products-left__image {
  position: absolute;
  top: calc(50% - 50px);
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

@media (max-width: 1279px) {
  .p-home__products-left__image {
    top: calc(50% - 150px);
  }
}

@media (max-width: 1199px) {
  .p-home__products-left__image {
    top: calc(50% - 200px);
  }

  .p-home__products-left__image img {
    width: 90%;
    margin: auto;
  }
}

@media (min-width: 1200px) {
  .p-home__products-left .item01 img {
    width: 254.33px;
    height: 494px;
    max-width: 100%;
    max-height: 100%;
  }
}

@media (min-width: 768px) and (max-width: 800px) {
  .p-home__products-left__image {
    top: calc(50% - 225px);
  }

  .item01 {
    top: calc(50% - 360px);
  }
}

@media (min-width: 801px) and (max-width: 860px) {
  .p-home__products-left__image {
    top: calc(50% - 195px);
  }

  .item01 {
    top: calc(50% - 320px);
  }
}

@media (max-width: 767px) {
  .p-home__products-left__image {
    top: 50%;
  }

  .p-home__products-left__image img {
    width: 100%;
  }

  .p-home__products-left .item01 img {
    width: 132.84px;
    height: 246.13px;
    max-width: 100%;
    max-height: 100%;
  }

  .item01 {
    top: calc(50% + 50px);
  }
}

@media (max-width: 767px) {
  .p-home__products-left {
    -webkit-box-flex: 100%;
    -ms-flex: 100%;
    flex: 100%;
    max-width: 100%;
    margin-top: 27px;
    padding: 0 5px;
  }
}

.p-home__products-right {
  -webkit-box-flex: 50%;
  -ms-flex: 50%;
  flex: 50%;
  max-width: 50%;
}

@media (max-width: 767px) {
  .p-home__products-right {
    -webkit-box-flex: 100%;
    -ms-flex: 100%;
    flex: 100%;
    max-width: 100%;
    padding: 0 5px;
  }
}

.p-home__products-right-inner {
  margin-top: 198px;
  width: 527px;
  max-width: 100%;
  margin-right: auto;
}

@media (max-width: 1199px) {
  .p-home__products-right-inner {
    margin-top: 30px;
  }
}

@media (max-width: 767px) {
  .p-home__products-right-inner {
    margin-top: -25px;
  }
}

.p-home__products-slider {
  margin-top: -133px;
}

@media (max-width: 1199px) {
  .p-home__products-slider {
    margin-top: 0;
  }
}

.p-home__products-slider-count {
  text-align: center;
}

@media (min-width: 768px) {
  .p-home__products-slider-count {
    text-align: right;
    padding-right: 30px;
    margin-bottom: -69px;
  }

  .hide-pc {
    display: none;
  }
}

@media (min-width: 1200px) {
  .footer-col-menu {
    max-width: 22% !important;
  }

  .l-footer__main .l-row>div.l-footer__main__office {
    margin-bottom: 13px;
    max-width: 33% !important;
  }

  .l-footer__main__menu {
    margin-left: 5.0520833333vw;
  }
}

@media (max-width: 767px) {
  .p-home__products-slider-count {
    display: block;
    position: relative;
    top: 0px;
    left: 14px;
  }
}

.p-home__products-slider-count .current-slide {
  font-family: 'UTM Bebas';
  font-style: normal;
  font-weight: 400;
  font-size: 80px;
  line-height: 80px;
  top: -32px;
  left: 32px;
}

@media (max-width: 767px) {
  .p-home__products-slider-count .current-slide {
    font-size: 60px;
    line-height: 80px;
    position: absolute;
    top: -32px;
    left: 33%;
  }
}

.p-home__products-slider-count .separate::before {
  content: url('/assets/img/home/icn_separate.svg');
  position: relative;
  top: 4px;
  left: 13px;
}

.p-home__products-slider-count .total-slide {
  font-family: 'UTM Bebas';
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 100px;
  color: #9a9999;
  position: relative;
  top: 16px;
  left: -8px;
}

@media (min-width: 768px) {
  .p-home__products-slider-count .total-slide {
    font-size: 60px;
  }
}

@media (max-width: 767px) {
  .p-home__products-slider .slick-arrow {
    width: 40px;
    height: 40px;
    border: none;
    background: rgba(255, 255, 255, 0.4);
    position: absolute;
    top: -118px;
  }

  .p-home__products-slider .slick-arrow.slick-prev {
    left: -12px;
  }

  .p-home__products-slider .slick-arrow.slick-next {
    right: -25px;
  }
}

.p-home__products-item .l-row {
  width: 1465px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.p-home__products-item h3 {
  font-family: 'UTM Bebas';
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 80px;
  text-transform: uppercase;
}

@media (max-width: 767px) {
  .p-home__products-item h3 {
    margin-bottom: 7px;
  }
}

.p-home__products-item p {
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: -0.02em;
}

@media (max-width: 767px) {
  .p-home__products-item p {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }
}

.p-home__products-features {
  margin-top: 51px;
}

.p-home__products-features-icon {
  min-width: 56px;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background: #4291f6;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-right: 32px;
}

.p-home__products-features-info h5 {
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 4px;
  margin-top: -2px;
}

@media (max-width: 767px) {
  .p-home__products-features-info h5 {
    font-size: 20px;
  }
}

.p-home__products-features-info p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  min-height: 48px;
}

.p-home__products-features li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 25px;
  position: relative;
}

@media (max-width: 767px) {
  .p-home__products-features li {
    margin-bottom: 23px;
  }
}

.p-home__products-item-03 .p-home__products-features li:nth-child(2)::before,
.p-home__products-item-01 .p-home__products-features li:nth-child(3)::before {
  height: calc(100% - 48%);
}

.p-home__products-item-02 .p-home__products-features li:nth-child(3)::before,
.p-home__products-item-03 .p-home__products-features li:nth-child(3)::before {
  height: calc(100% - 60%);
}

.p-home__products-features li:last-child::before {
  opacity: 0;
}

.p-home__products-features li::before {
  /* content: "";
    width: 2px;
    height: 28px;
    background-color: #4291F6;
    position: absolute;
    left: 28px;
    bottom: -12px;
    z-index: 1; */
  content: '';
  width: 2px;
  height: calc(100% - 60%);
  margin: 65px 0 -6px 0;
  position: absolute;
  left: 28px;
  bottom: -10px;
  z-index: 1;
}

@media (max-width: 820px) {

  .p-home__products-item-03 .p-home__products-features li:nth-child(3)::before,
  .p-home__products-item-02 .p-home__products-features li:nth-child(1)::before,
  .p-home__products-item-02 .p-home__products-features li:nth-child(3)::before,
  .p-home__products-item-01 .p-home__products-features li:nth-child(4)::before,
  .p-home__products-item-01 .p-home__products-features li:nth-child(5)::before,
  .p-home__products-item-01 .p-home__products-features li:nth-child(1)::before {
    height: calc(100% - 49%);
    bottom: -8px;
  }

  .p-home__products-item-03 .p-home__products-features li:nth-child(2)::before,
  .p-home__products-item-01 .p-home__products-features li:nth-child(3)::before {
    height: calc(100% - 40%);
    bottom: -8px;
  }

  /* ipad air page product */
  .p-products__block-02 .p-home__products-features li:nth-child(1)::before,
  .p-products__block-01 .p-home__products-features li:nth-child(5)::before,
  .p-products__block-01 .p-home__products-features li:nth-child(4)::before,
  .p-products__block-01 .p-home__products-features li:nth-child(1)::before {
    height: calc(100% - 47%);
    bottom: -10px;
  }

  .p-products__block-01 .p-home__products-features li:nth-child(3)::before {
    height: calc(100% - 32%);
    bottom: -10px;
  }
}

@media (max-width: 768px) {

  /* page home */
  .p-home__products-item-01 .p-home__products-features li:nth-child(3)::before {
    height: calc(100% - 35%);
  }

  .p-home__products-item-01 .p-home__products-features li:nth-child(5)::before {
    height: calc(100% - 40%);
  }

  /* page product */
  .p-products__block-01 .p-home__products-features li:nth-child(5)::before {
    height: calc(100% - 35%);
    bottom: -10px;
  }

  .p-products__block-03 .p-home__products-features li:nth-child(1)::before {
    height: calc(100% - 47%);
  }

  .p-products__block-02 .p-products__block-left {
    bottom: 53px;
  }
}

@media (max-width: 767px) {
  .p-home__products-features li::before {
    left: 27px;
    bottom: -6px;
  }
}

@media (max-width: 414px) {
  .p-home__products-features li::before {
    left: 6.68%;
    margin: 65px 0 -2px 0;
  }

  .p-home__products-features-icon {
    min-width: 48px;
    width: 48px;
    height: 48px;
  }

  .p-home__products-features-icon>img {
    width: 48px;
    height: 48px;
    object-fit: cover;
  }

  .p-home__products-item-03 .p-home__products-features li:nth-child(1)::before,
  .p-home__products-item-03 .p-home__products-features li:nth-child(3)::before,
  .p-home__products-item-02 .p-home__products-features li:nth-child(1)::before,
  .p-home__products-item-02 .p-home__products-features li:nth-child(3)::before,
  .p-home__products-item-01 .p-home__products-features li:nth-child(5)::before,
  .p-home__products-item-01 .p-home__products-features li:nth-child(1)::before {
    height: calc(100% - 50%);
    bottom: -8px;
  }

  .p-home__products-item-03 .p-home__products-features li:nth-child(1)::before {
    height: calc(100% - 62%);
  }

  .p-home__products-item-02 .p-home__products-features li:nth-child(2)::before,
  .p-home__products-item-01 .p-home__products-features li:nth-child(2)::before {
    height: calc(100% - 60%);
    bottom: -8px;
  }

  .p-home__products-item-01 .p-home__products-features li:nth-child(3)::before {
    height: calc(100% - 34%);
    bottom: -8px;
  }

  .p-home__products-item-03 .p-home__products-features li:nth-child(2)::before {
    height: calc(100% - 40%);
    bottom: -8px;
  }

  /* page product */
  .p-products__block-01 .p-home__products-features li:nth-child(4)::before,
  .p-products__block-01 .p-home__products-features li:nth-child(1)::before {
    bottom: 0;
  }

  .p-products__block-02 .p-home__products-features li:nth-child(1)::before,
  .p-products__block-01 .p-home__products-features li:nth-child(5)::before {
    bottom: 0;
    height: calc(100% - 47%);
  }

  .p-products__block:nth-child(1) .p-products__block-right .p-home__products-features li:nth-child(3):before {
    height: calc(100% - 39%);
    bottom: 0;
  }

  .p-products__block-03 .p-home__products-features li:nth-child(1)::before {
    height: calc(100% - 60%);
  }
}

@media (max-width: 414px) and (min-width: 376px) {
  .slick-slide:nth-child(1) .p-home__products-features li:nth-child(2):before {
    bottom: -13px;
  }

  .slick-slide:nth-child(1) .p-home__products-features li:nth-child(3):before {
    bottom: 7px;
  }

  /* .slick-slide:nth-child(2) .p-home__products-features li:nth-child(2):before {
    bottom: -12px;
  } */

  /* .slick-slide:nth-child(2) .p-home__products-features li:nth-child(3):before {
    bottom: -13px;
  } */

  /* .slick-slide:nth-child(3) .p-home__products-features li:nth-child(1):before {
    bottom: -12px;
  } */

  /* .slick-slide:nth-child(3) .p-home__products-features li:nth-child(2):before {
    bottom: -3px;
    height: 58px;
  } */

  /* .slick-slide:nth-child(3) .p-home__products-features li:nth-child(3):before {
    height: 37.84px;
  } */
}

@media (max-width: 395px) {
  .p-home__products-item-03 .p-home__products-features li:nth-child(1)::before {
    height: calc(100% - 50%);
  }
}

@media (max-width: 390px) {
  .p-products__block:nth-child(1) .p-products__block-right .p-home__products-features li:nth-child(3):before {
    height: calc(100% - 33%);
  }

  .p-home__products-item-03 .p-home__products-features li:nth-child(2)::before {
    height: calc(100% - 34%);
    bottom: -8px;
  }
}

@media (max-width: 375px) {
  .p-home__products-features li::before {
    left: 7.3%;
  }

  .p-home__products-item-02 .p-home__products-features li:nth-child(2)::before,
  .p-home__products-item-02 .p-home__products-features li:nth-child(3)::before,
  .p-home__products-item-01 .p-home__products-features li:nth-child(2)::before {
    height: calc(100% - 49%);
    bottom: -8px;
  }

  .p-home__products-item-03 .p-home__products-features li:nth-child(3)::before,
  .p-home__products-item-02 .p-home__products-features li:nth-child(3)::before {
    height: calc(100% - 39%);
  }

  .p-home__products-item-03 .p-home__products-features li:nth-child(1)::before {
    height: calc(100% - 50%);
  }

  .p-home__products-item-01 .p-home__products-features li:nth-child(4)::before,
  .p-home__products-item-01 .p-home__products-features li:nth-child(5)::before {
    height: calc(100% - 40%);
    bottom: -8px;
  }

  .p-home__products-item-03 .p-home__products-features li:nth-child(2)::before {
    height: calc(100% - 34%);
    bottom: -8px;
  }

  .slick-slide:nth-child(1) .p-home__products-features li:nth-child(3):before {
    bottom: 7px;
  }

  .slick-slide:nth-child(1) .p-home__products-features li:nth-child(5):before {
    bottom: 8px;
  }

  /* .slick-slide:nth-child(2) .p-home__products-features li:nth-child(3):before {
    bottom: -13px;
  } */

  /* .slick-slide:nth-child(3) .p-home__products-features li:nth-child(2):before {
    bottom: -3px;
    height: 77px;
  } */

  /* .slick-slide:nth-child(3) .p-home__products-features li:nth-child(3):before {
    height: 37.84px;
  } */

  .p-products__block:nth-child(1) .p-products__block-right .p-home__products-features li:nth-child(3):before {
    height: calc(100% - 34%);
    bottom: 0;
  }

  .p-products__block-03 .p-home__products-features li:nth-child(1)::before {
    height: calc(100% - 47%);
  }
}

@media (max-width: 360px) {
  .p-home__products-item-01 .p-home__products-features li:nth-child(3)::before {
    height: calc(100% - 30%);
  }

  .p-home__products-item-03 .p-home__products-features li:nth-child(3)::before,
  .p-home__products-item-02 .p-home__products-features li:nth-child(3)::before,
  .p-home__products-item-01 .p-home__products-features li:nth-child(4)::before {
    height: calc(100% - 40%);
  }

  .p-home__products-item-01 .p-home__products-features li:nth-child(5)::before {
    height: calc(100% - 33%);
  }

  .p-products__block-02 .p-home__products-features li:nth-child(2)::before,
  .p-products__block-01 .p-home__products-features li:nth-child(2)::before {
    height: calc(100% - 47%);
  }

  .p-products__block-01 .p-home__products-features li:nth-child(5)::before {
    height: calc(100% - 39%);
  }
}

@media (max-width: 320px) {

  .p-home__products-item-03 .p-home__products-features li:nth-child(3)::before,
  .p-home__products-item-02 .p-home__products-features li:nth-child(3)::before,
  .p-home__products-item-01 .p-home__products-features li:nth-child(5)::before,
  .p-home__products-item-01 .p-home__products-features li:nth-child(4)::before {
    height: calc(100% - 28%);
  }

  .p-home__products-item-02 .p-home__products-features li:nth-child(1)::before,
  .p-home__products-item-01 .p-home__products-features li:nth-child(1)::before {
    height: calc(100% - 40%);
  }

  .p-home__products-item-01 .p-home__products-features li:nth-child(3)::before {
    height: calc(100% - 26%);
  }

  .p-products__block-02 .p-home__products-features li:nth-child(1)::before,
  .p-products__block-01 .p-home__products-features li:nth-child(1)::before {
    height: calc(100% - 39%);
  }

  .p-home__products-item-03 .p-home__products-features li:nth-child(2)::before,
  .p-products__block:nth-child(1) .p-products__block-right .p-home__products-features li:nth-child(3):before {
    height: calc(100% - 29%);
  }

  /* .p-home__products-item-03 .p-home__products-features li:nth-child(3)::before{
    height: calc(100% - 33%);
  } */

  .p-home__solution {
    padding: 27px 0 0 !important;
  }

  .p-home__products {
    padding: 50px 0 0 !important;
  }

  .p-home__products-left .pt320 {
    padding-top: 50px;
  }

  .p-home__products-item-03 .p-home__products-features li:nth-child(1)::before,
  .p-products__block-01 .p-home__products-features li:nth-child(5)::before,
  .p-products__block-01 .p-home__products-features li:nth-child(4)::before {
    height: calc(100% - 32%);
  }
}

.p-home__products .slick-dots {
  text-align: center;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: -15px;
  z-index: 1;
}

@media (max-width: 1199px) {
  .p-home__products .slick-dots {
    bottom: -25px;
  }
}

.p-home__products .slick-dots li {
  display: inline-block;
  margin: 0 2px;
}

.p-home__products .slick-dots li.slick-active button {
  width: 39px;
  background-color: #fff;
}

.p-home__products .slick-dots li button {
  width: 14px;
  height: 8px;
  background: #9c9c9c;
  border-radius: 8px;
  border: none;
  font-size: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.p-home__products .slick-arrow {
  border: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 90px;
  height: 90px;
  background: rgba(255, 255, 255, 0.4);
  position: absolute;
  top: 41.3%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 1;
}

.p-home__news-content {
  vertical-align: middle;
  display: block;
  display: -webkit-box;
  max-width: 100%;
  font-size: 16px;
  line-height: 24px;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.p-home__news .c-title p {
  margin-top: -11px;
  color: #ffffff;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -0.02em;
}

@media (max-width: 767px) {
  .p-home__products .slick-arrow {
    top: -86px;
    -webkit-transform: none;
    transform: none;
    width: 40px;
    height: 40px;
  }
}

.p-home__products .slick-arrow.slick-prev {
  left: 0;
}

.p-home__products .slick-arrow.slick-next {
  right: 0;
}

.p-home__facesdk {
  padding: 169px 0 70px;
}

@media (max-width: 767px) {
  .p-home__facesdk {
    padding: 145px 0 70px;
  }
}

.p-home__facesdk .l-container {
  width: 1114px;
  padding-left: 7px;
  padding-right: 7px;
}

@media (max-width: 1199px) {
  .p-home__facesdk .l-container {
    padding-left: 25px;
    padding-right: 25px;
  }
}

.p-home__facesdk .c-title {
  position: relative;
  margin-bottom: 54px;
}

@media (max-width: 767px) {
  .p-home__facesdk .c-title {
    margin-bottom: 60px;
  }
}

.p-home__facesdk .c-title p {
  text-align: center;
  letter-spacing: -0.02em;
  margin-bottom: 25px;
  font-weight: 500;
  color: #fff;
}

@media (max-width: 767px) {
  .p-home__facesdk .c-title p {
    width: 319px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: -7px;
  }
}

.p-home__facesdk .c-title .c-btn {
  padding: 14px 30.75px;
}

.p-home__facesdk .l-row {
  margin-left: -7px;
  margin-right: -7px;
}

@media (max-width: 767px) {
  .p-home__facesdk .l-row.item-sp {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

.p-home__facesdk .l-row .l-col {
  padding-left: 12px;
  padding-right: 12px;
  -webkit-box-flex: 50%;
  -ms-flex: 50%;
  flex: 50%;
  max-width: 50%;
}

.p-home__facesdk-item {
  height: 100%;
}

.p-home__facesdk-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 16px;
  text-align: center;
  position: relative;
}

.p-home__facesdk-inner img {
  width: 100%;
}

.p-home__facesdk-inner h3 {
  font-weight: 700;
  font-size: 32px;
  line-height: 32px;
  position: absolute;
  bottom: 37px;
  width: 100%;
}

@media (max-width: 767px) {
  .p-home__facesdk-inner h3 {
    font-size: 20px;
    line-height: 32px;
    bottom: -49px;
  }
}

.p-home__news {
  padding: 126px 0 90px;
}

@media (max-width: 767px) {
  .p-home__news {
    padding: 114px 0 15px;
  }
}

.p-home__news .c-title {
  margin-bottom: 39px;
}

@media (max-width: 767px) {
  .p-home__news .c-title {
    text-align: center;
    margin-bottom: 0px;
  }
}

.p-home__news .c-title p {
  margin-top: -11px;
}

@media (max-width: 767px) {
  .p-home__news .c-title p {
    width: 259px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: -6px;
  }
}

.p-home__news .l-col-left {
  flex: 66.5%;
  max-width: 66.5%;
  width: 100%;
}

@media (max-width: 767px) {
  .p-home__news .l-col-left {
    -webkit-box-flex: 100%;
    -ms-flex: 100%;
    flex: 100%;
    max-width: 100%;
  }
}

.p-home__news .l-col-left .p-home__news-info {
  margin-top: 16px;
}

@media (max-width: 767px) {
  .p-home__news .l-col-left .p-home__news-info {
    margin-top: 16px;
  }
}

.p-home__news .l-col-right {
  flex: calc(100% - 66.5%);
  max-width: calc(100% - 66.5%);
}

@media (max-width: 767px) {
  .p-home__news .l-col-right {
    -webkit-box-flex: 100%;
    -ms-flex: 100%;
    flex: 100%;
    max-width: 100%;
  }
}

.p-home__news .l-col-right .p-home__news-info {
  margin-top: 24px;
}

.p-home__news .l-row {
  margin-left: -9px;
  margin-right: -9px;
}

.p-home__news .l-col {
  padding-left: 9px;
  padding-right: 9px;
}

.p-home__news .l-col-6 {
  -webkit-box-flex: 50%;
  -ms-flex: 50%;
  flex: 50%;
  max-width: 50%;
  margin-bottom: 37px;
}

@media (max-width: 767px) {
  .p-home__news .l-col {
    top: -48px;
  }

  .p-home__news-slider {
    margin-top: 78px;
  }

  .p-home__news-slider::before {
    content: '';
    position: absolute;
    /* width: 193px; */
    height: 1px;
    left: calc(41% + 30px);
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    top: -39px;
  }

  .item-sp-2::before {
    content: '';
    position: absolute;
    /* width: 193px; */
    height: 1px;
    left: calc(41% + 30px);
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    top: -39px;
    background-color: rgba(255, 255, 255, 0.65);
  }

  .p-home__news-slider .slick-list {
    overflow: visible;
    margin-left: -8px;
    margin-right: -8px;
  }

  .p-home__news-slider .slick-slide {
    margin: 0 0 0 -6px;
    padding: 0 15px;
  }

  /* .p-home__news-slider .slick-slide img {
    aspect-ratio: 12/9;
  } */
  .p-home__news-slider .p-home__news-cate {
    margin-bottom: 0;
  }

  /* .slick-slider.p-home__news-slider.item-sp.item-sp-2.slick-initialized a{
    height: 194px;
  } */
  /* .slick-slider.p-home__news-slider.item-sp.item-sp-2.slick-initialized .p-home__news-item {
    width: 81.4% !important;
  } */
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.p-home__news-item a {
  display: block;
}

.p-home__news-info h3 {
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.02em;
  color: #ffffff;
  margin-bottom: 8px;
  cursor: pointer;
}

@media (max-width: 767px) {
  .p-home__news-info h3 {
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
  }
}

.p-home__news-info h4 {
  cursor: pointer;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #ffffff;
}

.p-home__news-info p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #ffffff;
  margin-bottom: 4px;
  padding-right: 10px;
}

.p-home__news-info .c-btn {
  margin-top: 30px;
  padding: 14.5px 21.5px;
  display: inline-block;
  /* font-family: 'Inter'; */
  font-family: 'Roboto Condensed';
}

.p-home__news-cate {
  font-weight: 500;
  font-size: 12px;
  line-height: 32px;
  letter-spacing: -0.02em;
  color: #ffffff;
  margin-bottom: -6px;
}

@media (max-width: 767px) {
  .p-home__news-info h4 {
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    margin-bottom: 15px;
    color: rgba(255, 255, 255, 0.8);
  }

  .slick-slider.p-home__news-slider.item-sp.item-sp-2.slick-initialized .p-home__news-info h4 {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
  }

  .p-home__news-content {
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 15px;
    color: rgba(255, 255, 255, 0.8);
  }

  .p-home__news-info p {
    margin-bottom: 23px;
  }

  .p-home__news-info .c-btn {
    display: none;
  }

  .p-home__news-cate {
    line-height: 20px;
    color: rgba(255, 255, 255, 0.8);
  }

  .p-home__news-time {
    color: rgba(255, 255, 255, 0.56) !important;
  }

  .hide_button {
    display: none;
  }
}

.p-home__news-time {
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #ffffff;
}

.p-home__fields {
  padding: 46px 0 0;
}

.p-home__fields .c-title {
  margin-bottom: 87px;
}

.p-home__fields .c-title h3 {
  padding-bottom: 16px;
}

.p-home__fields .c-title p {
  font-weight: 500;
}

@media (max-width: 767px) {
  .p-home__fields {
    padding-top: 22px;
    padding-bottom: 159px;
  }

  .p-home__fields .c-title {
    margin-bottom: 15px;
  }

  .p-home__fields .c-title p {
    width: 236px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}

.p-home__fields .l-row {
  margin-left: -21px;
  margin-right: -21px;
}

.p-home__fields .l-col {
  -webkit-box-flex: 33.33333%;
  -ms-flex: 33.33333%;
  flex: 33.33333%;
  max-width: 33.33333%;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 30px;
}

@media (max-width: 1199px) {
  .p-home__fields .l-col {
    -webkit-box-flex: 50%;
    -ms-flex: 50%;
    flex: 50%;
    max-width: 50%;
  }
}

.p-home__fields .l-col:nth-child(odd) .p-home__fields-item {
  background: url('/assets/img/home/tech1.webp') no-repeat;
  background-size: 100% 100%;
}

.p-home__fields .l-col:nth-child(even) .p-home__fields-item {
  background: url('/assets/img/home/tech2.webp') no-repeat;
  background-size: 100% 100%;
}

/* .p-home__fields-slider {
  margin-right: 23px;
} */

.p-home__fields-slider .slick-track {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.p-home__fields-slider .slick-list {
  overflow: visible;
  margin-left: -8px;
  margin-right: -8px;
}

.p-home__fields-slider .slick-slide {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  height: auto;
  margin-right: 10px;
  padding: 9px;
}

.p-home__fields-slider .slick-slide>div {
  height: 100%;
}

.p-home__fields-slider .slick-slide>div .p-home__fields-box {
  height: 100%;
}

.p-home__fields-slider .slick-slide:nth-child(odd) .p-home__fields-item {
  /* background: radial-gradient(200.14% 200.14% at 50% 163%, #4776B3 8.96%, rgba(0, 36, 83, 0.1) 82.56%, rgba(189, 218, 255, 0) 100%); */

  background: url('/assets/img/home/tech2.png') no-repeat;
  background-size: 100% 100%;
}

.p-home__fields-slider .slick-slide:nth-child(even) .p-home__fields-item {
  /* background: -webkit-gradient(linear, left top, left bottom, color-stop(-235%, #002266), color-stop(9.31%, #004A7A), color-stop(63.64%, rgba(0, 33, 75, 0)), color-stop(124.61%, rgba(189, 218, 255, 0)));
  background: linear-gradient(180deg, #002266 -235%, #004A7A 9.31%, rgba(0, 33, 75, 0) 63.64%, rgba(189, 218, 255, 0) 124.61%); */

  background: url('/assets/img/home/tech1.png') no-repeat;
  background-size: 100% 100%;
}

.p-home__fields-slider .slick-dots {
  text-align: center;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: 25px;
  z-index: 1;
}

@media (max-width: 1199px) {
  .p-home__fields-slider .slick-dots {
    bottom: -25px;
  }
}

@media (max-width: 767px) {
  .p-home__fields-slider .slick-dots {
    bottom: -50px;
  }
}

.p-home__fields-slider .slick-dots li {
  display: inline-block;
  margin: 0 2px;
}

.p-home__fields-slider .slick-dots li.slick-active button {
  width: 39px;
  background-color: #fff;
}

.p-home__fields-slider .slick-dots li button {
  width: 14px;
  height: 8px;
  background: #9c9c9c;
  border-radius: 8px;
  border: none;
  font-size: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.p-home__fields-item {
  height: 100%;
  padding: 35px 18px 26px 44px;
  border-radius: 20px;
  /* border: 1.5px solid rgb(66, 145, 246); */
}

@media (max-width: 767px) {
  .p-home__fields-item {
    margin-top: 10px;
    padding: 21px 32px 22px 23px;
  }
}

.p-home__fields-item h3 {
  font-weight: 700;
  font-size: 32px;
  line-height: 32px;
  letter-spacing: -0.02em;
  margin-bottom: 10px;
}

@media (max-width: 767px) {
  .p-home__fields-item h3 {
    font-size: 20px;
  }
}

.p-home__fields-item ul li {
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: -0.02em;
  position: relative;
  padding-left: 15px;
}

@media (max-width: 767px) {
  .p-home__fields-item ul li {
    font-size: 16px;
  }
}

.p-home__fields-item ul li::before {
  content: '';
  position: absolute;
  top: 14px;
  left: 0;
  width: 3px;
  height: 3px;
  background-color: #fff;
  border-radius: 50%;
}

#header {
  position: fixed;
  margin-top: -10px;
}

.bgrColor {
  background: #000e33;
}

#header.hiddenn {
  opacity: 0;
}

#news-detail #header.sticky {
  background-color: #000e33;
}

#header.sticky {
  opacity: 1;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  /* -webkit-animation-name: fadeIn;
  animation-name: fadeIn; */
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  /* -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.15); */
  z-index: 100;
  animation: showHeder ease-in-out 0.5s;
}

.p-home__fields-icon {
  height: 80px;
  margin-bottom: 8px;
}

@media (max-width: 767px) {
  .p-home__fields-icon {
    height: 68px;
  }

  #header {
    margin-top: -3px;
  }

  .p-home__fields-icon img {
    height: 50px;
  }
}

.p-home__partner {
  /* padding: 142px 0 180px; */
  padding: 142px 0;
  background: url('/assets/img/home/bg_partner.webp') no-repeat center;
  background-size: cover;
}

@media (max-width: 767px) {
  .p-home__partner {
    padding-top: 4px;
    padding-bottom: 50px;
  }
}

.p-home__partner .c-title {
  margin-bottom: 67px;
}

@media (max-width: 767px) {
  .p-home__partner .c-title {
    margin-bottom: 54px;
  }
}

.p-home__partner .c-title p {
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  margin-top: 9px;
  font-family: 'Roboto Condensed';
  letter-spacing: 0;
}

@media (max-width: 767px) {
  .p-home__partner .c-title p {
    /* font-size: 16px; */
    font-weight: 500;
    font-family: 'Roboto Condensed';
    margin-top: -6px;
  }
}

.p-home__partner-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 67px 115px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

@media (max-width: 767px) {
  .p-home__partner-list {
    gap: initial;
    margin-left: -10px;
    margin-right: -10px;
  }
}

.p-home__partner-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media (max-width: 767px) {
  .p-home__partner-item {
    -webkit-box-flex: 33.33333%;
    -ms-flex: 33.33333%;
    flex: 33.33333%;
    max-width: 33.33333%;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 37px;
  }

  .p-home__partner-item:nth-child(7) {
    -webkit-box-flex: 40%;
    -ms-flex: 40%;
    flex: 40%;
    max-width: 40%;
  }

  .p-home__partner-item:nth-child(8) {
    -webkit-box-flex: 50%;
    -ms-flex: 50%;
    flex: 50%;
    max-width: 50%;
  }

  .p-home__partner-item img {
    width: 100%;
  }
}

.p-home__integrated {
  background: url('/assets/img/home/bg_integrated.webp') no-repeat center;
  background-size: cover;
  /* padding: 163px 0 335px; */
  padding: 60px 0 150px;
}

@media (max-width: 820px) {
  .p-home__integrated {
    /* padding: 163px 0 168px; */
  }
}

@media (max-width: 767px) {
  .p-home__integrated {
    padding: 21px 0 48px;
  }
}

.p-home__integrated .c-title {
  margin-bottom: 88px;
}

@media (max-width: 767px) {
  .p-home__integrated .c-title {
    margin-bottom: 66px;
  }
}

.p-home__integrated .c-title h3 {
  padding-bottom: 15px;
}

.p-home__integrated .c-title p {
  margin-bottom: 40px;
  font-weight: 500;
}

.p-home__integrated .l-col {
  -webkit-box-flex: 33.33333%;
  -ms-flex: 33.33333%;
  flex: 33.33333%;
  max-width: 33.33333%;
  position: relative;
}

@media (max-width: 767px) {
  .p-home__integrated .c-title p {
    font-size: 20px;
    line-height: 32px;
    margin-top: 3px;
    margin-bottom: 32px;
  }

  .p-home__integrated .l-col {
    -webkit-box-flex: 100%;
    -ms-flex: 100%;
    flex: 100%;
    max-width: 100%;
    margin-bottom: 40px;
  }

  .p-home__integrated .btn-home-contact {
    line-height: 19px;
    padding: 14.5px 54.5px;
  }
}

.p-home__integrated .btn-home-contact {
  font-family: 'Roboto Condensed';
}

.p-home__integrated .l-col:nth-child(3)::after {
  opacity: 0;
}

.p-home__integrated .l-col::after {
  content: '';
  width: 1px;
  height: 75px;
  background-color: #fff;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 0;
}

@media (max-width: 767px) {
  .p-home__integrated .l-col::after {
    width: 90px;
    height: 1px;
    background-color: rgba(255, 255, 255, 0.6);
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    bottom: -19px;
    top: auto;
  }
}

.p-home__integrated-item {
  text-align: center;
}

.p-home__integrated-item h3 {
  font-weight: 700;
  font-size: 32px;
  line-height: 160%;
  text-align: center;
  letter-spacing: -0.02em;
  margin-bottom: 8px;
}

@media (max-width: 767px) {
  .p-home__integrated-item h3 {
    font-size: 20px;
    line-height: 32px;
  }
}

.p-home__integrated-item p {
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
}

@media (max-width: 767px) {
  .p-home__integrated-item p {
    font-size: 16px;
    line-height: 24px;
  }
}

.p-home__integrated-icon {
  height: 79px;
  margin-bottom: 7px;
}

@media (max-width: 767px) {
  .p-home__integrated-icon {
    height: 65px;
  }
}

@media (max-width: 767px) {
  .p-home__integrated-icon img {
    width: 48px;
  }
}

.p-technology {
  position: relative;
}

.p-technology__main {
  position: relative;
  margin-top: -156px;
}

@media (max-width: 1279px) {
  .p-technology__main {
    margin-top: -100px;
  }
}

@media (max-width: 1199px) {
  .p-technology__main {
    margin-top: -90px;
  }
}

@media (max-width: 767px) {
  .p-technology__main {
    margin-top: -350px;
  }
}

.p-technology__box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 8.59375vw;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

@media (max-width: 767px) {
  .p-technology__box {
    display: block;
  }
}

.p-technology__box:nth-child(3) {
  margin-top: -15.8854166667vw;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.p-technology__box:nth-child(3) .p-technology__box__inner {
  margin-top: -203px;
}

.p-technology__box:nth-child(3) .p-technology__box__content__img {
  margin-top: 0;
}

.p-technology__box:nth-child(3) .p-technology__box__image-gif {
  top: 12%;
  left: -5%;
}

@media (max-width: 1199px) {
  .p-technology__box:nth-child(3) {
    margin-top: 50px;
  }
}

@media (max-width: 767px) {
  .p-technology__box:nth-child(3) {
    margin-top: 175px;
  }
}

.p-technology__box:nth-child(4).p-technology__box--right {
  margin-top: -16.7708333333vw;
}

@media (max-width: 1199px) {
  .p-technology__box:nth-child(4).p-technology__box--right {
    margin-top: -50px;
  }
}

@media (max-width: 767px) {
  .p-technology__box:nth-child(4).p-technology__box--right {
    margin-top: -176px;
  }
}

.p-technology__box:nth-child(4).p-technology__box--right .p-technology__box__inner {
  margin-top: 71px;
}

.p-technology__box:nth-child(5) {
  margin-top: -18.4375vw;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.p-technology__box:nth-child(5) .p-technology__box__inner {
  margin-top: -198px;
}

.p-technology__box:nth-child(5) .p-technology__box__content__img {
  margin-top: 0;
}

.p-technology__box:nth-child(5) .p-technology__box__image-gif {
  left: -14%;
  bottom: 172px;
}

@media (max-width: 1199px) {
  .p-technology__box:nth-child(5) {
    margin-top: 198px;
  }
}

.p-technology__box:nth-child(6) {
  margin-top: -24.5833333333vw;
}

.p-technology__box:nth-child(6) .p-technology__box__inner {
  margin-top: 208px;
}

@media (max-width: 1199px) {
  .p-technology__box:nth-child(6) .p-technology__box__inner {
    margin-top: -68px;
  }
}

@media (max-width: 1199px) {
  .p-technology__box:nth-child(6) {
    margin-top: 60px;
  }
}

@media (max-width: 767px) {
  .p-technology__box:nth-child(6) {
    margin-top: -175px;
  }
}

.p-technology__box:nth-child(7) {
  margin-top: -19.1145833333vw;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.p-technology__box:nth-child(7) .p-technology__box__inner {
  margin-top: -226px;
}

.p-technology__box:nth-child(7) .p-technology__box__image-gif {
  top: 12%;
  left: -5%;
}

.p-technology__box__image-gif--sp {
  top: 16%;
  left: 17%;
  right: 12%;
}

@media (max-width: 1199px) {
  .p-technology__box:nth-child(7) {
    margin-top: 180px;
  }
}

@media (max-width: 767px) {
  .p-technology__box:nth-child(7) {
    margin-top: 261px;
  }
}

.p-technology__box__image {
  -webkit-box-flex: calc(50% - 4.296875vw);
  -ms-flex: calc(50% - 4.296875vw);
  flex: calc(50% - 4.296875vw);
  max-width: calc(50% - 4.296875vw);
  position: relative;
}

.p-technology__box__image img {
  margin-top: -40px;
}

@media (max-width: 767px) {
  .p-technology__box__image {
    -webkit-box-flex: 100%;
    -ms-flex: 100%;
    flex: 100%;
    max-width: 100%;
  }

  .p-technology__box__image img {
    margin-top: -56px;
  }
}

.p-technology__box__inner {
  -webkit-box-flex: calc(50% - 4.296875vw);
  -ms-flex: calc(50% - 4.296875vw);
  flex: calc(50% - 4.296875vw);
  max-width: calc(50% - 4.296875vw);
}

@media (max-width: 767px) {
  .p-technology__box__inner {
    -webkit-box-flex: 100%;
    -ms-flex: 100%;
    flex: 100%;
    max-width: 100%;
    padding: 0 24px;
  }
}

.p-technology__box__title h3 {
  font-weight: 500;
  font-size: 40px;
  line-height: 48px;
  background: -webkit-gradient(linear,
      left top,
      left bottom,
      color-stop(32.76%, #4291f6),
      to(rgba(255, 255, 255, 0.68)));
  background: linear-gradient(180deg,
      #4291f6 32.76%,
      rgba(255, 255, 255, 0.68) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

@media (max-width: 767px) {
  .p-technology__box__title h3 {
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    color: #fff;
    background: none;
    -webkit-background-clip: unset;
    -webkit-text-fill-color: unset;
    background-clip: unset;
  }
}

.p-technology__box__content {
  text-align: left;
  max-width: 100%;
  margin-top: -13px;
}

.p-technology__box__content__img {
  margin-top: 61px;
  margin-left: -30px;
}

@media (max-width: 767px) {
  .p-technology__box__content__img img {
    width: 120px;
  }
}

.p-technology__box__desc {
  margin-top: 24px;
}

@media (max-width: 767px) {
  .p-technology__box__desc {
    margin-top: 10px;
  }
}

.p-technology__box__desc p {
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: #fff;
  width: 599px;
  max-width: 100%;
}

@media (max-width: 767px) {
  .p-technology__box__desc p {
    font-size: 16px;
    line-height: 24px;
  }
}

.p-technology__box__option {
  margin-top: 24px;
}

@media (max-width: 767px) {
  .p-technology__box__option {
    margin-top: 17px;
  }
}

.p-technology__box__option ul li {
  position: relative;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 16px;
  padding-left: 22px;
  width: 489px;
  max-width: 100%;
}

.p-technology__box__option ul li::after {
  content: '';
  position: absolute;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background: #fff;
  left: 7px;
  top: 9px;
}

@media (max-width: 767px) {
  .p-technology__box__option ul li {
    font-size: 14px;
    line-height: 20px;
    padding-left: 12px;
    margin-bottom: 8px;
  }

  .p-technology__box--right .p-technology__box__image-gif {
    top: 21%;
    left: unset;
    right: 8%;
  }

  .p-technology__box--right .p-technology__box__image-gif--sp {
    top: 27%;
    left: 12%;
  }

  .p-technology__box__option ul li::after {
    left: 0;
  }
}

.p-technology__box--right {
  margin-top: -344px;
}

@media (max-width: 1199px) {
  .p-technology__box--right {
    margin-top: 60px;
  }
}

@media (max-width: 767px) {
  .p-technology__box--right {
    margin-top: -261px;
  }
}

.p-technology__box--right .p-technology__box__inner {
  -webkit-box-flex: calc(50% - 150px);
  -ms-flex: calc(50% - 150px);
  flex: calc(50% - 150px);
  margin-top: 168px;
}

@media (max-width: 1199px) {
  .p-technology__box--right .p-technology__box__inner {
    margin-top: -68px;
  }
}

.p-technology__box--right .p-technology__box__image {
  text-align: end;
}

.p-technology__box--right .p-technology__box__image-gif {
  top: 8%;
  left: unset;
  right: 9%;
}

.p-technology__box--right .p-technology__box__image-gif--sp {
  top: 27%;
  left: 12%;
}

.p-technology__box--right .p-technology__box__content {
  text-align: end;
  margin-left: auto;
  margin-top: 35px;
}

@media (max-width: 767px) {
  .p-technology__box--right .p-technology__box__content {
    margin-top: 14px;
  }
}

.p-technology__box--right .p-technology__box__content__img {
  text-align: end;
  margin-right: -32px;
}

@media (max-width: 767px) {
  .p-technology__box--right .p-technology__box__content__img img+img {
    margin-left: auto;
  }
}

.p-technology__box--right .p-technology__box__desc {
  width: 489px;
  margin-left: auto;
  max-width: 100%;
}

.p-technology__box--right .p-technology__box__option ul li {
  margin-left: auto;
}

.p-technology__box--right .p-technology__box__option ul li::after {
  display: none;
}

.item-sp {
  display: none;
}

@media (max-width: 767px) {
  .item-sp {
    display: block;
  }

  .item-sp .slick-arrow {
    display: none !important;
  }

  .news-pc {
    display: none;
  }
}

@media (max-width: 767px) {
  .item-pc {
    display: none;
  }
}

.p-sdk .c-title p {
  font-family: 'Roboto Condensed';
  margin-top: -5px;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  width: 627px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  letter-spacing: 0;
  color: #fff;
}

@media (max-width: 767px) {
  .p-sdk .c-title p {
    font-size: 20px !important;
    line-height: 32px !important;
  }
}

.p-sdk__big-banner__content {
  width: 507px;
  max-width: calc(100% - 48px);
  position: absolute;
  top: calc(50% + 88px);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.p-sdk__big-banner__content-desc {
  font-weight: 700;
  font-size: 16px;
  line-height: 32px;
  text-transform: uppercase;
  color: #1f7aff;
}

.p-sdk__big-banner__content-title {
  font-weight: 700;
  font-size: 70px;
  line-height: 86px;
  text-transform: uppercase;
  color: #24b6ff;
  font-family: 'Roboto Condensed';
}

.p-sdk__big-banner__content-slogan {
  margin-top: 10px;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  font-family: 'Roboto Condensed';
}

.p-sdk__big-banner__content-handle {
  margin-top: 38px;
}

.p-sdk__big-banner__content-handle .c-btn {
  font-family: 'Roboto Condensed';
  padding: 12px 29px;
  line-height: 24px;
}

@media (max-width: 1199px) {
  .p-sdk__big-banner__content {
    top: 206px;
  }

  .p-sdk__big-banner__content-desc {
    font-size: 10px;
    line-height: 24px;
  }

  .p-sdk__big-banner__content-title {
    font-size: 32px;
    line-height: 48px;
  }

  .p-sdk__big-banner__content-slogan {
    font-size: 20px;
    line-height: 32px;
  }
}

@media (max-width: 767px) {
  .p-sdk__big-banner__content {
    top: 180px;
    text-align: center;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}

.p-sdk__main {
  margin-top: 45px;
}

.p-sdk__eyefire {
  padding-bottom: 50px;
}

.p-sdk__eyefire__img-bg {
  position: relative;
  width: 1119px;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
}

.p-sdk__eyefire__inner {
  width: 100%;
  top: 0;
  position: absolute;
  padding: 52px 120px;
}

.p-sdk__eyefire__inner-p {
  width: 765px !important;
}

.p-sdk__eyefire__content {
  margin-top: 73px;
}

.p-sdk__eyefire__content ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.p-sdk__eyefire__content ul li {
  -webkit-box-flex: clac(100%/3);
  -ms-flex: clac(100%/3);
  flex: clac(100%/3);
  max-width: 33.3333333333%;
}

.p-sdk__eyefire__content-image {
  margin: 0 -18px;
}

.p-sdk__eyefire__content__box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.p-sdk__eyefire__content__box-desc {
  margin-top: 41px;
}

.p-sdk__eyefire__content__box-desc h4 {
  font-weight: 700;
  font-size: 32px;
  line-height: 32px;
  font-family: 'Roboto Condensed';
}

@media (max-width: 1199px) {
  .p-sdk__eyefire__inner {
    padding: 19px;
  }
}

@media (max-width: 767px) {
  .p-sdk__eyefire__content {
    margin-top: 53px;
    position: relative;
  }

  .p-sdk__eyefire__content ul {
    position: absolute;
    width: 100%;
    top: 28px;
  }

  .p-sdk__eyefire__inner {
    top: -110px;
  }

  .p-sdk__eyefire__content__box-image img {
    width: 80px;
  }

  .p-sdk__eyefire__content__box-desc {
    margin-top: 8px;
  }

  .p-sdk__eyefire__content__box-desc h4 {
    font-size: 16px;
    line-height: 32px;
  }
}

.p-sdk__face {
  background: url('/assets/img/sdk/bg_face.webp') no-repeat;
  padding: 125px 0;
  background-position: 48% 120%;
}

@media (max-width: 767px) {
  .p-sdk__face {
    margin-top: 192px;
    background: url('/assets/img/sdk/bg_face-sp.jpg') no-repeat;
    background-size: cover;
  }
}

.p-sdk__face .c-title p {
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 0;
}

.p-sdk__face__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  gap: 30px;
  margin-left: -15px;
  margin-right: -15px;
  margin-top: 87px;
}

.p-sdk__face__content__inner {
  position: relative;
  -webkit-box-flex: calc(33.3333333333% - 30px);
  -ms-flex: calc(33.3333333333% - 30px);
  flex: calc(33.3333333333% - 30px);
  max-width: calc(33.3333333333% - 30px);
}

@media (max-width: 1199px) {
  .p-sdk__face__content__inner {
    -webkit-box-flex: calc(50% - 30px);
    -ms-flex: calc(50% - 30px);
    flex: calc(50% - 30px);
    max-width: calc(50% - 30px);
  }
}

@media (max-width: 767px) {
  .p-sdk__face__content__inner {
    -webkit-box-flex: 100%;
    -ms-flex: 100%;
    flex: 100%;
    max-width: 100%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

.p-sdk__face__content__box {
  padding: 52px 25px 25px;
  position: absolute;
  top: 0;
}

.p-sdk__face__content__box-icon {
  margin-bottom: 24px;
}

.p-sdk__face-pos {
  margin-bottom: 18px;
}

.p-sdk__face__content__box-title {
  margin-bottom: 17px;
}

.p-sdk__face__content__box-title h3 {
  font-weight: 700;
  font-size: 32px;
  line-height: 32px;
  letter-spacing: -0.02em;
  font-family: 'Roboto Condensed';
}

.p-sdk__face__content__box-desc p {
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
}

.p-sdk__face__content__box-desc p span {
  color: #ed2726;
}

@media (max-width: 767px) {
  .p-sdk__face__content__box-desc p {
    font-size: 16px;
    line-height: 24px;
  }
}

@media (max-width: 767px) {
  .p-sdk__face__content {
    margin-top: 54px;
    gap: 26px;
  }

  .p-sdk__face__content__box {
    padding: 30px 25px 25px;
  }

  .p-sdk__face__content__box-icon {
    margin-bottom: 15px;
  }

  .p-sdk__face__content__box-title {
    margin-bottom: 3px;
  }

  .p-sdk__face__content__box-title h3 {
    font-size: 20px;
    line-height: 32px;
  }
}

.p-sdk__option {
  margin-top: 97px;
}

@media (max-width: 767px) {
  .p-sdk__option {
    margin-top: -4px;
  }
}

.p-sdk__option .c-title p {
  margin-top: 3px;
  width: 456px;
}

.p-sdk__option__inner {
  margin-top: 82px;
  width: 1782px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  gap: 36px;
}

@media (max-width: 767px) {
  .p-sdk__option__inner {
    display: none;
    margin-top: 65px;
  }
}

.p-sdk__option__inner--slider {
  display: none;
}

@media (max-width: 767px) {
  .p-sdk__option__inner--slider {
    display: block;
  }
}

.p-sdk__option__box {
  -webkit-box-flex: calc(33.3333333333% - 36px);
  -ms-flex: calc(33.3333333333% - 36px);
  flex: calc(33.3333333333% - 36px);
  max-width: calc(33.3333333333% - 36px);
  position: relative;
}

.p-sdk__option__box__image {
  position: relative;
}

.p-sdk__option__box__image::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: -webkit-gradient(linear,
      left bottom,
      left top,
      color-stop(27.24%, #03316b),
      color-stop(78.98%, rgba(3, 49, 107, 0)));
  background: linear-gradient(0deg, #03316b 27.24%, rgba(3, 49, 107, 0) 78.98%);
  left: 0;
  bottom: 0;
}

.p-sdk__option__box__image img {
  -webkit-transition: 0.4s all;
  transition: 0.4s all;
}

@media (max-width: 555px) {
  .p-sdk__option__box__image::after {
    height: 246px;
  }
}

.p-sdk__option__box__content {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.p-sdk__option__box__content-icon {
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.p-sdk__option__box__content-desc {
  width: 100%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  position: absolute;
  bottom: 44px;
  text-align: center;
}

.p-sdk__option__box__content-desc h3 {
  font-weight: 700;
  font-size: 48px;
  line-height: 56px;
  letter-spacing: -0.02em;
  margin-bottom: 20px;
  font-family: 'Roboto Condensed';
}

.p-sdk__option__box__content-desc p {
  font-weight: 400;
  font-size: 32px;
  line-height: 32px;
  letter-spacing: -0.02em;
}

@media (max-width: 1199px) {
  .p-sdk__option__box__content-desc {
    bottom: 14px;
  }

  .p-sdk__option__box__content-desc h3 {
    font-size: 20px;
    line-height: 32px;
    margin-bottom: 0;
  }

  .p-sdk__option__box__content-desc p {
    font-size: 16px;
    line-height: 24px;
  }
}

@media (max-width: 767px) {
  .p-sdk__option__box__content {
    bottom: 6px;
  }

  .p-sdk__option__box__content-desc h3 {
    margin-bottom: 3px;
  }
}

.p-sdk__advantages {
  margin-top: 152px;
  padding-bottom: 274px;
  background: url('/assets/img/sdk/bg_advantages.webp') no-repeat center;
  background-size: cover;
}

@media (max-width: 1199px) {
  .p-sdk__advantages {
    padding-bottom: 80px;
    margin-top: 105px;
  }
}

.p-sdk__advantages .c-title h3 {
  letter-spacing: -0.02em;
}

.p-sdk__advantages__inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: -15px;
  margin-right: -15px;
  margin-top: 91px;
}

.p-sdk__advantages__inner>div {
  margin: 15px;
}

@media (max-width: 767px) {
  .p-sdk__advantages__inner {
    margin-left: 0;
    margin-right: 0;
    margin-top: 26px;
    gap: 25px 0;
  }
}

.p-sdk__advantages__box {
  -webkit-box-flex: calc(50% - 30px);
  -ms-flex: calc(50% - 30px);
  flex: calc(50% - 30px);
  max-width: calc(50% - 30px);
  position: relative;
}

@media (max-width: 767px) {
  .p-sdk__advantages__box {
    -webkit-box-flex: 100%;
    -ms-flex: 100%;
    flex: 100%;
    max-width: 100%;
  }
}

.p-sdk__advantages__box__content {
  position: absolute;
  width: 414px;
  max-width: 100%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: 61px;
  padding: 0 15px;
}

.p-sdk__advantages__box__content-title {
  text-align: center;
}

.p-sdk__advantages__box__content-title h3 {
  font-weight: 700;
  font-size: 32px;
  line-height: 32px;
  letter-spacing: -0.02em;
  margin-bottom: 22px;
}

.p-sdk__advantages__box__content-desc p {
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  text-align: center;
  letter-spacing: -0.02em;
  font-family: 'Roboto Condensed';
}

@media (max-width: 1199px) {
  .p-sdk__advantages__box__content {
    bottom: 28px;
    padding: 0 35px;
  }

  .p-sdk__advantages__box__content-title h3 {
    font-size: 20px;
  }

  .p-sdk__advantages__box__content-desc p {
    font-size: 16px;
    line-height: 24px;
  }
}

@media (max-width: 414px) {
  .p-sdk__advantages__box__content {
    bottom: 20px;
    padding: 0 30px;
  }

  .p-sdk__advantages__box__content-title h3 {
    margin-bottom: 5px;
  }
}

@media (max-width: 320px) {
  .p-sdk__advantages__box__content {
    bottom: 10px;
    padding: 0 20px;
  }

  .p-sdk__advantages__box__content-title h3 {
    font-size: 18px;
  }

  .p-sdk__eyefire__content__box-desc h4,
  .p-sdk__advantages__box__content-desc p {
    font-size: 14px;
  }

  .p-sdk__eyefire__content__box-image img {
    width: 65px;
  }
}

.p-sdk .slick-arrow {
  display: none !important;
}

.p-sdk .slick-list {
  overflow: visible;
  margin-left: 30px;
  margin-right: 30px;
}

.p-sdk .slick-slide {
  -webkit-transform: scale(0.93);
  transform: scale(0.93);
  padding: 0 4.5px;
}

.p-sdk .slick-slide.slick-current {
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.4s all;
  transition: 0.4s all;
}

.p-sdk .slick-dots {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 9px;
  gap: 5px;
}

.p-sdk .slick-dots li.slick-active button {
  width: 39px;
  background: #fff;
}

.p-sdk .slick-dots li button {
  font-size: 0;
  width: 14px;
  height: 8px;
  background: #9c9c9c;
  border-radius: 8px;
  border: 0;
}

.p-solution__main {
  margin-top: -250px;
  position: relative;
}

@media (min-width: 1500px) {
  .p-solution__main {
    margin-top: -425px;
  }
}

@media (max-width: 1279px) {
  .p-solution__main {
    margin-top: -100px;
  }
}

@media (max-width: 1199px) {
  .p-solution__main {
    margin-top: -90px;
    padding-bottom: 80px;
  }
}

@media (max-width: 767px) {
  .p-solution__main {
    margin-top: -148px;
    padding-bottom: 0;
  }
}

.p-solution__box {
  margin-bottom: 130px;
}

@media (min-width: 1200px) {
  .p-solution__box:nth-child(even) {
    position: relative;
    right: 50px;
  }

  .p-solution__box:last-child {
    position: relative;
    right: 80px;
    top: 60px;
    margin-bottom: 361px;
  }

  .p-solution__box:last-child .p-solution__box__row .l-col {
    left: calc(4% + 6.86px);
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .p-solution__box:nth-child(even) {
    position: relative;
    right: 20px;
  }
}

@media (max-width: 767px) {
  .p-solution__box {
    margin-bottom: 20px;
  }
}

.p-solution__box__row {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: baseline;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.p-solution__box__row .l-col {
  -webkit-box-flex: calc(100% - 695px);
  -ms-flex: calc(100% - 695px);
  flex: calc(100% - 695px);
  max-width: calc(100% - 695px);
}

.p-solution__box__row .l-col-auto {
  -webkit-box-flex: 695px;
  -ms-flex: 695px;
  flex: 695px;
  max-width: 695px;
  width: 100%;
}

@media (max-width: 1199px) {

  .p-solution__box__row .l-col,
  .p-solution__box__row .l-col-auto {
    -webkit-box-flex: 50%;
    -ms-flex: 50%;
    flex: 50%;
    max-width: 50%;
  }
}

@media (min-width: 1200px) {
  .p-solution__box__row .l-col {
    top: 60px;
  }
}

@media (max-width: 767px) {
  .p-solution__box__row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    -webkit-box-align: unset;
    -ms-flex-align: unset;
    align-items: unset;
  }

  .p-solution__box__row .l-col {
    margin-top: 25px;
  }

  .p-solution__box__row .l-col,
  .p-solution__box__row .l-col-auto {
    -webkit-box-flex: 100%;
    -ms-flex: 100%;
    flex: 100%;
    max-width: 100%;
  }
}

.p-solution__box__content {
  margin-top: -15px;
}

@media (max-width: 767px) {
  .p-solution__box__content {
    margin-top: 10px;
    margin-bottom: 50px;
  }
}

.p-solution__box__content-icon {
  margin-bottom: 24px;
}

@media (max-width: 767px) {
  .p-solution__box__content-icon {
    margin-bottom: 16px;
  }

  .p-solution__box__content-icon img {
    width: 48px;
  }
}

.p-solution__box__content-title {
  margin-bottom: 8px;
}

.p-solution__box__content-title h3 {
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
}

.p-solution__box__content-title h4 {
  display: flex;
  align-items: center;
  font-family: 'Lobster';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 42px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
}

.p-solution__box__content-title h4 img {
  margin-left: 8.6px;
  margin-right: 6px;
}

.p-solution__box__content-title h4 p {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 42px;
  color: #ffffff;
}

.p-solution__box__content .img-prize {
  max-width: 229.3px;
  width: 229px;
  max-height: 58.66px;
  margin-top: 9.62px;
  margin-bottom: 10px;
}

.p-solution__box__content-desc {
  margin-bottom: 24px;
}

.p-solution__box__content-desc p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  max-width: 311px;
  width: 100%;
}

@media (max-width: 767px) {
  .p-solution__box__content .img-prize {
    max-width: 162.3px;
    width: 162px;
    max-height: 41.7px;
  }

  .p-solution__box__content-desc p {
    font-size: 16px;
    line-height: 24px;
  }
}

.p-solution__box__content-desc ul {
  margin-top: 18px;
}

.p-solution__box__content-desc ul li {
  font-weight: 400;
  font-size: 16px;
  padding-bottom: 8px;
  line-height: 24px;
  padding-left: 29px;
  position: relative;
}

.p-solution__box__content-desc ul li::after {
  content: '';
  position: absolute;
  background: #fff;
  left: 13px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 3px;
  height: 3px;
  border-radius: 50%;
}

@media (max-width: 767px) {
  .p-solution__box__content-desc {
    margin-bottom: 16px;
  }
}

.p-solution__box__content-handle a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 13px;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  z-index: 2;
  position: relative;
  -webkit-transition: 0.3s all;
  transition: 0.3s all;
}

.p-solution__box__content-handle a::after {
  content: '';
  position: absolute;
  width: 121px;
  height: 48px;
  background: #34a3fd;
  border-radius: 4px;
  z-index: -1;
  left: -9px;
  opacity: 0;
}

.p-solution__box__content-handle a:hover {
  opacity: 1;
}

.p-solution__box__content-handle a:hover::after {
  opacity: 1;
}

.p-solution__box:nth-child(even) .p-solution__box__row {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.p-solution__box:nth-child(even) .p-solution__box__content {
  margin-left: 10px;
}

@media (max-width: 767px) {
  .p-solution__box:nth-child(even) .p-solution__box__row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }

  .p-solution__box:nth-child(even) .p-solution__box__content {
    margin-left: 0;
  }

  .p-solution__box:nth-child(3) .p-solution__box__image img {
    position: relative;
    /* right: 18px; */
  }

  .p-solution__box:nth-child(4) .p-solution__box__image img {
    position: relative;
    /* right: 5px; */
  }

  .p-solution__box:nth-child(5) .p-solution__box__image img {
    position: relative;
    /* right: 10px; */
  }

  .p-solution__box:nth-child(6) .p-solution__box__image img {
    position: relative;
    /* right: 30px; */
  }

  /* .p-solution__box__image {
    background-image: url('/assets/img/home/img_solution_bg.png');
    background-position: bottom 10px right;
    background-repeat: no-repeat;
  } */
}

.p-products .c-big-banner {
  margin-bottom: -250px;
}

@media (max-width: 1199px) {
  .p-products .c-big-banner {
    margin-bottom: 0;
  }
}

@media (max-width: 767px) {
  .p-products .c-big-banner {
    margin-bottom: -190px;
  }
}

.p-products .c-big-banner__content {
  top: calc(50% + 6px);
}

@media (max-width: 767px) {
  .p-products .c-big-banner__content p {
    width: 217px;
    margin-left: auto;
    margin-right: auto;
  }
}

.p-products__list {
  margin-bottom: 152px;
  margin-top: 60px;
}

@media (min-width: 1200px) and (max-width: 1499px) {
  .p-products__list {
    margin-top: 100px;
  }
}

@media (max-width: 767px) {
  .p-products__list {
    margin-bottom: 50px;
    margin-top: 20px;
  }

  .p-products__block-01 {
    margin-bottom: 0px;
  }
}

.p-products__block {
  position: relative;
}

.p-products__block .l-container {
  position: relative;
  z-index: 1;
}

.p-products__block-03::before {
  top: -46.646873vw !important;
}

@media (max-width: 767px) {
  .p-products__block-03 .p-products__block-left .p-products__block-text img {
    width: 152px;
  }
}

.p-products__block-03 .p-home__products-features li:nth-child(2)::before {
  height: calc(100% - 48%);
}

.p-products__block-03 .p-home__products-features li:nth-child(3)::before {
  height: calc(100% - 58%);
}

.p-products__block-03 .p-products__block-item img {
  width: 460px;
  height: 300px;
}

@media (max-width: 1199px) {
  .p-products__block-03 .p-products__block-item img {
    width: auto;
    height: auto;
    max-width: 80%;
  }
}

@media (max-width: 767px) {
  .p-products__block-03 .p-products__block-item img {
    max-width: 100%;
  }
}

.p-products__block-03 .slick-dots li.slick-active button img {
  width: 46px !important;
  height: 30px !important;
}

.p-products__block-03 .slick-dots li button img {
  width: 40px !important;
  height: 25px !important;
}

.p-products__block-03 .p-products__block-slider {
  margin-top: -20px;
  left: -41px;
}

@media (max-width: 1199px) {
  .p-products__block-03 .p-products__block-slider {
    left: 0;
  }
}

.p-products__block-03 .p-products__block .slick-dots {
  margin-top: 48px;
}

.p-products__block:nth-child(odd)::before {
  content: '';
  width: 50.0262743037vw;
  height: 84.3930635838vw;
  position: absolute;
  left: 0;
  top: -23.6468733579vw;
  background: url('/assets/img/products/img_line_left.webp') no-repeat center;
  background-size: cover;
  z-index: 0;
}

.p-products__block:nth-child(even) {
  margin-bottom: 170px;
}

.p-products__block-01 {
  margin-bottom: 120px;
}

@media (max-width: 767px) {
  .p-products__block:nth-child(even) {
    margin-bottom: 1px;
  }

  .p-products__block-01 {
    margin-bottom: 0px;
  }
}

.p-products__block:nth-child(even)::before {
  content: '';
  width: 952px;
  width: 50.0262743037vw;
  height: 1606px;
  height: 84.3930635838vw;
  position: absolute;
  right: 0;
  top: -23.6468733579vw;
  background: url('/assets/img/products/img_line_right.webp') no-repeat center;
  background-size: cover;
  z-index: 0;
}

.p-products__block:nth-child(even) .p-products__block-right .p-home__products-right-inner {
  /* margin-top: 58px; */
}

.p-products__block:nth-child(even) .p-home__products-features li:nth-child(3)::before {
  height: calc(100% - 58%);
}

@media (max-width: 820px) {

  .p-products__block-03 .p-home__products-features li:nth-child(3)::before,
  .p-products__block:nth-child(even) .p-home__products-features li:nth-child(3)::before {
    height: calc(100% - 47%);
  }

  .p-products__block-03 .p-home__products-features li:nth-child(2)::before {
    height: calc(100% - 39%);
  }
}

/* .p-products__block:nth-child(even) .p-products__block-item img {
  width: 367px;
  height: 558px;
} */

@media (max-width: 1199px) {
  .p-products__block:nth-child(even) .p-products__block-item img {
    width: auto;
    height: auto;
    max-width: 80%;
  }
}

@media (max-width: 767px) {
  .p-products__block:nth-child(even) .p-products__block-item img {
    max-width: 100%;
  }
}

.p-products__block:nth-child(even) .p-products__block-slider {
  left: 89px;
  margin-left: 22%;
}

@media (max-width: 1199px) {
  .p-products__block:nth-child(even) .p-products__block-slider {
    left: 0;
    /* margin-top: 60px */
  }
}

.p-products__block:nth-child(even) .p-products__block-text {
  margin-top: 44px;
  text-align: right;
}

@media (max-width: 767px) {
  .p-products__block:nth-child(even) .p-products__block-text {
    margin-top: -44px;
    text-align: left;
  }

  .p-products__block:nth-child(even) .p-products__block-text img {
    top: 91px;
  }
}

.p-products__block:nth-child(even) .l-row {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.p-products__block-left {
  -webkit-box-flex: 50%;
  -ms-flex: 50%;
  flex: 50%;
  max-width: 50%;
  bottom: 0px;
  right: 50px;
}

@media (max-width: 767px) {
  .p-products__block-left {
    -webkit-box-flex: 100%;
    -ms-flex: 100%;
    flex: 100%;
    max-width: 100%;
    bottom: 0;
    right: 0;
  }

  .p-products__block-left:nth-child(2) {
    bottom: 53px;
  }
}

@media (max-width: 767px) {
  .p-products__block-left .p-products__block-text img {
    width: 217px;
    position: relative;
    top: 36px;
    left: 16px;
    z-index: -1;
  }
}

.p-products__block-right {
  -webkit-box-flex: 50%;
  -ms-flex: 50%;
  flex: 50%;
  max-width: 50%;
}

@media (max-width: 767px) {
  .p-products__block-right {
    -webkit-box-flex: 100%;
    -ms-flex: 100%;
    flex: 100%;
    max-width: 100%;
  }
}

@media (max-width: 767px) {
  .p-products__block-right .p-home__products-features-info h5 br {
    display: none;
  }
}

.p-products__block-right .p-home__products-right-inner {
  margin-top: 0;
}

.p-products__block-right .p-home__products-features {
  margin-top: 34px;
}

@media (max-width: 767px) {
  .p-products__block-right .p-home__products-features {
    margin-top: 19px;
  }
}

@media (max-width: 767px) {
  .p-products__block-right .p-home__products-item h3 {
    font-size: 32px;
    line-height: 48px;
  }
}

@media (max-width: 767px) {
  .p-products__block-right .p-home__products-item p {
    font-weight: 400;
  }
}

@media (max-width: 767px) {
  .p-products__block-right .p-home__products-features-icon {
    width: 48px;
    min-width: 48px;
    height: 48px;
  }

  .p-products__block-right .p-home__products-features-icon img {
    width: 27.43px;
  }

  .p-home__products-features .p-home__products-features-icon img {
    max-width: 48px;
    max-height: 48px;
  }
}

@media (max-width: 767px) {
  .p-products__block-right .p-home__products-features li {
    margin-bottom: 13px;
  }
}

@media (max-width: 767px) {
  .p-products__block-right .p-home__products-features li::before {
    left: 24px;
    bottom: 0px;
  }
}

@media (max-width: 414px) {
  /* .p-products__block:nth-child(1) .p-products__block-right .p-home__products-features li:nth-child(1):before {
    bottom: -4px;
  } */

  /* .p-products__block:nth-child(1) .p-products__block-right .p-home__products-features li:nth-child(2):before {
    height: 58px;
  } */

  .p-products__block:nth-child(1) .p-products__block-right .p-home__products-features li:nth-child(3):before {
    height: calc(100% - 39%);
  }

  /* .p-products__block:nth-child(2) .p-products__block-right .p-home__products-features li:nth-child(1):before {
    bottom: 8px;
  } */

  /* .p-products__block:nth-child(2) .p-products__block-right .p-home__products-features li:nth-child(2):before {
    bottom: -4px;
  } */

  /* .p-products__block:nth-child(3) .p-products__block-right .p-home__products-features li:nth-child(2):before {
    bottom: -4px;
  } */
}

@media (max-width: 412px) {
  .p-products__block:nth-child(1) .p-products__block-right .p-home__products-features li:nth-child(3):before {
    height: calc(100% - 33%);
  }
}

/* @media (max-width: 390px) {
  .p-products__block:nth-child(3) .p-products__block-right .p-home__products-features li:nth-child(3):before {
    height: 77px;
  }
} */

@media (max-width: 375px) {
  /* .p-products__block:nth-child(1) .p-products__block-right .p-home__products-features li:nth-child(1):before {
    bottom: 7px;
  } */

  .p-products__block:nth-child(3) .p-products__block-right .p-home__products-features li:nth-child(3):before {
    height: calc(100% - 47%);
  }
}

@media (max-width: 360px) {
  .p-products__block-03 .p-home__products-features li:nth-child(2)::before {
    height: calc(100% - 33%);
  }
}

@media (max-width: 320px) {

  .p-products__block-03 .p-home__products-features li:nth-child(2)::before,
  .p-products__block:nth-child(1) .p-products__block-right .p-home__products-features li:nth-child(3):before {
    height: calc(100% - 29%);
  }

  .p-products__block:nth-child(3) .p-products__block-right .p-home__products-features li:nth-child(3):before,
  .p-products__block:nth-child(even) .p-home__products-features li:nth-child(3)::before {
    height: calc(100% - 31%);
  }
}

@media (max-width: 767px) {
  .p-products__block-item {
    padding: 5px;
  }
}

.slick-slide .p-products__block-item {
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
  opacity: 1;
}

.slick-center .p-products__block-item {
  -webkit-transform: scale(1);
  transform: scale(1);
  transition: all 0.5s ease;
}

.p-products__block-item img {
  margin-left: auto;
  margin-right: auto;
  width: 269px;
  height: 499px;
  /* -o-object-fit: cover;
  object-fit: cover; */
}

.p-products__block-item.slick-current::before {
  content: '';
  position: absolute;
  right: 100%;
  top: 0;
  width: 86px;
  height: 100%;
  background: -webkit-gradient(linear,
      left top,
      right top,
      color-stop(-41.28%, #001530),
      color-stop(117.44%, rgba(0, 21, 48, 0)));
  background: linear-gradient(90deg,
      #001530 -41.28%,
      rgba(0, 21, 48, 0) 117.44%);
}

.p-products__block-item.slick-current::after {
  content: '';
  position: absolute;
  left: 100%;
  top: 0;
  width: 86px;
  height: 100%;
  background: -webkit-gradient(linear,
      left top,
      right top,
      color-stop(-41.28%, #001530),
      color-stop(117.44%, rgba(0, 21, 48, 0)));
  background: linear-gradient(90deg,
      #001530 -41.28%,
      rgba(0, 21, 48, 0) 117.44%);
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.p-products__block-item.slick-center {
  -webkit-transform: scale(1.08);
  transform: scale(1.08);
}

.p-products__block-item img {
  margin-left: auto;
  margin-right: auto;
  width: 269px;
  height: 499px;
  /* -o-object-fit: cover;
  object-fit: cover; */
}

@media (max-width: 1199px) {
  .p-products__block-item img {
    width: auto;
    height: auto;
    max-width: 80%;
  }
}

@media (max-width: 767px) {

  .p-products__block-item img,
  .p-products__block:nth-child(even) .p-products__block-item img {
    width: 100%;
    max-width: 100%;
    transition: all 0.5s ease;
  }

  /* .p-products__block-03 .p-products__block-item img {
    width: 72%;
  } */

  .p-products__block:nth-child(even) .p-products__block-slider {
    margin-left: -24px;
  }
}

.p-products__block-text {
  margin-top: 35px;
  margin-left: 85px;
}

@media (max-width: 767px) {
  .p-products__block-text {
    margin-top: 13px;
    margin-left: 0px;
  }
}

.p-products__block-text img {
  max-width: 100%;
}

.p-products__block-slider {
  margin-top: 33px;
  left: -123px;
}

@media (max-width: 1199px) {
  .p-products__block-slider {
    left: 0;
    margin-left: 25px;
  }
}

@media (max-width: 767px) {
  .p-products__block-slider {
    width: calc(100% + 48px);
    margin-left: -24px;
  }

  .p-products__block-slider .slick-list {
    padding-top: 80px !important;
    /* padding-bottom: 25px !important; */
  }

  .p-products__block-03 {
    margin-top: 40px;
  }
}

.p-products__block .slider-thumb {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 9px;
}

.p-products__block .slick-dots {
  display: -webkit-box;
  display: -ms-flexbox;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  /* margin-top: 9px; */
  display: flex !important;
  flex-wrap: nowrap;
  flex-direction: column;
}

@media (max-width: 767px) {
  .p-products__block .slick-dots {
    margin-top: 10px;
    display: block;
  }
}

.p-products__block .slick-dots+.slick-dots {
  margin-top: 15px;
}

@media (max-width: 767px) {
  .p-products__block .slick-dots+.slick-dots {
    display: none !important;
  }
}

.p-products__block .slick-dots+.slick-dots li {
  padding: 0;
  margin: 0 3px;
}

.p-products__block .slick-dots+.slick-dots li.slick-active button {
  width: 39px;
  height: 8px;
  background-color: #fff;
  border: none;
}

.p-products__block .slick-dots+.slick-dots li button {
  width: 14px;
  height: 8px;
  background: #9c9c9c;
  border-radius: 8px;
  border: none;
  font-size: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.p-products__block .slick-dots+.slick-dots li button img {
  display: none;
}

.p-products__block .slick-dots li {
  padding: 0 11.5px;
}

.p-products__block .slick-dots li.slick-active button {
  width: 76px;
  height: 76px;
  background: #062853;
  border: 1.21699px solid #1f7aff;
}

.p-products__block .slick-dots li.slick-active button img {
  width: 32.54px;
  height: 63.22px;
}

.p-products__block .slick-dots li button {
  background-color: transparent;
  width: 62.45px;
  height: 62.45px;
  background: #062853;
  border: 1px solid #ffffff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: none;
  transition: none;
}

.p-products__block .slick-dots li button img {
  width: 26.74px;
  height: 51.95px;
}

@media (max-width: 767px) {
  .p-products__block .slick-dots li {
    padding: 0;
    margin: 0 3px;
  }

  .p-products__block .slick-dots li.slick-active button {
    width: 39px;
    height: 8px;
    background-color: #fff;
    border: none;
  }

  .p-products__block .slick-dots li button {
    width: 14px;
    height: 8px;
    background: #9c9c9c;
    border-radius: 8px;
    border: none;
    font-size: 0;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .p-products__block .slick-dots li button img {
    display: none;
  }
}

@media (max-width: 767px) {
  .p-news .l-col-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .p-news .l-col-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (max-width: 767px) {
  .p-news .c-big-banner__image img {
    height: 500px;
    -o-object-fit: cover;
    object-fit: cover;
  }

  /* .p-news .c-big-banner__content {
    top: 50%;
  } */
}

.p-news__featured {
  padding-top: 165px;
  padding-bottom: calc(100 / 1920 * 100vw);
  position: relative;
  background: url('/assets/img/news/bg_news_featured.webp') center no-repeat;
  background-position-y: -96px;
  background-size: cover;
}

@media (max-width: 767px) {
  .p-news__featured {
    /* margin-top: -50px; */
  }
}

.p-news__featured .c-title {
  margin-bottom: 40px;
}

.p-news__featured .c-title h3 {
  font-weight: 700;
  font-size: 48px;
  line-height: 56px;
  padding-bottom: 17px;
  font-family: 'Roboto Condensed';
}

@media (max-width: 1199px) {
  .p-news__featured .c-title h3 {
    font-size: 38px;
    line-height: 46px;
  }
}

@media (max-width: 767px) {
  .p-news__featured .c-title h3 {
    font-size: 28px;
    line-height: 36px;
  }
}

.p-news__featured .c-title p {
  font-weight: 400;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 32px;
  width: 30%;
}

@media (max-width: 767px) {
  .p-news__featured .l-col {
    margin-bottom: 20px;
  }

  .news-item {
    margin-bottom: 15px;
  }

  .p-news__featured .c-title p {
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  .p-news__featured .c-title p {
    width: 47%;
  }
}

.p-news__featured .l-container .l-col.l-col-8 .p-news__featured-item::after {
  content: '';
  position: absolute;
  background: linear-gradient(0deg, #000000 -10.33%, rgba(0, 0, 0, 0) 100%);
  border-radius: 0 0 4px 4px;
  height: 100%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: calc(100% + 1px);
}

.p-news__featured .l-container .l-col.l-col-4 .p-news__featured-item::after {
  content: '';
  position: absolute;
  background: linear-gradient(0deg, #000000 -10.33%, rgba(0, 0, 0, 0) 100%);
  border-radius: 0 0 4px 4px;
  bottom: 0;
  height: 100%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: calc(100% + 2px);
}

.p-news__featured-item:hover {
  opacity: 1;
}

.p-news__featured-item {
  display: block;
  border-radius: 6px;
  overflow: hidden;
  position: relative;
}

.p-news__featured-item::after {
  background: linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.85));
}

@media (max-width: 767px) {
  .p-news__featured-item img {
    /* height: 55vw; */
    border-radius: 5px;
    aspect-ratio: 16/9;
    object-fit: cover;
  }
}

.p-news__lastest-list img {
  aspect-ratio: 16/9;
  object-fit: fill;
}

.p-news__featured-item+.p-news__featured-item {
  margin-top: 16px;
}

.p-news__featured-item h3 {
  position: absolute;
  bottom: 26px;
  width: 100%;
  padding: 0px 65px 0 62px;
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 48px;
  letter-spacing: -0.55px;
  z-index: 10;
}

@media (max-width: 1199px) {
  .p-news__featured-item h3 {
    font-size: 26px;
    line-height: 38px;
    padding: 0 20px;
  }
}

@media (max-width: 767px) {
  .p-news__featured-item h3 {
    font-size: 16px;
    line-height: 20px;
  }
}

.p-news__featured-item h4 {
  position: absolute;
  bottom: 18px;
  width: 100%;
  padding: 0 27px;
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.3px;
  z-index: 10;
}

@media (max-width: 767px) {
  .p-news__featured-item h4 {
    font-size: 16px;
    line-height: 20px;
    padding: 0 20px;
  }
}

.p-news__lastest {
  margin-bottom: 15.0814503416vw;
  margin-top: calc(-33 / 1920 * 100vw);
}

.p-news__lastest .l-col-8 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 58.333333%;
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

@media (max-width: 767px) {
  .p-news__lastest .l-col-8 {
    -webkit-box-flex: 100%;
    -ms-flex: 100%;
    flex: 100%;
    max-width: 100%;
  }
}

.p-news__lastest .l-col-4 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.p-news__lastest-top {
  margin-top: 115px;
  margin-bottom: 43px;
}

.p-news__lastest-top .l-col-8 .title-left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.p-news__lastest-top .l-col-8 .title-left .handleBtnClose {
  position: relative;
  margin-left: 50px;
  margin-top: 12px;
  cursor: pointer;
}

@media (max-width: 820px) {
  .p-news__lastest-top .l-col-8 .title-left .handleBtnClose {
    margin-left: 22px;
    margin-top: 0px;
  }

  .p-news__lastest-top .l-col-8 .title-left .handleBtnClose::before {
    left: -10px !important;
  }
}

.p-news__lastest-top .l-col-8 .title-left .handleBtnClose a {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #0063aa;
  background: #e2e2e2;
  border-radius: 55px;
  padding: 4px 24px;
  position: relative;
  pointer-events: none;
}

.p-news__lastest-top .l-col-8 .title-left .handleBtnClose a:hover {
  background: #0063aa;
  color: #fff;
  transition: 0.4s all;
}

.p-news__lastest-top .l-col-8 .title-left .handleBtnClose a::before {
  content: url('/assets/images/news/bi_x-circle-fill.svg');
  position: absolute;
  top: -4px;
  right: -11px;
  pointer-events: initial;
}

.p-news__lastest-top .l-col-8 .title-left .handleBtnClose::before {
  content: '';
  position: absolute;
  height: 25px;
  width: 1px;
  left: -25px;
  background: #a7a7a7;
  transform: translateY(-50%);
  top: 50%;
}

.p-news__lastest-top .l-col-4 .search {
  position: relative;
  text-align: right;
  display: none;
}

.p-news__lastest-top .l-col-4 .search input {
  background: #ffffff;
  border: 1px solid #e2e2e2;
  border-radius: 29px;
  width: 445px;
  height: 56px;
  padding: 0 23px;
  padding-right: 40px !important;
  max-width: 100%;
  font-size: 16px;
  /* color: #868686; */
}

.p-news__lastest-top .l-col-4 .search input:focus {
  outline: none;
}

.p-news__lastest-top .l-col-4 .search input::-webkit-input-placeholder {
  color: inherit;
}

.p-news__lastest-top .l-col-4 .search input::-moz-placeholder {
  color: inherit;
}

.p-news__lastest-top .l-col-4 .search input:-ms-input-placeholder {
  color: inherit;
}

.p-news__lastest-top .l-col-4 .search input::-ms-input-placeholder {
  color: inherit;
}

.p-news__lastest-top .l-col-4 .search input::placeholder {
  color: inherit;
}

.p-news__lastest-top .l-col-4 .search button {
  position: absolute;
  right: 0;
  top: 0;
  background: transparent;
  border: none;
  width: 49px;
  height: 56px;
  z-index: 1;
}

.p-news__lastest-top .l-col-4 .search button:hover path {
  stroke: #0063aa;
}

.p-news__lastest-list #not-found .inner {
  padding: 0;
}

.p-news__lastest-list #not-found .image img {
  /* max-width: 200px; */
  object-fit: cover;
  /* width: 100% !important; */
}

#not-found .main-content .inner {
  text-align: center;
  padding: 131px 0;
}

#not-found .main-content .inner .image {
  margin-bottom: 32px;
}

#not-found .main-content .inner h3 {
  font-size: 24px;
  font-weight: 400;
  color: #0063aa;
  margin-bottom: 60px;
}

#not-found .main-content .inner a {
  border-radius: 5px;
  color: #fff;
  background-color: #0063aa;
  padding: 16px 23px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 242px;
  max-width: 100%;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 24px;
  margin-left: auto;
  margin-right: auto;
}

#not-found .main-content .inner a:hover {
  background-color: #0099ff;
}

@media (max-width: 767px) {
  .p-news__lastest .l-col-4 {
    -webkit-box-flex: 100%;
    -ms-flex: 100%;
    flex: 100%;
    max-width: 100%;
  }

  .p-news__lastest-top {
    margin-top: 35px;
  }

  #not-found .main-content .inner .image img {
    /* width: 200px; */
  }

  #not-found .main-content .inner h3 {
    font-size: 18px;
    margin-bottom: 40px;
  }

  #not-found .main-content .inner a {
    font-size: 12px;
    padding: 7.5px 17px;
  }

  #not-found .main-content .inner {
    padding: 70px 0;
  }
}

.p-news__lastest-top h3 {
  font-weight: 700;
  font-size: 48px;
  line-height: 56px;
}

@media (max-width: 1199px) {
  .p-news__lastest-top h3 {
    font-size: 34px;
    line-height: 44px;
  }
}

@media (max-width: 767px) {
  .p-news__lastest-top h3 {
    font-size: 24px;
    line-height: 34px;
  }
}

.p-news__lastest-top .l-col-8 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.p-news__lastest-top .l-row .l-col-8 {
  justify-content: flex-start;
}

@media (max-width: 767px) {
  .p-news__lastest-top .l-col-8 {
    display: block;
  }
}

/* .p-news__lastest-top .l-col-8::before {
  content: "";
  width: 1px;
  height: 24px;
  background-color: #a7a7a7;
  position: absolute;
  left: -2px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
} */

@media (max-width: 767px) {
  .p-news__lastest-top .l-col-8::before {
    opacity: 0;
  }
}

.p-news__lastest-catalog {
  position: relative;
  background: #e2e2e2;
  border-radius: 55px;
  padding: 0px 24px;
}

@media (max-width: 767px) {
  .p-news__lastest-catalog {
    display: inline-block;
    margin-top: 15px;
    margin-bottom: 25px;
  }
}

.p-news__lastest-catalog span {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #0063aa;
}

.p-news__lastest-catalog img {
  position: absolute;
  top: -8px;
  right: -9px;
  cursor: pointer;
}

.p-news__lastest-search {
  position: relative;
  margin-left: 60px;
  width: 445px;
}

@media (max-width: 1199px) {
  .p-news__lastest-search {
    width: calc(100% - 200px);
  }
}

@media (max-width: 767px) {
  .p-news__lastest-search {
    width: 100%;
    margin-left: 0;
  }
}

.p-news__lastest-search input {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #a7a7a7;
  background-color: #fff;
  border: 1px solid #e2e2e2;
  border-radius: 29px;
  padding: 15px 20px;
  width: 100%;
}

.p-news__lastest-search input::-webkit-input-placeholder {
  color: inherit;
}

.p-news__lastest-search input::-moz-placeholder {
  color: inherit;
}

.p-news__lastest-search input:-ms-input-placeholder {
  color: inherit;
}

.p-news__lastest-search input::-ms-input-placeholder {
  color: inherit;
}

.p-news__lastest-search input::placeholder {
  color: inherit;
}

.p-news__lastest-search button {
  border: none;
  background-color: transparent;
  padding: 0;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 15px;
}

.p-news__lastest-item+.p-news__lastest-item {
  margin-top: 40px;
}

.p-news__lastest-item-catalog {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 3px;
  margin-top: 3px;
}

@media (max-width: 767px) {
  .p-news__lastest-item-catalog {
    margin-top: 20px;
  }
}

.p-news__lastest-item-time {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #a7a7a7;
  margin-bottom: 16px;
}

.p-news__lastest-item h3 {
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  margin-bottom: 4px;
}

.p-news__lastest-item p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* width: 494px; */
  max-width: 100%;
  margin-bottom: 17px;
  font-family: 'Roboto Condensed';
}

@media (max-width: 767px) {
  .p-news__lastest-item p {
    margin-bottom: 15px;
  }
}

.p-news__lastest-item a {
  display: block;
  border-radius: 5px;
  overflow: hidden;
  font-weight: 700;
  line-height: 21px;
  font-size: 18px;
}

.p-news__lastest-tag {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 10px;
}

.p-news__lastest-tag a {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #434343;
  cursor: pointer;
  padding: 4px 25px;
  background: #e2e2e2;
  border-radius: 55px;
  font-family: 'Roboto Condensed';
}

.p-news__lastest__btn-viewmore {
  text-align: center;
  margin-top: 78px;
}

.p-news__lastest__btn-viewmore a {
  width: 168px;
  padding: 15px 42px 10px;
  cursor: pointer;
  text-decoration: 5px;
}

#page-news-details {
  background-color: #fff;
}

#page-news-details .l-footer {
  background-color: #fff;
}

.p-news-details {
  padding: 120px 0 165px;
  background-color: #fff;
}

@media (max-width: 767px) {
  .p-news-details {
    padding-bottom: 65px;
  }
}

.p-news-details * {
  color: rgba(0, 0, 0, 0.8);
}

.p-news-details__sidebar {
  margin-top: 100px;
}

/* @media (max-width: 1199px) {
  .p-news-details__sidebar {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 40%;
    flex: 0 0 40%;
    max-width: 40%;
  }
} */

@media (max-width: 767px) {
  .p-news-details__sidebar {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.p-news-details__sidebar .p-news-details__title {
  margin-bottom: 14px;
}

.p-news-details__related-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-top: 0.5px solid rgba(0, 0, 0, 0.56);
  padding-top: 16px;
}

.p-news-details__related-info {
  -webkit-box-flex: calc(100% - 108px);
  -ms-flex: calc(100% - 108px);
  flex: calc(100% - 108px);
  max-width: calc(100% - 108px);
  padding-right: 20px;
}

@media (min-width: 768px) and (max-width: 1199px) {
  .p-news-details__related-group {
    width: 130%;
  }
}

.p-news-details__related-info h5 {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.02em;
  margin-bottom: 18px;
  color: rgba(0, 0, 0, 0.8);
  font-style: normal;
}

.p-news-details__related-cate {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #707070;
  margin-bottom: -1px;
}

.p-news-details__related-time {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: rgba(0, 0, 0, 0.56);
  margin-bottom: 20px;
  font-style: normal;
}

.p-news-details__related-time span {
  position: relative;
  color: rgba(0, 0, 0, 0.65);
}

.p-news-details__related-time img {
  margin: 0px 5px;
}

.p-news-details__related-img {
  -webkit-box-flex: 108px;
  -ms-flex: 108px;
  flex: 108px;
  max-width: 108px;
}

.p-news-details__related-img img {
  border-radius: 5px;
}

/* @media (max-width: 1199px) {
  .p-news-details__main {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 60%;
    flex: 0 0 60%;
    max-width: 60%;
  }
} */

@media (max-width: 767px) {
  .p-news-details__main {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.p-news-details__main h4 {
  font-weight: 700;
  font-size: 20px;
  line-height: 38px;
  letter-spacing: -0.02em;
  margin-bottom: 8px;
}

@media (max-width: 767px) {
  .p-news-details__main h4 {
    font-size: 16px;
    line-height: 26px;
  }
}

.p-news-details__main h5 {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  margin-bottom: 16px;
}

.p-news-details__main p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  margin-bottom: 16px;
}

.p-news-details__btn-back {
  margin-bottom: 67px;
  cursor: pointer;
}

@media (max-width: 767px) {
  .p-news-details__btn-back {
    margin-bottom: 50px;
    cursor: pointer;
  }
}

.p-news-details__btn-back a {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.02em;
}

@media (max-width: 767px) {
  .p-news-details__btn-back a {
    font-size: 20px;
  }
}

.p-news-details__btn-back a img {
  margin-right: 30px;
}

.p-news-details__title {
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
  letter-spacing: -0.02em;
  color: #0b3b79;
  position: relative;
  padding-right: 10px;
  margin-bottom: 24px;
}

@media (max-width: 767px) {
  .p-news-details__title {
    font-size: 22px;
    line-height: 32px;
  }
}

.p-news-details__title::before {
  content: '';
  position: absolute;
  left: 0;
  top: -26px;
  width: 66px;
  height: 10px;
  background: #34a3fd;
  border-radius: 16px;
}

.p-news-details__category {
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  letter-spacing: -0.02em;
  margin-bottom: 12px;
  font-style: normal;
  color: rgba(0, 0, 0, 0.8);
}

@media (max-width: 767px) {
  .p-news-details__category {
    font-size: 14px;
  }
}

.p-news-details__time {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: rgba(0, 0, 0, 0.56);
  margin-bottom: 16px;
}

.p-news-details .post img {
  width: 100%;
}

@media (max-width: 767px) {
  .p-news-details__time {
    font-size: 12px;
  }
}

.p-news-details__time img {
  margin-right: 10px;
}

.p-news-details__img-cover {
  margin-top: 43px;
  margin-bottom: 24px;
}

.p-news-details__img-cover p {
  font-size: 16px;
  line-height: 38px;
  letter-spacing: -0.02em;
  text-align: center;
  margin-top: 14px;
}

@media (max-width: 767px) {
  .p-news-details__img-cover p {
    line-height: 26px;
  }
}

.p-news-details__author {
  margin-top: 24px;
}

.p-not-data {
  height: calc(100vh - 115px);
  position: relative;
}

.p-not-data__alert {
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

@media (max-width: 767px) {
  .p-not-data__alert__image img {
    width: 200px;
  }
}

.p-not-data__alert__desc {
  margin-top: 10px;
}

.p-not-data__alert__desc p {
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
}

@media (max-width: 767px) {
  .p-not-data__alert__desc p {
    font-size: 16px;
  }
}

.p-not-data__alert__handle {
  margin-top: 42px;
}

.p-not-data__alert__handle .c-btn {
  font-family: 'Roboto Condensed' sans-serif;
  width: 177px;
  padding: 12px 20px;
}

.p-not-found {
  height: calc(100vh - 115px);
  position: relative;
  padding-top: 10%;
}

@media (max-width: 820px) {
  .p-not-found {
    padding-top: 35%;
  }
}

@media (max-width: 414px) {
  .p-not-found {
    padding-top: 50%;
  }
}

@media (max-width: 390px) {
  .p-not-found {
    padding-top: 45%;
  }
}

.p-not-found__alert {
  text-align: center;
  margin-top: 5%;
  /* position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); */
}

@media (max-width: 767px) {
  .p-not-found__alert__image img {
    width: 200px;
  }
}

.p-not-found__alert__desc {
  margin-top: 10px;
}

.p-not-found__alert__desc p {
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  margin-top: 32px;
}

@media (max-width: 767px) {
  .p-not-found__alert__desc p {
    font-size: 16px;
  }
}

.p-not-found__alert__handle {
  margin-top: 42px;
}

.p-not-found__alert__handle .c-btn {
  font-family: 'Roboto Condensed' sans-serif;
  width: 177px;
  padding: 12px 20px;
}

.p-loading {
  text-align: center;
}

.p-loading svg {
  margin-bottom: 32px;
}

.p-loading p {
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 15px;
  margin-top: 21px;
}

.p-loading__inner {
  padding: 228px 0 300px;
}

.p-loading__progress {
  width: 464px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  height: 42px;
  padding: 3px 5px;
  border: 1px solid rgba(255, 255, 255, 0.68);
  border-radius: 8px;
}

.p-loading__progress span {
  background: #fff;
  border-radius: 4px 0px 0px 4px;
  width: 88.6%;
  height: 34px;
  display: block;
}

@media (max-width: 767px) {
  .p-loading__inner {
    padding: 100px 0 200px;
  }
}

.p-contact {
  background: url('/assets/img/contact/bg-contact.webp') no-repeat center;
  background-size: cover;
}

.p-contact__main {
  padding-top: 196px;
  padding-bottom: 109px;
}

@media (max-width: 767px) {
  .p-contact__main {
    padding-bottom: 6px;
    padding-top: 100px;
  }
}

@media (max-width: 1199px) {
  .l-header-mobile {
    /* background-color: #000E33; */
    display: block;
    z-index: 10;
  }
}

.p-contact__main .l-col__info {
  -webkit-box-flex: 55%;
  -ms-flex: 55%;
  flex: 55%;
  max-width: 55%;
}

.p-contact__main .l-col__info .c-title {
  /* width: 448px; */
  max-width: 100%;
}

.p-contact__main .l-col__info .c-title h3 {
  font-size: 48px;
  line-height: 60px;
  position: relative;
  padding-bottom: 7px;
}

.p-contact__main .l-col__info .c-title h3::after {
  content: '';
  position: absolute;
  background: #fff;
  border-radius: 12px;
  width: 48px;
  height: 4px;
  left: 0;
  bottom: 0;
}

.p-contact__main .l-col__info .c-title p {
  margin-top: 21px;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 0;
}

@media (max-width: 1199px) {
  .p-contact__main .l-col__info .c-title h3 {
    font-size: 36px;
    line-height: 50px;
  }
}

@media (max-width: 767px) {
  .p-contact__main .l-col__info .c-title h3 {
    font-size: 30px;
  }

  .p-contact__main .l-col__info .c-title h3::after {
    width: 38px;
  }

  .p-contact__main .l-col__info .c-title p {
    font-size: 14px;
  }
}

.p-contact__main .l-col__form {
  -webkit-box-flex: 45%;
  -ms-flex: 45%;
  flex: 45%;
  max-width: 45%;
  display: flex;
  flex-direction: row-reverse;
}

@media (max-width: 767px) {

  .p-contact__main .l-col__form,
  .p-contact__main .l-col__info {
    -webkit-box-flex: 100%;
    -ms-flex: 100%;
    flex: 100%;
    max-width: 100%;
  }

  .p-contact__main .l-col__form {
    margin-top: 50px;
  }

  .l-col__form form {
    width: 100%;
  }
}

.p-contact__title h3 {
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: -0.02em;
  position: relative;
  padding-bottom: 13px;
}

.p-contact__title h3::before {
  content: '';
  position: absolute;
  border-radius: 5px;
  width: 28px;
  height: 5px;
  background: #4291f6;
  left: 0;
  bottom: 0;
}

@media (max-width: 767px) {
  .p-contact__title h3 {
    font-size: 20px;
  }
}

.p-contact__office {
  margin-top: 30px;
  width: 349px;
  max-width: 100%;
}

.p-contact__office__content {
  margin-top: 38px;
}

.p-contact__office__content>ul>li {
  margin-bottom: 24px;
}

.p-contact__office__content>ul>li h3 {
  font-weight: 700;
  font-size: 16px;
  line-height: 25px;
}

.p-contact__office__content>ul>li p {
  margin-top: 5px;
}

.p-contact__office__content>ul>li p .p-contact__address {
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #f9f9f9;
  opacity: 0.68;
}

@media (max-width: 767px) {
  .p-contact__office__content>ul>li h3 {
    font-size: 14px;
  }

  .p-contact__office__content>ul>li p {
    font-size: 14px;
  }
}

.p-contact__keep {
  margin-top: 32px;
}

.p-contact__keep__content>ul {
  margin-top: 21px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 14px;
}

.p-contact__form {
  margin-bottom: 24px;
}

.vnhwqyhaf>ul {
	display: block;
}

.vnhwqyhaf>ul>li {
	display: flex;
	margin-bottom: 16px;
}

.vnhwqyhaf>ul>li>p {
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	/* identical to box height, or 150% */

	letter-spacing: 0.01em;
	margin-left: 10.89px;
}

@media (max-width: 767px) {
  .p-contact__form {
    text-align: center;
  }
}

.p-contact__form input,
.p-contact__form select,
.p-contact__form textarea {
  font-family: 'Roboto Condensed';
  background: transparent;
  border: 1px solid #ffffff;
  border-radius: 5px;
  width: 460px;
  max-width: 100%;
  height: 44px;
  padding: 0 16px;
  color: #fff;
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  letter-spacing: -0.02em;
}

.p-contact__form select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: #a7a7a7;
  background: url('/assets/img/common/icn_select.svg') no-repeat center;
  background-position-x: calc(100% - 16px);
}

.p-contact__form textarea {
  height: 140px;
}

.p-contact__form .filelabel {
  margin-left: auto;
  width: 460px;
  max-width: 100%;
  border: 1px solid #ffffff;
  border-radius: 5px;
  display: block;
  height: 44px;
  -webkit-transition: border 300ms ease;
  transition: border 300ms ease;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  padding: 0 16px;
}

@media (max-width: 767px) {
  .p-contact__form .filelabel {
    margin-right: auto;
  }
}

.p-contact__form .filelabel .title {
  color: #a7a7a7;
  -webkit-transition: 200ms color;
  transition: 200ms color;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: start;
  gap: 16px;
}

.p-contact__form .filelabel:hover {
  border: 2px solid #1665c4;
}

.p-contact__form .filelabel:hover i,
.p-contact__form .filelabel:hover .title {
  color: #1665c4;
}

.p-contact__form #FileInput {
  display: none;
}

.p-contact__image-bg {
  margin-top: 111px;
  text-align: center;
}

.p-contact__image-bg .c-title {
  padding-bottom: 47px;
}

.p-contact__image-bg .c-title h3 {
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  position: relative;
  padding-bottom: 11px;
}

.p-contact__image-bg .c-title h3::after {
  content: '';
  position: absolute;
  bottom: 0;
  width: 24px;
  height: 5px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 5px;
  background-color: #4291f6;
}

.p-contact__image-bg iframe {
  border-radius: 8px;
}

@media (max-width: 1550px) {
  /* .p-contact__image-bg {
    padding-left: 24px;
    padding-right: 24px;
  } */

  .p-contact__image-bg img {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .p-contact__image-bg {
    margin-top: 63px;
  }
}

.p-contact+footer .l-footer__contact {
  display: none;
}

.c-title h3 {
  font-family: 'UTM Bebas';
  font-weight: 400;
  font-size: 64px;
  line-height: 80px;
  color: #fff;
  position: relative;
  padding-bottom: 22px;
}

@media (max-width: 1199px) {
  .c-title h3 {
    font-size: 44px;
    line-height: 60px;
  }
}

@media (max-width: 767px) {
  .c-title h3 {
    font-size: 24px;
    line-height: 32px;
  }
}

.c-title p {
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -0.02em;
  color: #fff;
  font-family: 'Roboto Condensed';
}

.c-title--border h3::after {
  content: '';
  width: 100%;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.8);
  position: absolute;
  bottom: 23px;
  left: 0;
}

@media (max-width: 767px) {
  .c-title--border h3::after {
    opacity: 0;
  }
}

.c-title--center {
  text-align: center;
}

.c-title--center h3::after {
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.c-btn {
  font-family: 'Overpass', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #fff;
  background: #34a3fd;
  border-radius: 2px;
  padding: 12px 42px 9px;
  white-space: nowrap;
}

@media (max-width: 1199px) {
  .c-btn {
    font-size: 16px;
    padding: 12px 20px;
  }
}

@media (max-width: 767px) {
  .c-btn {
    font-size: 16px;
    line-height: 24px;
    padding: 10px 20px;
  }
}

.p-contact__main .p-contact__form .c-btn {
  border-radius: 5px;
  width: 177px;
  font-size: 16px;
  line-height: 24px;
  font-family: 'Roboto Condensed' sans-serif !important;
  border: none;
}

.c-btn--white {
  font-weight: 600;
  font-size: 16px;
  line-height: 32px;
  color: #1da1f2;
  background-color: #fff;
  padding: 4px 43.5px;
}

.c-btn--white:hover {
  opacity: 1;
  background-color: #34a3fd;
  color: #fff;
}

.c-btn--border {
  border: 1px solid #34a3fd;
}

.c-btn--border:hover {
  opacity: 1;
  background-color: #34a3fd;
  color: #fff;
}

.c-big-banner {
  position: relative;
  margin-top: -115px;
}

@media (max-width: 1199px) {
  .c-big-banner {
    margin-top: 0;
  }
}

.c-big-banner__content {
  position: absolute;
  left: 50%;
  top: calc(50% - 5px);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  width: 920px;
  max-width: 100%;
  padding-left: 36px;
  padding-right: 36px;
}

@media (max-width: 1499px) {
  .p-home__slider__follow {
    left: -25px;
    top: 11%;
  }
}

@media (max-width: 1199px) {
  .c-big-banner__content {
    top: calc(50% - 50px);
  }

  .content-mobiles {
    margin-top: 124px !important;
  }
}

@media (max-width: 767px) {
  .p-news .c-big-banner__content {
    top: 45% !important;
  }

  .c-big-banner__content {
    top: calc(50% - 109px);
  }

  .p-home__slider__follow {
    display: none;
  }
}

.c-big-banner__content h3 {
  font-family: 'UTM Bebas';
  font-style: normal;
  font-weight: 400;
  font-size: 64px;
  line-height: 80px;
  text-transform: uppercase;
  color: #fdfdfd;
}

@media (min-width: 1200px) {
  .product-it-2 img {
    width: 460px;
    height: 300px;
  }
}

@media (max-width: 1199px) {
  .c-big-banner__content h3 {
    font-size: 32px;
    line-height: 48px;
  }
}

.c-big-banner__content p {
  font-family: 'Roboto Condensed';
  margin-top: 36px;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: #ffffff;
}

.product-lh {
  line-height: 24px !important;
}

@media (max-width: 1199px) {
  .c-big-banner__content p {
    font-size: 20px;
    line-height: 32px !important;
    margin-top: 24px;
  }
}

@media (max-width: 575px) {
  .p-solution .c-big-banner__content p {
    width: 303px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}

.p-solution .c-big-banner .c-big-banner__image .item-pc {
  margin-top: 100px;
}

@media (min-width: 768px) {
  .content-mobile {
    display: none;
  }

  /* .p-sdk__option__box {
    flex: 100%;
    max-width: 29%;
    position: relative;
  } */
  /* .p-products__block .slick-dots {
    display: none !important;
  } */
  .p-home__solution {
    background: url('/assets/img/home/bg_solutions.webp') no-repeat center 10px;
  }
}

@media (max-width: 767px) {
  .content-web {
    display: none;
  }

  .p-sdk__option__box {
    flex: calc(33.3333333333% - 36px) 1;
    position: relative;
    max-width: 100%;
  }

  .slick-dots {
    display: flex !important;
  }

  .container-custom {
    margin-top: 5%;
  }
}

@media (max-width: 1199px) {
  .l-header-mobile__canvas__languages__dropdown {
    padding: 11px 0;
    min-width: 84px;
    background: #ffffff;
    box-shadow: 0px 12px 14px rgb(189 189 189 / 25%);
    border-radius: 5px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    /* top: calc(100% + 15px); */
    top: 40px;
    opacity: 1;
    visibility: unset;
    transition: 0.2s;
    pointer-events: initial;
    z-index: 1;
    display: none;
  }

  .showLang {
    display: block !important;
  }
}

.p-technology__box__image-gif {
  position: absolute;
  top: 12%;
  left: 6%;
}

.p-technology__box--right .p-technology__box__image-gif {
  top: 8%;
  left: unset;
  right: 8%;
}

.content-news {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  display: -webkit-box;
  font-size: 16px;
  overflow: hidden !important;
  margin-bottom: 1rem !important;
  text-overflow: ellipsis;
}

/*c-big-banner__content*/
/* .p-news .c-big-banner__content {
  top: 16%;
} */

/* @media (max-width: 1499px) {
  .p-news .c-big-banner__content {
    top: 15%
  }
}

@media (max-width: 1199px) {
  .p-news .c-big-banner__content {
    top: 14%
  }
} */

@media (max-width: 767px) {

  /* .p-news .c-big-banner__content {
    top: 8%
  } */
  .p-technology__box__image-gif {
    top: 16%;
  }
}

.c-btn {
  font-family: 'Roboto Condensed' sans-serif;
}

.uppercase {
  text-transform: uppercase;
}

@media (min-width: 1200px) {
  .l-footer__main .l-row>div {
    /* flex: 0% 1; */
    max-width: 50%;
  }
}

@media (min-width: 900px) {
  .p-home__slider__item {
    height: 100%;
  }
}

.btn_center {
  text-align: right;
}

@media (min-width: 1600px) {
  .l-header-desktop__menu>ul>li.active::before {
    content: '';
    width: 112px;
    height: 110px;
    background: url(/assets/img/common/icn_light.svg) no-repeat center;
    background-size: cover;
    position: absolute;
    bottom: -14px;
    left: 50%;
    transform: translateX(-50%);
  }

  .l-header-desktop__menu>ul>li.active::after {
    content: '';
    width: 72px;
    height: 5px;
    background: #ffffff;
    border-radius: 18px;
    position: absolute;
    bottom: -14px;
    left: 50%;
    transform: translateX(-50%);
  }

  .l-header-desktop__menu>ul>li.active>a::before {
    content: url(/assets/img/common/icn_eyefire_active.svg);
    position: absolute;
    top: -48px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.red-text {
  color: red;
}

@media (max-width: 767px) {
  .textSDK-web {
    display: none;
  }

  .textSDK-mob {
    display: block;
  }

  .hide-mb {
    display: none;
  }
}

.c-btn--font-inter {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  color: #fff;
}

.c-btn-more {
  font-family: 'Roboto Condensed', sans-serif;
  background: #34a3fd;
  border-radius: 2px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  padding: 14.5px 38.8px;
}

.mt-24 {
  margin-top: 24px;
}

.p-products__block .slick-dots li {
  padding: 0;
  margin: 0 3px;
}

/* .p-products__block .slick-dots li.slick-active button {
  width: 39px;
  height: 8px;
  background-color: #fff;
  border: none;
} */

/* .p-products__block .slick-dots li button {
  width: 14px;
  height: 8px;
  background: #9C9C9C;
  border-radius: 8px;
  border: none;
  font-size: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
} */

/* .p-products__block .slick-dots li button img {
  display: none;
} */

.ant-form-item-explain-error {
  color: red;
  padding-top: 5px;
}

.p-home__solution__group-btn {
  position: absolute;
  bottom: 0;
  left: 78px;
}

.p-home__solution__group-btn a {
  font-family: 'Roboto Condensed', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #fdfdfd;
  background: #34a3fd;
  border-radius: 2px;
  padding: 12px 12px;
  white-space: nowrap;
  width: 152.51px;
}

.p-home__products {
  padding: 125px 0 50px;
  background: url(/assets/img/home/bg_products.webp) no-repeat center;
  background-size: cover;
}

@media (max-width: 767px) {
  .p-home__products {
    padding: 80px 0 50px;
    background: url(/assets/img/home/bg_product-sp.jpg) no-repeat center;
    background-size: cover;
    background-position-y: -377px;
  }
}

/* vinh */
.c-button-submit {
  text-align: end;
}

@media (max-width: 767px) {
  .c-button-submit {
    text-align: center;
  }
}

/* end-vinh */
.p-news-details .post figure {
  display: table;
  margin: 1rem auto;
}

.p-news-details .l-container .l-row .l-col .post figure figcaption {
  color: #666;
  font-size: 14px;
  font-style: italic;
  line-height: 22px;
  margin: 8px 0 0;
  padding: 0 30px;
  text-align: center;
}

@keyframes showHeder {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.img-list-new {
  aspect-ratio: 3/2;
}

.imgDots {
  display: flex;
  align-items: center;
}

.barDots {
  display: flex;
  margin-top: 3%;
}

.btnSlide {
  display: inline-block;
  margin: 0 2px;
}

.btnSlide {
  width: 14px;
  height: 8px;
  background: #9c9c9c;
  border-radius: 8px;
  border: none;
  font-size: 0;
  transition: 0.4s;
}

.p-products__block .slick-dots .barDots img {
  display: none;
}

.barDots li.slick-active button {
  width: 39px !important;
  background-color: #fff !important;
}

.barDots li button {
  width: 14px !important;
  height: 8px !important;
  background: #9c9c9c !important;
  border-radius: 8px !important;
  border: none !important;
  font-size: 0 !important;
  transition: 0.4s !important;
}

@media (max-width: 767px) {

  .p-products__block:nth-child(odd)::before,
  .p-products__block:nth-child(even)::before,
  .barDots {
    display: none;
  }
}

.l-col-right .item-pc .p-home__news-item {
  position: relative;
}

.l-col-right .item-pc .l-col {
  flex: 100%;
  max-width: 100%;
  padding-bottom: 15px;
}

.l-col-right .item-pc .l-col:last-child {
  padding-bottom: 0;
}

.l-col-right .item-pc .p-home__news-item .p-home__news-info {
  position: absolute;
  bottom: 15px;
  z-index: 2;
  padding: 0 25px;
}

@media (min-width: 768px) and (max-width: 1080px) {

  /* home-technology */
  .p-home__technology-content h5 {
    font-size: 38px;
    line-height: 40px;
  }

  .p-home__technology-content p {
    margin-bottom: 0;
  }

  /* home-sdk */
  .p-home__facesdk-inner h3 {
    bottom: 20px;
  }

  .p-home__products {
    padding: 0 0 50px;
  }

  /* home-news */
  .l-col-right .item-pc .p-home__news-item .p-home__news-info {
    bottom: 5px;
    padding: 0 15px;
  }

  .p-home__news-info h4 {
    font-size: 14px;
    line-height: 20px;
  }

  .p-news__featured-item h4 {
    bottom: 8px;
    padding: 0 12px;
    font-size: 14px;
    line-height: 20px;
  }

  .p-home__news--date p span {
    font-size: 12px;
  }
}

.p-home__news--date p {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #ffffff;
  /* display: flex;
  gap: 14px; */
}

.p-home__news--date p .p-home__news--date-text {
  position: relative;
  padding-right: 14px;
  display: inline-block;
  cursor: pointer;
}

.p-home__news--date .p-home__news--date-text {
  cursor: pointer;
}

.p-home__news--date .p-home__news--date-text::after {
  content: '';

  position: absolute;
  width: 4px;
  height: 4px;
  background: #fff;
  border-radius: 50%;
  top: calc(50% - 1px);
  right: 3%;
}

.p-home__news .l-col-left.news-pc .p-home__news-item>a img {
  /* height: 494px; */
  object-fit: cover;
  border-radius: 4px;
}

@media (max-width: 1099px) {
  .p-home__news .l-col-left.news-pc .p-home__news-item>a img {
    height: auto;
  }
}

.scroll-link {
  bottom: 4vh;
  display: flex;
  justify-content: center;
  left: 50%;
  position: absolute;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

@media (max-width: 767px) {
  .scroll-link {
    display: none;
  }

  .p-home__news--date p span {
    font-size: 12px;
    line-height: 32px;
  }

  .slick-slider .p-home__news--date p span {
    line-height: 20px;
  }
}

@media (max-width: 900px) {
  .scroll-link {
    bottom: -10px;
  }
}

.p-contact__office__handle-btn {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #fff;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 5px;
}

#contact #footer {
  margin-top: -118px;
}

@media (max-width: 767px) {
  #contact #footer {
    margin-top: -30px;
  }

  .p-home__news-item img {
    height: 221px;
  }

  .slick-slide .p-home__news-item img {
    height: 194px;
    object-fit: fill;
  }
}

.p-home__news-item img {
  border-radius: 4px;
  aspect-ratio: 16/9;
  object-fit: cover;
}

.scroll-link svg:hover {
  opacity: 0.5;
  cursor: pointer;
}

@media (min-width: 768px) {
  .l-col-right .p-home__news-item a::after {
    content: '';
    position: absolute;
    background: linear-gradient(0deg, #000000 -10.33%, rgba(0, 0, 0, 0) 100%);
    border-radius: 0 0 4px 4px;
    bottom: 0;
    height: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
  }
}

@media (max-width: 767px) {
  /* .p-news__featured .l-container .l-col.l-col-8::after {
    height: 100%;
    bottom: 0;
  } */

  .home_slide_new_mobile {
    margin-left: 5px;
  }
}

.mapView {
  display: flex;
}

.mapView a {
  background-color: transparent;
  font-family: 'Roboto Condensed';
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  font-style: normal;
  cursor: pointer;
  color: #ffffff;
}

.mapView img {
  margin-left: 6px;
  cursor: pointer;
}

.mapView a:hover {
  color: #34a3fd;
  padding: 0px 5px;
}

/* update technology */
.p-technology__main__box {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  /* margin-bottom: calc(50/1920*100vw); */
  margin-bottom: 0;
}

@media (min-width: 1200px) {
  .p-technology__main__box:nth-child(3) {
    bottom: 50px;
  }
}

.p-technology__main__box:nth-child(even) {
  flex-direction: row-reverse;
}

.p-technology__main__box:nth-child(even) .p-technology__main__box-img {
  flex: 60%;
  max-width: 60%;
}

.p-technology__main__box:nth-child(even) .p-technology__main__box-content {
  flex: 40%;
  max-width: 40%;
  position: relative;
  left: 0%;
}

.p-technology__main__box-img {
  flex: 55%;
  max-width: 55%;
  position: relative;
  top: 50px;
}

.p-technology__main__box-content {
  flex: 40%;
  max-width: 40%;
}

@media (min-width: 768px) and (max-width: 1080px) {
  .p-technology__main__box-content {
    position: relative;
    right: 5%;
    flex: 44%;
    max-width: 44%;
  }
}

.p-technology__main__box-img--bg {
  position: absolute;
  bottom: calc(-130 / 1920 * 100vw);
}

.p-technology__main__box-img--gif {
  position: relative;
}

@media (min-width: 1200px) {
  .p-technology__main__box--02 .p-technology__main__box-img .p-technology__main__box-img--gif {
    left: 10%;
  }

  .p-technology__main__box:nth-child(4) .p-technology__main__box-img .p-technology__main__box-img--gif {
    top: -30px;
  }
}

.p-technology__main__box-content--intro {
  padding-left: 40px;
  margin-top: -18px;
}

.p-technology__main__box-content--intro h3 {
  font-weight: 500;
  font-size: 40px;
  line-height: 48px;
  background: linear-gradient(180deg,
      #4291f6 32.76%,
      rgba(255, 255, 255, 0.68) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  padding-bottom: 23px;
}

.p-technology__main__box-content--intro p {
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  padding-bottom: 20px;
}

/* .p-technology__main__box-content--intro ul {
    display: grid;
    grid-template: auto auto auto/ auto auto;
} */

.p-technology__main__box-content--intro ul li {
  padding-left: 20px;
  position: relative;
  padding-bottom: 16px;
}

.p-technology__main__box-content--intro ul li::before {
  content: '';
  position: absolute;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: #fff;
  left: 8px;
  top: 10px;
}

.p-technology__main__box--02 {
  align-items: center;
  /* margin-bottom: calc(294/1920*100vw); */
}

.p-technology__main__box--02 .p-technology__main__box-img--bg,
.p-technology__main__box--03 .p-technology__main__box-img--bg {
  bottom: -100px;
}

.p-technology__main__box--04 .p-technology__main__box-img--bg {
  bottom: 0px;
}

.p-technology__main__box-content--intro-option {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.p-technology__main__box-content--intro-option ul {
  flex: 50%;
  max-width: 50%;
}

.p-technology__main__box--05 {
  align-items: end;
}

.p-technology__main__box--05 .p-technology__main__box-img--bg {
  bottom: -50px;
}

@media (max-width: 1099px) {
  .p-technology__main__box {
    align-items: center;
  }

  .p-technology__main__box--04 .p-technology__main__box-img--bg {
    bottom: calc(-130 / 1099 * 100vw);
  }
}

@media (min-width: 768px) and (max-width: 1099px) {
  .p-technology__main__box {
    margin-bottom: 10%;
  }

  .p-technology__main__box:nth-child(2) .p-technology__main__box-img--gif div {
    width: 105% !important;
  }
}

.p-technology__main__box-content--icon img {
  width: 142px;
}

@media (max-width: 767px) {
  .p-technology__main {
    margin-top: -290px;
  }

  .p-technology__main__box-content--intro h3 {
    -webkit-text-fill-color: unset;
    background: 0 0;
    -webkit-background-clip: unset;
    background-clip: unset;
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    line-height: 32px;
  }

  .p-technology__main__box {
    flex-direction: column;
    top: -100px;
  }

  .p-technology__main__box:first-child {
    top: 20px;
  }

  .p-technology__main__box:nth-child(even) {
    flex-direction: column;
    top: -100px;
  }

  .p-technology__main__box:nth-child(2) .p-technology__main__box-img--gif {
    top: 70px;
    right: 20px;
  }

  .p-technology__main__box:nth-child(2) .p-technology__main__box-img--gif div {
    width: 115% !important;
  }

  .p-technology__main__box:nth-child(4) .p-technology__main__box-img--gif {
    top: 40px;
  }

  .p-technology__main__box:nth-child(4) {
    top: -190px;
  }

  .p-technology__main__box:nth-child(5) {
    top: -230px;
  }

  .p-technology__main__box:nth-child(5) .p-technology__main__box-img--gif {
    right: 10px;
    top: 20px;
  }

  .p-technology__main__box:nth-child(6) {
    top: -350px;
  }

  .p-technology__main__box:nth-child(6) .p-technology__main__box-img--gif {
    right: 30px;
    top: 50px;
  }

  .p-technology__main__box:nth-child(6) .p-technology__main__box-img--gif div {
    width: 120% !important;
  }

  .p-technology__main__box:last-child {
    top: -350px;
    margin-bottom: -300px !important;
  }

  .p-technology__main__box-img {
    flex: 100%;
    max-width: 100%;
  }

  .p-technology__main__box-content {
    flex: 100%;
    max-width: 100%;

    position: relative;
    top: -20px;
  }

  .p-technology__main__box-content--icon {
    margin-left: -30px;
    margin-top: 50px;
  }

  .p-technology__main__box-content--intro {
    padding-left: 0;
  }

  .p-technology__main__box-content--intro p {
    font-size: 16px;
    line-height: 24px;
  }

  .p-technology__main__box-content--intro ul li {
    font-size: 14px;
    line-height: 20px;
    /* margin-bottom: 8px; */
    padding-left: 12px;
  }

  .p-technology__main__box-content--intro ul li::before {
    left: 0;
  }

  .p-technology__main__box:nth-child(even) .p-technology__main__box-img {
    flex: 100%;
    max-width: 100%;
  }

  .p-technology__main__box:nth-child(even) .p-technology__main__box-content {
    flex: 100%;
    max-width: 100%;
  }

  .p-technology__main__box:nth-child(even) .p-technology__main__box-content .p-technology__main__box-content--icon {
    display: flex;
    justify-content: flex-end;
  }

  .p-technology__main__box:nth-child(even) .p-technology__main__box-content .p-technology__main__box-content--intro {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .p-technology__main__box:nth-child(even) .p-technology__main__box-content .p-technology__main__box-content--intro p {
    text-align: end;
    width: 75%;
  }

  .p-technology__main__box:nth-child(even) .p-technology__main__box-content .p-technology__main__box-content--intro ul div {
    /* text-align: right;
    list-style-position: inside; */
    display: flex;
    justify-content: flex-end;
    margin-bottom: 8px;
  }

  .p-technology__main__box ul {
    display: grid;
    grid: none !important;
  }

  .p-technology__main__box ul div span {
    line-height: 22px;
  }

  .p-technology__main__box-img--bg {
    bottom: -90px;
  }

  .p-technology__main__box--02 .p-technology__main__box-img--bg,
  .p-technology__main__box--03 .p-technology__main__box-img--bg {
    bottom: -70px;
  }

  .p-technology__main__box--04 .p-technology__main__box-img--bg {
    bottom: -70px;
  }

  .p-technology__main__box-content--icon img {
    width: 120px;
  }
}

.p-technology__main__box ul div {
  margin-bottom: 8px;
}

@media (min-width: 1200px) {
  .l-footer__main .rowTouchRule>div {
    flex: 100% 1 !important;
    /* max-width: 50% !important; */
  }

  .l-footer__main .rowTouchRule>div.l-footer__main__office {
    max-width: 375.23px !important;
    z-index: -1;
  }
}

@media (max-width: 1199px) {
  .l-footer__main .l-row>div {
    -webkit-box-flex: 50%;
    -ms-flex: 50%;
    flex: 50%;
    max-width: 50%;
  }
}

@media (max-width: 767px) {
  .l-footer__main .l-row>div {
    -webkit-box-flex: 100%;
    -ms-flex: 100%;
    flex: 100%;
    max-width: 100%;
    margin-bottom: 13px;
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .l-footer__main .rowTouchRule>div.l-footer__main__office {
    display: none;
  }
}

.slick-active .p-home__technology-item::after {
  content: '';
  width: 50%;
  height: 100%;
  position: absolute;
  left: calc(100% + 28px);
  top: 0;
  background: -webkit-gradient(linear,
      right top,
      left top,
      color-stop(9.83%, #001530),
      color-stop(133.67%, rgba(0, 21, 48, 0)));
  background: linear-gradient(270deg,
      #001530 9.83%,
      rgba(0, 21, 48, 0) 133.67%);
  z-index: 2;
}

.slick-active .p-home__technology-item::before {
  content: '';
  width: 50%;
  height: 100%;
  position: absolute;
  right: calc(100% + 0px);
  top: 0;
  background: -webkit-gradient(linear,
      right top,
      left top,
      color-stop(9.83%, #001530),
      color-stop(133.67%, rgba(0, 21, 48, 0)));
  background: linear-gradient(270deg,
      #001530 9.83%,
      rgba(0, 21, 48, 0) 133.67%);
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
  z-index: 2;
}

@media (max-width: 1920px) {}

.solution-banner-content {
  top: calc(50% - 130px);
}

@media (max-width: 1500px) {
  .solution-banner-content {
    top: 50%;
  }
}

@media (max-width: 1200px) {
  .solution-banner-content {
    top: calc(50% - 50px);
  }
}

@media (max-width: 767px) {
  .solution-banner-content {
    top: calc(50% - 67.5px);
  }
}

.big-banner-sdk {
  margin-top: -7px;
}

@media (max-width: 1500px) {
  .banner-technology {
    top: calc(50% + 50px);
  }
}

@media (max-width: 1200px) {
  .banner-technology {
    top: calc(50% - 20px);
  }
}

@media (max-width: 767px) {
  .banner-technology {
    top: calc(50% - 109px);
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .l-footer__content .l-col-auto {
    padding-right: 0px;
  }

  .p-home__technology .slick-dots li button {
    font-size: 12px;
  }

  .p-home__technology .slick-dots li button {
    font-size: 12px;
  }

  .p-home__technology-slider {
    padding-top: 220px;
  }

  .p-home__technology .slick-dots li.slick-active button,
  .p-home__technology .slick-dots li:hover button {
    font-size: 14px;
  }
}

.p-technology__main__box:last-child {
  margin-bottom: 180px;
}

/* @media(min-width:1200px){
  .rowTouchRule{
    margin-top: -60px;
    margin-left: 40px;
  }

  .rowTouchRule .l-footer__main__technology{
    margin-left: 78px;
  }
} */

/* @media(min-width:1366px){
  .rowTouchRule{
    margin-left: 50px;
  }
} */

/* @media(min-width:1499px){
  .rowTouchRule{
    margin-left: 70px;
  }

  .rowTouchRule .l-footer__main__technology{
    margin-left: 95px;
  }
} */
/*
@media(max-width:1990px){
  .rowTouchRule .l-footer__main__technology{
    margin-left: 117px;
  }
} */

/* @media (min-width:768px) and (max-width:1199px){
  .rowTouchRule .l-footer__main__technology{
    margin-left: 0;
  }
} */

#news .p-news__featured .l-col-8 img {
  aspect-ratio: 16/9;
  object-fit: cover;
}

#news .p-news__featured .news-item img {
  aspect-ratio: 16/9;
  object-fit: cover;
}

#news .p-news__featured .news-item {
  margin-bottom: 20px;
}

.p-news__lastest-list .l-row .l-col-10 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#news .l-col-10 .p-home__news--date p {
  margin-bottom: 0;
}

.newsList_textContent {
  line-height: 5vh;
}

@media (min-width: 1200px) {
  #news .l-col-2 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  #news .l-col-10 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
    padding-left: 6vw;
  }
}

@media (max-width: 767px) {

  .p-news__lastest .l-col-2,
  .p-news__lastest .l-col-8 {
    flex: 100% 1;
    max-width: 100%;
  }

  .p-news__lastest-list .l-row .l-col-10 {
    margin-top: 20px;
  }
}

.l-header-desktop__menu>ul>.active>a {
  text-decoration: none;
}

.l-header-desktop__menu>ul>li>a {
  text-decoration: none;
}

.l-header-desktop__menu>ul>li>a:hover {
  text-decoration: none;
}

@media (max-width: 767px) {
  .p-sdk__advantages__inner>div {
    margin: 0px;
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .rowTouchRule {
    margin-top: 0 !important;
  }
}

.p-home__slider {
  /* padding-top:130px; */
  width: 100%;
  height: 100vh;
  max-height: 990px;
  display: flex;
  flex-direction: row;
  /* background-image: url('/assets/img/home/bg_home.jpg'); */
  /* background-size: fill; */
  /* background-repeat: round; */
  align-items: center;
}

.slide-left {
  width: 83%;
  height: fit-content;
}

.slide-right {
  width: 17%;
  margin-left: 17px;
}

.slide-left .slick-slider ul {
  position: relative;
  bottom: 0;
  left: 55%;
  margin-top: 50px;
}

@media (min-width: 1900px) {
  /* .slide-left .slick-slider ul {
    bottom: 40px;
  } */

  .p-home__technology {
    padding: 0 0 93px;
    position: relative;
    /* top: -48px; */
  }
}

.Slider-home {
  height: 70vh;
  max-height: 80vh;
  display: flex;
  align-items: center;
}

.home-content {
  width: 80%;
  height: 100%;
  float: right;
  background-image: url('/assets/img/home/Vector1.svg');
  background-size: contain;
  background-repeat: no-repeat;
  height: 100%;
  position: relative;
}

.home-content .home-product {
  max-height: 500px;
  height: 100%;
  aspect-ratio: 4/3;
  position: absolute;
  right: 3%;
  top: 5%;
}

@media (max-width: 1900px) {
  .home-content .home-product-01 {
    position: absolute;
    left: 22%;
    max-height: 645px;
  }
}

@media (min-width: 1900px) {
  .home-content .home-product-01 {
    position: absolute;
    left: 23%;
  }
  .home-content .home-product-03 {
    position: absolute;
    max-height: 645px;
    top: 0;
    right: 0;
  }
}

.slide-right>.slick-slider {
  height: 100%;
}

.slide-right .slick-list {
  display: flex;
  align-items: center;
  height: 100%;
}

.slide-right .slick-track {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: center;
  margin-left: 30px;
}

.slide-right .slick-track::before {
  position: absolute;
  content: '';
  left: 1px;
  width: 1px;
  height: 100%;
  background-color: #fff;
  border-radius: 10px;
}

.slide-right .slick-track .slick-slide {
  width: 100% !important;
}

.slide-right .slick-track .sub {
  width: 100%;
  margin-left: 10px;
}
.slide-right .slick-track .slick-slide {
  margin-top: 40px;
}
.slide-right .slick-track .slick-slide:first-child {
  margin-top: 0;
}

.slide-right .slick-track .sub .content {
  position: relative;
  height: fit-content;
  cursor: pointer;
}

.slide-right .sub .content {
  opacity: 0.6;
}

.slide-right .slick-current .sub .content {
  opacity: 1;
}

/* .slide-right .slick-track .slick-slide:last-child .sub {
  display: flex !important;
  justify-content: flex-end;
  flex-direction: column;
} */
.slide-right .slick-current .content::before {
  position: absolute;
  content: '';
  left: -10px;
  top: 0;
  border-radius: 8px;
  width: 3px;
  height: 50px;
  background-color: #fff;
}

.slide-right .content h6 {
  font-weight: 500;
  font-size: 16px;
  line-height: 18.75px;
}

.slide-right .content h2 {
  font-weight: 700;
  font-size: 24px;
  line-height: 28.13px;
  margin-right: 40px;
}

.sub_slider {
  position: absolute;
  /*background-color: #0063AA;*/
  background-image: url('/assets/img/home/Frame_635047.png');
  background-size: cover;
  background-position: center;
  width: 100%;
  max-width: 507px;
  border-radius: 8px;
  padding: 50px 26px 60px;
  top: 20%;
  left: -100px;
  z-index: 99;
}

.p-home__slider .home-content .sub_slider .img-prize {
  max-width: 230px;
  position: relative;
}
html[lang='vi'] .p-home__slider .home-content .sub_slider .img-prize {
  max-width: 350px;
}


.title-card {
  font-size: 20px;
  font-weight: 500;
  position: relative;
  width: fit-content;
}

.title-card::before {
  position: absolute;
  content: '';
  bottom: -5px;
  width: 100%;
  height: 3px;
  background-color: #fff;
}

.header-card {
  font-size: 44px;
  font-weight: 700;
  line-height: 56px;
  margin-top: 19px;
}
.p-home__slider .img-prize {
  margin-top: 10px;
}
html[lang='vi'] .p-home__slider .img-prize {
  margin-top: -4px;
}

@media (max-width: 1540px) {
  .slide-right .slick-track {
    margin-left: 5px;
  }

  .slide-right .content h2 {
    font-size: 22px;
  }
}

@media (min-width: 1500px) {
  .header-card {
    font-size: 48px;
  }
}

@media (max-width: 1500px) {
  .slide-right {
    width: 21%;
    margin-left: -15px;
  }

  .slide-right .content h2 {
    font-size: 20px;
  }

  .slide-right .content h6 {
    font-size: 14px;
  }
}

.home-content .sub_slider .card-content {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-top: 10px;
  max-width: 414px;
}

.link-card {
  display: flex;
  margin-top: 10px;
  font-weight: 700;
}

.link-card img {
  margin-left: 15px;
  max-width: 20.78px;
  max-height: 20.23px;
  position: relative;
  top: 2px;
}

.p-home .BannerMobile {
  display: none;
}

@media screen and (max-width: 1800px) {
  .home-content .home-product-01 {
    max-height: 590px;
    top: 0;
    left: 28%;
  }
}

@media screen and (max-width: 1700px) {
  .sub_slider {
    left: -180px;
  }

  .home-content .home-product {
    max-height: 430px;
  }

  .home-content .home-product-01 {
    max-height: 560px;
    top: 0;
    left: 25%;
  }
}

@media screen and (max-width: 1600px) {
  .home-content .home-product-01 {
    max-height: 485px;
    top: 0;
    left: 28%;
  }
}

@media screen and (max-width: 1500px) {
  .sub_slider {
    max-width: 450px;
    max-height: 345px;
    top: 10%;
  }

  .home-content .home-product {
    max-height: 400px;
  }

  .home-content .home-product-01 {
    max-height: 440px;
    top: 0;
    left: 25%;
  }

  .p-home .slide-left .banner-slider .slick-list {
    height: 535px !important;
  }
}

@media screen and (max-width: 1300px) {
  .sub_slider {
    max-width: 465px;
    max-height: 345px;
    top: 10%;
  }

  .home-content .home-product {
    max-height: 350px;
  }

  .p-home .slide-right {
    display: none;
  }

  .p-home .slide-left {
    width: 95%;
  }
}

@media (min-width: 660px) and (max-width: 767px) {
  .bannerMobile-slider .home-content img {
    display: none;
  }

  .bannerMobile-slider .sub_slider {
    margin-top: 45px;
  }
}

@media (max-width: 350px) {
  .bannerMobile-slider .sub_slider {
    margin-top: 40px;
  }

  .l-header-mobile__left {
    width: 59%;
  }

  .l-header-mobile__left img {
    width: 80%;
  }

  .l-header-mobile__right img {
    width: 55%;
    margin-top: 6px;
  }
}

@media screen and (max-width: 1200px) {
  .p-home .slide-left {
    display: none;
  }

  .p-home .BannerMobile {
    display: block;
    width: 100%;
    margin-top: 0px;
  }

  .p-home .BannerMobile .slick-dots {
    display: block !important;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0px;
  }

  .bannerMobile-slider .sub_slider {
    position: initial;
    max-width: 330px;
    padding-bottom: 30px !important;
  }

  .bannerMobile-slider .home-content img {
    position: initial;
  }

  .bannerMobile-slider .Slider-home {
    display: flex !important;
    justify-content: center;
    text-align: center;
    padding: 0px;
  }

  .bannerMobile-slider .home-content {
    background-image: none;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .bannerMobile-slider .title-card {
    margin: 15px 0px 10px 0px;
    font-size: 16px;
    border-bottom: 1px solid white;
    display: inline-block;
    width: auto;
    line-height: 20px;
  }

  .bannerMobile-slider .title-card:before {
    position: relative;
  }

  .bannerMobile-slider .header-card {
    font-size: 24px;
    margin: 0px 0px 10px 0px;
    line-height: 24px;
  }

  .bannerMobile-slider .card_content {
    font-size: 16px;
    line-height: 20px;
  }

  .bannerMobile-slider .sub_slider {
    padding: 0px 20px;
    margin-bottom: 25px;
  }

  .bannerMobile-slider .link-card {
    margin-top: 10px;
    display: flex;
    justify-content: center;
  }

  .bannerMobile-slider .home-product {
    max-height: 230px;
  }
}

@media screen and (max-width: 768px) {
  .bannerMobile-slider .header_item_1 {
    margin-bottom: 0;
  }

  .home-content .sub_slider .card-content {
    line-height: 20px;
    padding: 0 10px;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .p-home .BannerMobile {
    margin-top: 230px;
    margin-bottom: 10px;
  }

  .p-home .BannerMobile .slick-dots {
    bottom: -25px;
  }

  .p-home__technology--sp {
    margin-top: 140px;
  }
}

/* p-home__prize Begin. */
.p-home__prize {
  width: calc(1681px + 48px);
  max-width: 100%;
  padding: 124px 24px 185.95px 24px;
  margin: auto;
  position: relative;
}

.p-home__prize .img-home_prize div {
  width: 100%;
}

.p-home__prize .img-home_prize div>img {
  border-radius: 20px;
  width: 100%;
  object-fit: cover;
}

.p-home__prize .p-home__prize__desc {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 216px;
  right: 333px;
  padding-left: 18px;
  padding-right: 18px;
}

.p-home__prize .p-home__prize__desc h1 {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 700;
  font-size: 64px;
  line-height: 80px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 13.4px;
}

.p-home__prize .p-home__prize__desc h2 {
  font-family: 'Lobster', cursive;
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 48px;
  text-align: center;
  color: #f5be2a;
  margin-top: 16.4px;
  margin-bottom: 3.6px;
  position: relative;
}

.p-home__prize .p-home__prize__desc img {
  margin-bottom: 19px;
}

.p-home__prize .p-home__prize__desc h3 {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 56px;
  text-align: center;
  color: #ffffff;
  margin-top: 13.4px;
}

.p-home__prize .p-home__prize__desc p {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: #ffffff;
}

.p-home__prize .slick-dots {
  display: flex !important;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.p-home__prize .slick-dots > li {
  display: flex;
  margin: 0 12px;
}
.p-home__prize .slick-dots > li > button {
  font-size: 0;
  width: 10px;
  height: 10px;
  border: 1px solid #ffffff;
  border-radius: 50%;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
  transition: 0.4s all;
}
.p-home__prize .slick-dots > li.slick-active > button {
  width: 20px;
  height: 20px;
  background-color: #fff;
}

@media screen and (max-width: 1200px) {
  .p-home__prize .p-home__prize__desc {
    top: 160px;
    right: 160px;
  }

  .p-home__prize .p-home__prize__desc h2 {
    font-size: 30px;
    line-height: 34px;
  }

  .p-home__prize .p-home__prize__desc img {
    margin-bottom: 10px;
  }

  .p-home__prize .p-home__prize__desc h3 {
    font-size: 30px;
    line-height: 36px;
  }

  .p-home__prize .p-home__prize__desc p {
    font-size: 20px;
    line-height: 24px;
  }
}

@media screen and (max-width: 768px) {
  .p-home__prize {
    padding-top: 48px;
  }

  .p-home__prize .p-home__prize__desc {
    top: 260px;
    left: 50%;
    transform: translate(-50%);
    width: 100%;
    padding-left: 24px;
    padding-right: 24px;
  }

  .p-home__prize .p-home__prize__desc h1 {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 8.4px;
  }

  .p-home__prize .p-home__prize__desc h2 {
    font-size: 24px;
    line-height: 48px;
    margin-top: 8.4px;
  }

  .p-home__prize .p-home__prize__desc img {
    margin-bottom: 7px;
    margin-top: -11px;
  }

  .p-home__prize .p-home__prize__desc h3 {
    font-size: 24px;
    line-height: 32px;
    margin-top: 9.4px;
  }

  .p-home__prize .p-home__prize__desc p {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }
}

@media screen and (max-width: 479px) {
  .p-home__prize .p-home__prize__desc {
    top: 150px;
  }
}

/* p-home__prize End. */

/* p-home__newspaper Begin. */
.p-home__newspaper {
  background: url('/assets/img/home/bg-newspaper.jpg') center no-repeat;
  position: relative;
}

.p-home__newspaper__container {
  width: calc(1681px + 48px);
  margin: auto;
  max-width: 100%;
  padding-left: 24px;
  padding-right: 24px;
  display: flex;
}

.p-home__newspaper__container .p-home__newspaper__left {
  flex: 45%;
  max-width: 45%;
}

.p-home__newspaper__container .p-home__newspaper__left h1 {
  font-family: 'UTM Bebas';
  font-style: normal;
  font-weight: 400;
  font-size: 64px;
  line-height: 80px;
  color: #ffffff;
  padding-left: 235px;
  padding-top: 181px;
  text-transform: uppercase;
}

.p-home__newspaper__container .p-home__newspaper__right {
  flex: 55%;
  max-width: 55%;
  position: relative;
}

.p-home__newspaper__container .p-home__newspaper__right .box {
  display: flex;
  background: #ffffff;
  box-shadow: 0px 1px 19px 3px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  padding: 17.5px 21px 15.5px 82px;
  margin-top: 12px;
  margin-bottom: 12px;
  margin-right: 100px;
}

.p-home__newspaper__container .p-home__newspaper__right .box .left>img {
  margin-top: 14px;
}

.p-home__newspaper__container .p-home__newspaper__right .box .left p {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #0063aa;
  margin-top: 16px;
  margin-bottom: 8px;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.p-home__newspaper__container .p-home__newspaper__right .box .left a {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #0063aa;
  display: flex;
  align-items: center;
}

.p-home__newspaper__container .p-home__newspaper__right .box .left a img {
  margin-left: 16px;
}

.p-home__newspaper__container .p-home__newspaper__right .box .right img {
  border-radius: 8px;
}

@media (min-width: 1200px) {
  .p-home__newspaper__container .p-home__newspaper__right .box .left p {
    margin-right: 15.46px;
  }
}

@media (min-width: 768px) {
  .p-home__newspaper__container .p-home__newspaper__right .box .right img {
    max-width: 244px;
    max-height: 222px;
  }

  .p-home__newspaper__right .item-mb {
    display: none;
  }
}

@media (max-width: 767px) {
  .p-home__slider .home-content .sub_slider .img-prize {
    max-width: 70%;
    margin: auto;
  }
  html[lang="vi"] .p-home__slider .home-content .sub_slider .img-prize {
    max-width: 100%;
  }
  .p-home__newspaper__container .p-home__newspaper__right .box .right img {
    width: 100%;
  }

  .p-home__newspaper__right .item-mb {
    display: block;
  }
}

@media (max-width: 376px) {
  .p-home__newspaper__container .p-home__newspaper__right .box .right img {
    max-width: 244px;
    max-height: 222px;
    position: absolute;
    bottom: 18.48px;
  }

  .p-home__newspaper__container .p-home__newspaper__right .box {
    height: 432.77px;
    width: 283.6px;
  }
}

.p-home__newspaper__container .p-home__newspaper__right .inner {
  position: relative;
  padding-left: 81px;
}

.p-home__newspaper__container .p-home__newspaper__right .box .logo img {
  position: absolute;
  left: 7px;
  top: 90px;
}

.p-home__newspaper__container .p-home__newspaper__right>img {
  margin-left: 16px;
}

.p-home__newspaper__container .slick-dots {
  position: absolute;
  top: 52%;
  transform: translateY(-50%);
  right: 40px;
  display: flex;
  flex-direction: column;
  gap: 7px;
}

/* .p-home__newspaper__container .slick-dots li {
    width: 10px;
    height: 10px;
  } */
.p-home__newspaper__container .slick-dots .slick-active button {
  background: #ffffff;
  border-radius: 5px;
  height: 49px;
  width: 10px;
}

.p-home__newspaper__container .slick-dots li button {
  display: block;
  width: 10px;
  height: 10px;
  background: rgba(223, 237, 255, 0.45);
  /* opacity: 0.45; */
  border-radius: 5px;
  text-indent: -9999px;
  padding: 0;
  margin: 7px 0;
  border: none;
  outline: none;
  transition: 0.3s ease;
}

.p-home__newspaper__container .slick-arrow {
  background: none;
  border: none;
  outline: none;
  position: absolute;
  bottom: 0;
  left: calc(50% - 100px);
  transform: translateX(-50%);
  z-index: 1000;
}

.p-home__newspaper__container .slick-next {
  margin-left: 100px;
}

.p-home__newspaper__container .slick-list {
  height: 800px !important;
  padding-top: 175px;
}

.p-home__newspaper__container .slick-active+.slick-slide {
  opacity: 0;
}

.p-home__newspaper__container .slick-active {
  opacity: 1 !important;
}

.p-home__newspaper__coat {
  height: 311.06px;
  width: 100%;
  left: 0;
  top: -2px;
  background: linear-gradient(360deg, #001530 -8.24%, rgba(0, 21, 48, 0) 50%);
  transform: rotate(-180deg);
  z-index: 10;
  position: absolute;
}

@media (max-width: 1450px) {
  .p-home__newspaper__container .p-home__newspaper__left h1 {
    padding-left: 70px;
  }

  .p-home__newspaper__container .p-home__newspaper__left {
    flex: 35% 1;
    max-width: 35%;
  }

  .p-home__newspaper__container .p-home__newspaper__right {
    flex: 65% 1;
    max-width: 65%;
  }
}

@media (max-width: 1250px) {
  .p-home__newspaper__container {
    flex-direction: column;
    /* align-items: center; */
  }

  .p-home__newspaper__container .p-home__newspaper__left {
    flex: 100% 1;
    max-width: 100%;
    display: flex;
    justify-content: center;
  }

  .p-home__newspaper__container .p-home__newspaper__right {
    flex: 90% 1;
    max-width: 90%;
  }

  .p-home__newspaper__container .p-home__newspaper__left h1 {
    text-align: center;
    padding: 0;
    margin-bottom: 30px;
  }

  .p-home__newspaper__coat {
    top: 155px;
  }
}

@media (max-width: 900px) {
  .p-home__newspaper__container .p-home__newspaper__right {
    flex: 100% 1;
    max-width: 100%;
  }

  .p-home__newspaper__container .slick-arrow {
    bottom: -70px;
    left: calc(50% - 55px);
  }
}

@media (max-width: 767px) {
  .p-home__newspaper__container .slick-arrow {
    bottom: -100px;
  }

  .p-home__newspaper__container .p-home__newspaper__left h1 {
    margin-bottom: 22.43px;
  }

  .p-home__newspaper__container .p-home__newspaper__right .inner {
    padding: 0;
  }

  .p-home__newspaper__container .p-home__newspaper__right .box {
    flex-direction: column;
    margin: 0;
    padding: 24px 20px 18.48px;
    border-radius: 8px;
  }

  .p-home__newspaper__container .p-home__newspaper__right .box .right {
    display: flex;
    justify-content: center;
    margin-top: 16px;
  }

  .p-home__newspaper__coat {
    display: none;
  }

  .p-home__newspaper__container .p-home__newspaper__right .box .logo img {
    left: 28px;
    top: -32px;
  }

  .item-pc {
    display: none !important;
  }

  .p-home__newspaper__container .slick-dots {
    bottom: -40px;
    left: 50%;
    transform: translateX(-50%);
    top: unset;
    right: unset;
    flex-direction: row;
  }

  .p-home__newspaper__container .slick-dots .slick-active button {
    width: 31.31px;
    height: 8px;
  }

  .p-home__newspaper__container .slick-dots li button {
    height: 8px;
    width: 8px;
  }

  .p-home__newspaper__container .slick-list {
    height: unset !important;
    padding-top: 50px;
    overflow: visible;
  }

  .p-home__newspaper__container .p-home__newspaper__left h1 {
    font-family: 'UTM Bebas';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: #ffffff;
    width: 170px;
  }

  .p-home__prize {
    padding-bottom: 108px;
  }

  .p-home__newspaper__container .p-home__newspaper__right .box {
    margin: 0 12px;
  }

  .p-home__newspaper__container .p-home__newspaper__right .box .logo img {
    width: 68px;
    height: 68px;
  }

  .p-home__newspaper {
    background: url(/assets/img/home/bg-newspaper-sp.jpg) center no-repeat;
    height: 685px;
  }

  .p-home__newspaper__container .slick-arrow img {
    width: 40px;
    height: 40px;
  }

  .p-home__newspaper__container .slick-slider {
    padding-right: 50px;
  }

  .p-home__newspaper__container .slick-slide {
    opacity: 0;
  }

  .p-home__newspaper__container .slick-active+.slick-slide {
    opacity: 1;
  }

  .p-home__newspaper__container {
    padding-left: 12px;
    padding-right: 0;
  }
}

/* p-home__newspaper End. */

@media (max-width: 767px) {
  .l-footer__main {
    margin-top: 0;
    padding: 64px 0 0;
  }
}

.item-sp-2::before {
  top: -45px;
}

.p-home__solution-icon {
  margin-bottom: 16px;
}

.p-home__solution-item .home-news {
  margin-bottom: 0px;
}

@media (max-width: 767px) {
  .p-home__solution {
    padding-top: 75px;
  }
}

@media (max-width: 320px) {
  .p-home__solution {
    padding: 60px 0 0 !important;
  }
}

.lottie_home_safety {
  height: 100%;
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
}

.lottie_home_safety>div {
  float: right;
  margin-right: 0 !important;
}

/* .lottie_home_safety_mb {
  padding: 50px;
} */
.update_contact_a {
  margin-bottom: 10px;
}

.update_contact_a span {
  margin-left: 10px;
}
.p-home__slider img {
  object-fit: contain;
}
