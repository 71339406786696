@charset "utf-8";

.p-home {
  &__slider {
    @include brSpLandscape {
      margin-top: -100px;
    }
    &__follow {
      position: absolute;
      right: calc(100% + 89px);
      top: 6%;
      padding-top: 25px;
      display: flex;
      flex-direction: column;
      align-items: center;
      z-index: 1;
      &-label {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: -0.02em;
        color: rgba(255, 255, 255, 0.8);
        transform: rotate(-90deg);
        white-space: nowrap;
      }
      ul {
        margin-top: 38px;
        &::before {
          content: '';
          width: 1px;
          height: 30px;
          background-color: #fff;
          display: block;
          margin-left: 16px;
          margin-bottom: 11px;
        }
        li {
          display: flex;
          + li {
            margin-top: 16px;
          }
          a {
            img {
              width: 32px;
              height: 32px;
            }
          }
        }
      }
    }
    &__item {
      position: relative;
      > img {
        &.sp {
          display: none;
          @include brSpLandscape {
            display: block;
          }
        }
        &.pc {
          @include brSpLandscape {
            display: none;
          }
        }
      }
    }
    &__content {
      position: absolute;
      top: 23.3%;
      left: calc((100% - 1100px) / 2);
      width: 1188px;
      max-width: 90%;
      z-index: 1;
      @media (max-width: 1366px) {
        top: 35px;
      }
      @include brSmallPc {
        left: 0;
        width: 100%;
        max-width: 100%;
        padding: 0 25px;
      }
      @include brSpLandscape {
        text-align: center;
        bottom: 91px;
        top: auto;
      }
      h2 {
        font-weight: 700;
        font-size: 68.7037px;
        line-height: 86px;
        text-transform: uppercase;
        color: #1f7aff;
        margin-bottom: 3px;
        @include brSmallPc {
          font-size: 40px;
          line-height: 60px;
        }
        @include brSpLandscape {
          font-weight: 700;
          font-size: 40px;
          line-height: 32px;
          margin-bottom: 16px;
        }
      }
      p {
        font-weight: 700;
        font-size: 32px;
        line-height: 48px;
        padding-left: 6px;
        margin-bottom: 54px;
        @include brSmallPc {
          font-size: 22px;
          line-height: 32px;
        }
        @include brSpLandscape {
          font-weight: 500;
          font-size: 24px;
          line-height: 32px;
          margin-bottom: 32px;
          padding: 0 5px;
        }
      }
      .c-btn {
        margin-left: 2px;
        @include brSmallPc {
        }
        @include brSpLandscape {
          font-size: 16px;
          line-height: 24px;
          padding: 12px 42px;
        }
      }
    }
    &__btn-play {
      margin-left: 28px;
    }
    .slick-slide {
      outline: none;
      overflow: hidden;
    }
    .slick-arrow {
      width: 52px;
      height: 52px;
      cursor: pointer;
      padding: 0;
      border: none;
      position: absolute;
      background-color: transparent;
      top: 50%;
      transform: translateY(-50%);
      transition: 0.4s;
      z-index: 1;
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.65);
        opacity: 0.54;
        z-index: 1;
      }
      img {
        position: relative;
        z-index: 2;
      }
      &:hover {
        opacity: 0.6;
      }
      &.slick-prev {
        left: 0;
      }
      &.slick-next {
        right: 0;
      }
    }
    .slick-dots {
      position: absolute;
      bottom: 153px;
      right: calc((100% - 1188px) / 2);
      display: flex !important;
      align-items: center;
      @include brSmallPc {
        left: 25px;
        right: auto;
        bottom: 30px;
      }
      @include brTablet {
        display: none !important;
      }
      li {
        display: inline-flex;
        margin: 0 12px;
        &.slick-active {
          button {
            background-color: #fff;
          }
        }
        button {
          font-size: 0;
          width: 16px;
          height: 16px;
          border: 1.5px solid #ffffff;
          border-radius: 50%;
          background-color: transparent;
          padding: 0;
          cursor: pointer;
          transition: 0.4s all;
          &:hover {
            background-color: #fff;
          }
        }
      }
    }
  }

  &__technology {
    padding: 96px 0 93px;
    @include brSpLandscape {
      padding: 43px 0 43px;
    }
    &--pc {
      @include brSpLandscape {
        display: none;
      }
    }
    &--sp {
      display: none;
      @include brSpLandscape {
        display: block;
      }
    }
    &-slider {
      display: flex;
      flex-direction: column;
      padding-left: 447px;
      padding-right: 447px;
      padding-top: 171px;
      @media (max-width: 1800px) {
        padding-left: calc(447 / 1903 * 100vw);
        padding-right: calc(447 / 1903 * 100vw);
      }
      @include brTablet {
        padding-left: 50px;
        padding-right: 50px;
      }
      @include brSpLandscape {
        padding: 0;
        margin-top: 17px;
      }
    }
    &-item {
      position: relative;
      padding: 0 27.5px;
      @include brSpLandscape {
        padding: 0 24px;
        margin-bottom: 40px;
      }
      &.slick-active {
        &::before {
          content: '';
          width: 50%;
          height: 100%;
          position: absolute;
          right: calc(100% + 0px);
          top: 0;
          background: linear-gradient(
            270deg,
            #001530 9.83%,
            rgba(0, 21, 48, 0) 133.67%
          );
          transform: rotate(-180deg);
          z-index: 2;
        }
        &::after {
          content: '';
          width: 50%;
          height: 100%;
          position: absolute;
          left: calc(100% + 28px);
          top: 0;
          background: linear-gradient(
            270deg,
            #001530 9.83%,
            rgba(0, 21, 48, 0) 133.67%
          );
          z-index: 2;
        }
        .p-home__solution-content {
          left: 82px;
        }
        .c-btn {
          padding: 11px 11px 13px;
          background-color: $cBase;
        }
      }
    }
    &-content {
      width: 47%;
      position: absolute;
      bottom: 38px;
      left: 70px;
      z-index: 1;
      @include brSpLandscape {
        width: 100%;
        left: 0;
        padding: 0 65px;
        bottom: 28px;
      }
      > img {
        margin-left: -24px;
        @include brSpLandscape {
          width: 120px;
          margin-left: -30px;
        }
      }
      h5 {
        font-weight: 500;
        font-size: 40px;
        line-height: 48px;
        margin-bottom: 8px;
        margin-top: -29px;
        margin-left: 13px;
        @include brSpLandscape {
          font-size: 24px;
          margin-left: 0;
          margin-bottom: 0;
        }
      }
      p {
        font-weight: 400;
        font-size: 20px;
        line-height: 32px;
        margin-bottom: 24px;
        margin-left: 11px;
        @include brSpLandscape {
          font-size: 20px;
          margin-left: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          margin-bottom: 6px;
        }
      }
      div {
        display: flex;
        align-items: center;
        a {
          padding: 11px 11px 13px;
          background-color: transparent;
          @include brSpLandscape {
            font-family: 'Roboto Condensed';
            padding-left: 0;
            // &:hover{
            // 	background-color: $cBase;
            // 	font-family: 'Roboto Condensed';
            // 	padding: 11px 14px 13px;
            // 	opacity: 1;
            // }
          }
        }
        img {
          margin-left: 7px;
        }
      }
    }
    .slick-list {
      overflow: visible;
      order: 2;
    }
    .slick-dots {
      display: flex;
      position: absolute;
      top: 2px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 1;
      display: block;
      width: 1144px;
      max-width: 100%;
      margin-left: auto;
      margin-right: auto;
      order: 1;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-left: -12px;
      margin-right: -12px;
      @include brTablet {
        margin-left: 0;
        margin-right: 0;
      }
      li {
        padding-left: 12px;
        padding-right: 12px;
        flex: 25%;
        max-width: 25%;
        margin-bottom: 16px;
        &.slick-active,
        &:hover {
          button {
            color: #001530;
            background-color: #fff;
          }
        }
        button {
          font-weight: 500;
          font-size: 20px;
          line-height: 32px;
          text-align: center;
          color: rgba(255, 255, 255, 0.56);
          padding: 9px 10px;
          border: 1px solid #ffffff;
          border-radius: 32px;
          width: 100%;
          background-color: transparent;
          transition: 0.2s;
          @include brTablet {
            font-size: 13px;
          }
        }
      }
    }
    .slick-arrow {
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 90px;
      height: 90px;
      background: rgba(255, 255, 255, 0.4);
      position: absolute;
      top: 63.5%;
      transform: translateY(-50%);
      z-index: 1;
      &.slick-prev {
        left: 0;
      }
      &.slick-next {
        right: 0;
      }
    }
  }

  &__solution {
    padding-top: 10px;
    padding-bottom: 53px;
    background-size: cover;
    @include brSpLandscape {
      padding-top: 27px;
    }
    .c-title {
      margin-bottom: 55px;
      @include brSpLandscape {
        text-align: center;
        margin-bottom: 94px;
      }
      p {
        @include brSpLandscape {
          width: 314px;
          max-width: 100%;
          margin-left: auto;
          margin-right: auto;
          margin-top: -6px;
        }
      }
      .c-btn {
        display: none;
        @include brSpLandscape {
          display: inline-block;
          margin-top: 24px;
          padding: 12px 34px;
        }
      }
    }
    .l-row {
      @include brSpLandscape {
        display: none;
      }
    }
    .l-col {
      flex: 33.33333%;
      max-width: 33.33333%;
      margin-bottom: 45px;
      @include brTablet {
        flex: 50%;
        max-width: 50%;
      }
    }
    &-slider {
      display: none;
      &-count {
        display: none;
        text-align: center;
        @include brSpLandscape {
          display: block;
          position: relative;
          top: -1px;
          left: -18px;
        }
        .current-slide {
          font-family: 'UTM Bebas';
          font-style: normal;
          font-weight: 400;
          font-size: 60px;
          line-height: 80px;
          position: relative;
          top: -44px;
          left: 42px;
        }
        .separate {
          &::before {
            content: url('../../img/home/icn_separate.svg');
            position: relative;
            top: 4px;
            left: 13px;
          }
        }
        .total-slide {
          font-family: 'UTM Bebas';
          font-style: normal;
          font-weight: 400;
          font-size: 48px;
          line-height: 100px;
          color: #9a9999;
          position: relative;
          top: 16px;
          left: -8px;
        }
      }
      @include brSpLandscape {
        display: block;
        margin-right: 25px;
        margin-left: 12px;
        .slick-list {
          overflow: visible;
        }
        .slick-arrow {
          width: 40px;
          height: 40px;
          border: none;
          background: rgba(255, 255, 255, 0.4);
          position: absolute;
          top: -118px;
          &.slick-prev {
            left: -12px;
          }
          &.slick-next {
            right: -25px;
          }
        }
      }
    }
    &-item {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: relative;
      @include brSpLandscape {
        &::before {
          content: '';
          width: 3px;
          height: 178px;
          position: absolute;
          background-color: $cBase;
          border-radius: 6px;
          left: -19px;
          bottom: 47px;
        }
      }
      h3 {
        font-weight: 700;
        font-size: 20px;
        line-height: 32px;
        margin-bottom: 8px;
        min-height: 64px;
      }
      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        // margin-bottom: 10px;
        padding-right: 35px;
        @include brSpLandscape {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 4;
        }
      }
    }
    &-icon {
      width: 96px;
      height: 96px;
      background-color: #fff;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 24px;
    }
    &-btn {
      display: flex;
      align-items: center;
      .c-btn {
        background-color: transparent;
        padding-left: 0;
        padding-right: 0;
        @include brSpLandscape {
          font-size: 18px;
        }
        // &:hover{
        // 	padding-left: 10px;
        // 	padding-right: 12px;
        // 	background-color: $cBase;
        // 	opacity: 1;
        // }
      }
      img {
        margin-left: 10px;
      }
    }
  }

  &__products {
    padding: 174px 0 50px;
    @include brSpLandscape {
      padding: 90px 0 50px;
    }
    .l-container {
      width: 100%;
      padding: 0;
      @include brSpLandscape {
        padding: 0 24px;
      }
    }
    .c-title {
      position: relative;
      z-index: 2;
      @include brSpLandscape {
        margin-bottom: 81px;
      }
      h3 {
        padding-bottom: 17px;
      }
      p {
        font-weight: 500;
        font-size: 18px;
        line-height: 32px;
        text-align: center;
        letter-spacing: -0.02em;
        margin-bottom: 56px;
        @include brSpLandscape {
          font-size: 20px;
          line-height: 32px;
          margin-bottom: 30px;
        }
      }
      .c-btn {
        padding: 14px 30.75px;
      }
    }
    &-left {
      flex: 50%;
      max-width: 50%;
      @include brTablet {
        margin-top: 50px;
      }
      @include brSpLandscape {
        flex: 100%;
        max-width: 100%;
        margin-top: 27px;
        padding: 0;
      }
    }
    &-right {
      flex: 50%;
      max-width: 50%;
      @include brSpLandscape {
        flex: 100%;
        max-width: 100%;
        padding: 0;
      }
    }
    &-right-inner {
      margin-top: 198px;
      width: 527px;
      max-width: 100%;
      margin-right: auto;
      @include brTablet {
        margin-top: 30px;
      }
      @include brSpLandscape {
        margin-top: -25px;
      }
    }
    &-slider {
      margin-top: -133px;
      @include brTablet {
        margin-top: 0;
      }
      &-count {
        text-align: center;
        @media (min-width: 768px) {
          text-align: right;
          padding-right: 30px;
          margin-bottom: -69px;
        }
        @include brSpLandscape {
          display: block;
          position: relative;
          top: -1px;
          left: -18px;
        }
        .current-slide {
          font-family: 'UTM Bebas';
          font-style: normal;
          font-weight: 400;
          font-size: 60px;
          line-height: 80px;
          position: relative;
          top: -44px;
          left: 42px;
          @media (min-width: 768px) {
            font-size: 80px;
            line-height: 80px;
          }
        }
        .separate {
          &::before {
            content: url('../../img/home/icn_separate.svg');
            position: relative;
            top: 4px;
            left: 13px;
          }
        }
        .total-slide {
          font-family: 'UTM Bebas';
          font-style: normal;
          font-weight: 400;
          font-size: 48px;
          line-height: 100px;
          color: #9a9999;
          position: relative;
          top: 16px;
          left: -8px;
          @media (min-width: 768px) {
            font-size: 60px;
          }
        }
      }
      @include brSpLandscape {
        .slick-arrow {
          width: 40px;
          height: 40px;
          border: none;
          background: rgba(255, 255, 255, 0.4);
          position: absolute;
          top: -118px;
          &.slick-prev {
            left: -12px;
          }
          &.slick-next {
            right: -25px;
          }
        }
      }
    }
    &-item {
      .l-row {
        width: 1465px;
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
      }
      h3 {
        font-family: 'UTM Bebas';
        font-style: normal;
        font-weight: 400;
        font-size: 48px;
        line-height: 80px;
        text-transform: uppercase;
        @include brSpLandscape {
          margin-bottom: 7px;
        }
      }
      p {
        font-weight: 400;
        font-size: 18px;
        line-height: 30px;
        letter-spacing: -0.02em;
        @include brSpLandscape {
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
    &-features {
      margin-top: 51px;
      &-icon {
        min-width: 56px;
        width: 56px;
        height: 56px;
        border-radius: 50%;
        background: #4291f6;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 32px;
      }
      &-info {
        h5 {
          font-weight: 700;
          font-size: 24px;
          line-height: 32px;
          margin-bottom: 4px;
          margin-top: -2px;
          @include brSpLandscape {
            font-size: 20px;
          }
        }
        p {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: -0.02em;
        }
      }
      li {
        display: flex;
        margin-bottom: 25px;
        position: relative;
        @include brSpLandscape {
          margin-bottom: 23px;
        }
        &:nth-child(3) {
          &::before {
            height: 53.84px;
          }
        }
        &:last-child {
          &::before {
            opacity: 0;
          }
        }
        &::before {
          content: '';
          width: 2px;
          height: 28px;
          background-color: #4291f6;
          position: absolute;
          left: 28px;
          bottom: -12px;
          z-index: 1;
          @include brSpLandscape {
            left: 26px;
            bottom: -4px;
          }
        }
      }
    }
    .slick-dots {
      text-align: center;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 25px;
      z-index: 1;
      @include brTablet {
        bottom: -25px;
      }
      li {
        display: inline-block;
        margin: 0 2px;
        &.slick-active {
          button {
            width: 39px;
            background-color: #fff;
          }
        }
        button {
          width: 14px;
          height: 8px;
          background: #9c9c9c;
          border-radius: 8px;
          border: none;
          font-size: 0;
          transition: 0.4s;
        }
      }
    }
    .slick-arrow {
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 90px;
      height: 90px;
      background: rgba(255, 255, 255, 0.4);
      position: absolute;
      top: 41.3%;
      transform: translateY(-50%);
      z-index: 1;
      @include brSpLandscape {
        top: -86px;
        transform: none;
        width: 40px;
        height: 40px;
      }
      &.slick-prev {
        left: 0;
      }
      &.slick-next {
        right: 0;
      }
    }
  }

  &__facesdk {
    padding: 97px 0 70px;
    @include brSpLandscape {
      padding: 87px 0 70px;
    }
    .l-container {
      width: 1114px;
      padding-left: 7px;
      padding-right: 7px;
      @include brTablet {
        padding-left: 25px;
        padding-right: 25px;
      }
    }
    .c-title {
      position: relative;
      margin-bottom: 113px;
      @include brSpLandscape {
        margin-bottom: 60px;
      }
      p {
        text-align: center;
        letter-spacing: -0.02em;
        margin-bottom: 25px;
        @include brSpLandscape {
          width: 319px;
          max-width: 100%;
          margin-left: auto;
          margin-right: auto;
          margin-top: -7px;
        }
      }
      .c-btn {
        padding: 12px 34px;
      }
    }
    .l-row {
      margin-left: -7px;
      margin-right: -7px;
      &.item-sp {
        @include brSpLandscape {
          display: flex;
        }
      }
      .l-col {
        padding-left: 7px;
        padding-right: 7px;
        flex: 50%;
        max-width: 50%;
      }
    }
    &-item {
      height: 100%;
    }
    &-inner {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 16px;
      text-align: center;
      position: relative;
      img {
        width: 100%;
      }
      h3 {
        font-weight: 700;
        font-size: 32px;
        line-height: 32px;
        position: absolute;
        bottom: 37px;
        width: 100%;
        @include brSpLandscape {
          font-size: 20px;
          line-height: 32px;
          bottom: -49px;
        }
      }
    }
  }

  &__news {
    padding: 132px 0 90px;
    @include brSpLandscape {
      padding: 86px 0 90px;
    }
    .c-title {
      margin-bottom: 25px;
      @include brSpLandscape {
        text-align: center;
        margin-bottom: 37px;
      }
      p {
        margin-top: -11px;
        @include brSpLandscape {
          width: 259px;
          max-width: 100%;
          margin-left: auto;
          margin-right: auto;
          margin-top: -6px;
        }
      }
    }
    .l-col-left {
      flex: 49.468%;
      max-width: 49.468%;
      @include brSpLandscape {
        flex: 100%;
        max-width: 100%;
      }
      .p-home__news-info {
        margin-top: 42px;
        @include brSpLandscape {
          margin-top: 16px;
        }
      }
    }
    .l-col-right {
      flex: 50.532%;
      max-width: 50.532%;
      @include brSpLandscape {
        flex: 100%;
        max-width: 100%;
      }
      .p-home__news-info {
        margin-top: 24px;
      }
    }
    .l-row {
      margin-left: -9px;
      margin-right: -9px;
    }
    .l-col {
      padding-left: 9px;
      padding-right: 9px;
    }
    .l-col-6 {
      flex: 50%;
      max-width: 50%;
      margin-bottom: 37px;
    }
    &-slider {
      @include brSpLandscape {
        // margin-right: 60px;
        margin-top: 35px;
        &::before {
          content: '';
          position: absolute;
          width: 193px;
          height: 1px;
          // background-color: rgba(255, 255, 255, 0.65);
          left: calc(50% + 30px);
          transform: translateX(-50%);
          top: -39px;
        }
        .slick-list {
          overflow: visible;
          margin-left: -8px;
          margin-right: -8px;
        }
        .slick-slide {
          padding: 0 8px;
        }
        .p-home__news-cate {
          margin-bottom: 0;
        }
      }
    }
    &-item {
      a {
        display: block;
      }
    }
    &-info {
      h3 {
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: -0.02em;
        color: rgba(255, 255, 255, 0.8);
        margin-bottom: 8px;
        @include brSpLandscape {
          font-weight: 500;
          font-size: 20px;
          line-height: 32px;
        }
      }
      h4 {
        font-weight: 700;
        font-size: 20px;
        line-height: 32px;
        letter-spacing: -0.02em;
        color: rgba(255, 255, 255, 0.8);
        @include brSpLandscape {
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 15px;
        }
      }
      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.02em;
        color: rgba(255, 255, 255, 0.8);
        margin-bottom: 4px;
        padding-right: 10px;
        @include brSpLandscape {
          margin-bottom: 23px;
        }
      }
      .c-btn {
        margin-top: 30px;
        padding: 12px 25px;
        display: inline-block;
        @include brSpLandscape {
          display: none;
        }
      }
    }
    &-cate {
      font-weight: 500;
      font-size: 12px;
      line-height: 32px;
      letter-spacing: -0.02em;
      color: rgba(255, 255, 255, 0.8);
      margin-bottom: -6px;
      @include brSpLandscape {
        line-height: 20px;
      }
    }
    &-time {
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: -0.02em;
      color: rgba(255, 255, 255, 0.56);
    }
  }

  &__fields {
    padding: 46px 0 149px;
    @include brSpLandscape {
      padding-top: 22px;
    }
    .l-container {
    }
    .c-title {
      margin-bottom: 87px;
      @include brSpLandscape {
        margin-bottom: 26px;
      }
      h3 {
        padding-bottom: 16px;
      }
      p {
        @include brSpLandscape {
          width: 236px;
          max-width: 100%;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
    .l-row {
      margin-left: -21px;
      margin-right: -21px;
    }
    .l-col {
      flex: 33.33333%;
      max-width: 33.33333%;
      padding-left: 21px;
      padding-right: 21px;
      margin-bottom: 30px;
      @include brTablet {
        flex: 50%;
        max-width: 50%;
      }
      &:nth-child(odd) {
        .p-home__fields-item {
          background: radial-gradient(
            200.14% 200.14% at 50% 163%,
            #4776b3 8.96%,
            rgba(0, 36, 83, 0.1) 82.56%,
            rgba(189, 218, 255, 0) 100%
          );
        }
      }
      &:nth-child(even) {
        .p-home__fields-item {
          background: linear-gradient(
            180deg,
            #002266 -235%,
            #004a7a 9.31%,
            rgba(0, 33, 75, 0) 63.64%,
            rgba(189, 218, 255, 0) 124.61%
          );
        }
      }
    }
    &-slider {
      //margin-right: 23px;

      .slick-track {
        display: flex;
      }
      .slick-list {
        overflow: visible;
        margin-left: -8px;
        margin-right: -8px;
      }
      .slick-slide {
        padding: 0 8px;
        flex: 1;
        height: auto;
        &:nth-child(odd) {
          .p-home__fields-item {
            background: radial-gradient(
              200.14% 200.14% at 50% 163%,
              #4776b3 8.96%,
              rgba(0, 36, 83, 0.1) 82.56%,
              rgba(189, 218, 255, 0) 100%
            );
          }
        }
        &:nth-child(even) {
          .p-home__fields-item {
            background: linear-gradient(
              180deg,
              #002266 -235%,
              #004a7a 9.31%,
              rgba(0, 33, 75, 0) 63.64%,
              rgba(189, 218, 255, 0) 124.61%
            );
          }
        }
      }
      .slick-dots {
        text-align: center;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 25px;
        z-index: 1;
        @include brTablet {
          bottom: -25px;
        }
        li {
          display: inline-block;
          margin: 0 2px;
          &.slick-active {
            button {
              width: 39px;
              background-color: #fff;
            }
          }
          button {
            width: 14px;
            height: 8px;
            background: #9c9c9c;
            border-radius: 8px;
            border: none;
            font-size: 0;
            transition: 0.4s;
          }
        }
      }
    }
    &-item {
      height: 100%;
      padding: 35px 18px 22px 44px;
      border-radius: 20px;
      // border: 1.5px solid rgba(66, 145, 246, 1);
      @include brSpLandscape {
        padding: 21px 32px 22px 23px;
      }
      h3 {
        font-weight: 700;
        font-size: 32px;
        line-height: 32px;
        letter-spacing: -0.02em;
        margin-bottom: 10px;
        @include brSpLandscape {
          font-size: 20px;
        }
      }
      ul {
        li {
          font-weight: 400;
          font-size: 18px;
          line-height: 32px;
          letter-spacing: -0.02em;
          position: relative;
          padding-left: 15px;
          @include brSpLandscape {
            font-size: 16px;
          }
          &::before {
            content: '';
            position: absolute;
            top: 14px;
            left: 0;
            width: 3px;
            height: 3px;
            background-color: #fff;
            border-radius: 50%;
          }
        }
      }
    }
    &-icon {
      height: 80px;
      margin-bottom: 8px;
      @include brSpLandscape {
        height: 68px;
        img {
          height: 50px;
        }
      }
    }
  }

  &__partner {
    padding: 142px 0 248px;
    background: url('../../img/home/bg_partner.jpg') no-repeat center;
    background-size: cover;
    @include brSpLandscape {
      padding-top: 4px;
      padding-bottom: 50px;
    }
    .c-title {
      margin-bottom: 67px;
      @include brSpLandscape {
        margin-bottom: 54px;
      }
      p {
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        margin-top: 9px;
        font-family: 'Roboto';
        letter-spacing: 0;
        @include brSpLandscape {
          font-size: 16px;
          font-weight: 500;
          font-family: 'Roboto Condensed';
          margin-top: -6px;
        }
      }
    }
    &-list {
      display: flex;
      flex-wrap: wrap;
      gap: 67px 115px;
      justify-content: center;
      @include brSpLandscape {
        gap: initial;
        margin-left: -10px;
        margin-right: -10px;
      }
    }
    &-item {
      display: flex;
      align-items: center;
      @include brSpLandscape {
        flex: 33.33333%;
        max-width: 33.33333%;
        padding-left: 10px;
        padding-right: 10px;
        margin-bottom: 37px;
        &:nth-child(7) {
          flex: 40%;
          max-width: 40%;
        }
        &:nth-child(8) {
          flex: 50%;
          max-width: 50%;
        }
        img {
          width: 100%;
        }
      }
    }
  }

  &__integrated {
    background: url('../../img/home/bg_integrated.jpg') no-repeat center;
    background-size: cover;
    padding: 163px 0 335px;
    @include brSpLandscape {
      padding: 21px 0 48px;
    }
    .c-title {
      margin-bottom: 88px;
      @include brSpLandscape {
        margin-bottom: 66px;
      }
      h3 {
        padding-bottom: 15px;
      }
      p {
        margin-bottom: 40px;
        @include brSpLandscape {
          font-size: 16px;
          line-height: 24px;
          margin-top: 3px;
          margin-bottom: 32px;
        }
      }
    }
    .l-col {
      flex: 33.33333%;
      max-width: 33.33333%;
      position: relative;
      @include brSpLandscape {
        flex: 100%;
        max-width: 100%;
        margin-bottom: 40px;
      }
      &:nth-child(3) {
        &::after {
          opacity: 0;
        }
      }
      &::after {
        content: '';
        width: 1px;
        height: 75px;
        background-color: #fff;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        @include brSpLandscape {
          width: 90px;
          height: 1px;
          background-color: rgba(255, 255, 255, 0.6);
          left: 50%;
          transform: translateX(-50%);
          bottom: -19px;
          top: auto;
        }
      }
    }
    &-item {
      text-align: center;
      h3 {
        font-weight: 700;
        font-size: 32px;
        line-height: 160%;
        text-align: center;
        letter-spacing: -0.02em;
        margin-bottom: 8px;
        @include brSpLandscape {
          font-size: 20px;
          line-height: 32px;
        }
      }
      p {
        font-weight: 400;
        font-size: 20px;
        line-height: 32px;
        @include brSpLandscape {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
    &-icon {
      height: 79px;
      margin-bottom: 7px;
      @include brSpLandscape {
        height: 65px;
      }
      img {
        @include brSpLandscape {
          width: 48px;
        }
      }
    }
  }
}
