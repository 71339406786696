@charset "utf-8";

// ----------------------------------------------------------------------
// MediaQuery
// ----------------------------------------------------------------------


@mixin brSmallPc {
	// Small PC <= 1279px
	@media ( max-width: ($width-small-pc + $width-scrollbar)) {
		@content;
	}
}


@mixin brTablet {
	// Tablet <= 959px
	@media ( max-width: ($width-tablet + $width-scrollbar)) {
		@content;
	}
}


@mixin brSpLandscape {
	// SP Landscape <= 599px
	@media  ( max-width: ($width-sp-landscape + $width-scrollbar)) {
		@content;
	}
}


@mixin brSpPortrait {
	// SP Portrait <= 479px
	@media ( max-width: ($width-sp-portrait + $width-scrollbar)) {
		@content;
	}
}
